import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Credential } from 'src/app/entities/credential';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';


declare const jQuery;
declare const swal;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    // Contiene la estructura de los datos a 
    formData: Credential;

    // indica el estado del proceso
    status: "SENDING" | "FILLING_OUT" | "COMPLETE";

    showWorkspace: boolean;

    constructor(
        private router: Router,
        private currentRoute: ActivatedRoute,
        private helperService: HelperService,
        private authService: AuthService,) {

        this.formData = new Credential();
        this.status = "FILLING_OUT";
        this.showWorkspace = false;
    }

    
    ngOnInit(): void {
        // verficamos si se esta editando
        this.currentRoute
            .queryParams
            .subscribe(params => {
                // validamos si existe el parametro solicitado
                if ('workspace' in params && params.workspace !== '') {
                    this.showWorkspace = false;
                    // Asignamos el workspace
                    this.formData.workspace_id = params.workspace;

                } else {
                    this.formData.workspace_id = '';
                    this.showWorkspace = true;
                    // indicamos un estado diferente
                    // this.status = "COMPLETE";

                    // // avisamos lo que esta pasando
                    // swal({
                    //     title: 'Which workspace?',
                    //     text: "Error setting the workspace",
                    //     type: 'error',
                    // });

                }

            });
    }


    /**
     * Permite iniciar sesion al carrier
     * @returns void
     */
    login(): void {

        if (this.formData.workspace_id === null || this.formData.workspace_id === '') {
            swal({
                title: 'Which workspace?',
                text: "Error the workspace is required",
                type: 'error',
            });
            return;
        }

        if (this.formData.password === null || this.formData.password === '') {
            swal({
                title: 'Which password?',
                text: "Error password is required",
                type: 'error',
            });
            return;
        }


        if (this.formData.username === null || this.formData.username === '') {
            swal({
                title: 'Which username?',
                text: "Error the username is required",
                type: 'error',
            });
            return;
        }

        // si esta fuera del estado 
        if (this.status != "FILLING_OUT") {
            return;
        }

        // actualizamos el estado del formulario
        this.status = "SENDING";
        
        this.authService
            .login(this.formData)
            .then(() => {

                // se establece "my-jobs" como opción seleccionada en el menu principal al iniciar sesión
                localStorage.setItem('selectOptNav', 'myjobs');

                // actualizamos el estado del formulario
                this.status = "COMPLETE";

                // redireccionamos
                this.router.navigateByUrl("/my-jobs");
                
            })
            .catch((error) => {

                this.helperService.showMessageError(error, this.formData);
                
                // actualizamos el estado del formulario
                this.status = "FILLING_OUT";
            });
    }

}
