import { ElectronicSignature } from "./electronic-signature";

export class DocumentEsignature {
    id: string;
    main: ElectronicSignature;
    second: ElectronicSignature;
    third: ElectronicSignature;

    constructor(){
        this.id = null;
        this.main = null;
        this.second = null;
        this.third = null;
    }
}