import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JobOperationStatus } from '../entities/job-operation-status';

@Injectable({
  providedIn: 'root'
})
export class StatusesHistoryService {

    constructor(private http: HttpClient) {

    }

    /**
     * permite obtener todos los registros
     */
    getById(id): Promise<Array<any>> {
        return this.http.get<any>(environment.api.carrier + '/jobs/'+ id +'/history').toPromise()
    }

    
    saveStatus(id, data) {
    return this.http.post<any>(environment.api.carrier + '/jobs/' + id + '/history', data).toPromise();
    }

      
    getStatusHistoryCharge(id) {
        return this.http.get<Array<JobOperationStatus>>(environment.api.carrier + '/jobs/' + id + '/history').toPromise();
    }
  
}
