<div id="content" class="main-content">

    <div class="container text-center section-title pad-t70">
        <p class="h6 text-uppercase text-primary">Broker Documents</p>
        <div class="col-lg-6 col-md-6 col-sm-9 filtered-list-search mx-auto mb-0">
            <form class="form-inline my-2 my-lg-0 justify-content-center">
                <div class="w-100">
                    <input type="text" class="w-100 form-control product-search br-30" id="input-search" placeholder="Search..." >
                    <button class="btn btn-search" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg></button>
                </div>
            </form>
        </div>
    </div>        

    <div class="layout-px-spacing-head pad-0">
        <div class="row" id="cancel-row">

            <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing header-table">
                <div class="widget-content widget-content-area widget-content-head br-6 py-2">
                    <table id="invoice-list" class="table table-hover m-0" style="width:100%">
                        <thead>
                            <tr>                                
                                <th>Document</th>
                                <th>Description</th>
                                <th>Signature</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>            
        
            <div id="resize"></div>

            <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing layout-spacing body-table">
                <div class="widget-content widget-content-area br-6">
                    <table id="invoice-list" class="table table-hover" style="width:100%">                        
                        <tbody>
                           
                            <tr *ngFor="let brokerDocument of brokerDocuments">
                                <td class="tdHeader headerCollapse">
                                    <button class="btn-arrow rotate"><i class="fas fa-chevron-down"></i></button>                                    
                                    <div>
                                        <span class="key">Document: </span>{{brokerDocument.document.code}}
                                        <span *ngIf="brokerDocument.document == null || brokerDocument.document.esignatures.main == null" class="badge badge-danger ml-3"><i class="fas fa-signature"></i></span>
                                        <span *ngIf="brokerDocument?.document?.esignatures.main" class="badge badge-success ml-3"><i class="fas fa-signature"></i></span>
                                    </div>
                                </td>                                
                                <td class="rowCollapse" data-label="Document">
                                    <div class="arrow-down"></div>                                     
                                    <div>{{brokerDocument.document.code}}</div>
                                </td>
                                <td class="rowCollapse" data-label="Description">                                    
                                    <div class="documentDescription text-justify"><br class="break">{{brokerDocument.description}}</div>
                                </td>
                                <td class="rowCollapse" data-label="Signature">
                                    <span
                                        *ngIf="brokerDocument.document != null && brokerDocument.document.esignatures.main != null">
                                        <img src="{{environment.az_api.cdn}}/{{authService.session.workspace.id}}/e-signatures/{{brokerDocument.document.esignatures.main.esignature_file.display_name}}"
                                            width="185px" alt="">
                                    </span>
                                    
                                    <span
                                        *ngIf="brokerDocument.document == null || brokerDocument.document.esignatures.main == null"
                                        class="badge outline-badge-danger">
                                        None
                                    </span>
                                </td> 
                                <td class="rowCollapse t-align"  *ngIf="brokerDocument.document && brokerDocument.document.esignatures.main" data-label="Action">
                                    <button class="btn btn-success" (click)="downloadDocument(brokerDocument)">
                                       Download
                                    </button>
                                </td>                                
                                <td *ngIf="brokerDocument.document == null || brokerDocument.document.esignatures.main == null" class="rowCollapse t-align"  data-label="Action">
                                        <button class="btn btn-secondary" (click)="openModal(brokerDocument.id, brokerDocument)">Sign</button>
                                </td>                                
                            </tr>                     
                            
                           

                        </tbody>
                    </table>                    

                </div>
            </div>

        </div>
    </div>

</div>
<!--  END CONTENT AREA  -->

<!-- Modal -->
<div class="modal fade" id="modalSignature" #modalSignDoc data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-sign-job-acceptance" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Sign Broker Document
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">                
                    <div class="col-sm-12 col-md-6"  *ngIf="urlJobAcceptanceTrusted">
                        <iframe class="preview-job-acceptance" [src]="urlJobAcceptanceTrusted" type="application/pdf" style="width: 100%; height: 100%;"
                            allowfullscreen>
                        </iframe>
                    </div>
                    <div class="row col-sm-12 col-md-6">
                        <div class="col-xl-12 col-md-12 col-sm-12 col-12 mb-3">
                            <h4 class="m-0">Signature</h4>
                            <span>Sign Broker Document here</span>
                        </div>
                        <div class="row">
                            <app-sign (onSignature)="onSignature($event)"></app-sign>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="formStatus != 'FILLING_OUT'" class="btn" data-dismiss="modal" (click)="closeModal()" >
                    <i class="flaticon-cancel-12"></i> 
                    Discard
                </button>
                <button [disabled]="formStatus != 'FILLING_OUT'" type="button" class="btn btn-warning" (click)="saveFormData()">
                    <div *ngIf="formStatus == 'SENDING'" class="spinner-border text-white mr-2 align-self-center loader-sm ">Loading...</div>
                    Save
                </button>
            </div>
        </div>
    </div>
</div>


<!-- Modal View Document -->
<div class="modal fade" id="modalViewDocument" tabindex="-1" role="dialog" aria-labelledby="modalSignature" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered view-document" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Document CIANC00080
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">                
                    <div>
                        pdf
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal"><i class="flaticon-cancel-12"></i>Close</button>
            </div>
        </div>
    </div>
</div>

