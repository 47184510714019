import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ElectronicSignature } from '../entities/electronic-signature';
import { File } from '../entities/file';
import { JobOutsourcingView } from '../entities/job-outsourcing-view';

@Injectable({
    providedIn: 'root'
})
export class JobOutsourcingsService {


    constructor(private http: HttpClient) {

    }

    /**
     * permite obtener todos los registros
     */
    getAll(): Promise<Array<JobOutsourcingView>> {
        return this.http.get<Array<JobOutsourcingView>>(environment.api.carrier + '/job-outsourcings').toPromise()
    }

    /**
     * Permite firmar un job acceptance
     * 
     * @param id indica el id del job outsourcing  
     * @param signatureFile indica el archivo a firmar
     * @returns 
     */
    signJobAcceptance(id: string, signatureFile: ElectronicSignature): Promise<any> {
        return this.http.post(environment.api.carrier + '/job-outsourcings/' + id + '/job-acceptance/sign', signatureFile).toPromise()
    }

    /**
     * permite obtener el link del documento "job acceptance" de un trabajo asignado
     * 
     * @param id identificador del jobOutsourcing
     * @returns Promise
     */
    getJobAcceptance(id: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.http
                .get(environment.api.carrier + '/job-outsourcings/' + id + '/job-acceptance')
                .toPromise()
                .then((response) => {
                    resolve(response['filename']);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * permite obtener el link del documento job acceptance de un trabajo asignado en un ambiente publico
     * 
     * @param idWorkspace identificador del workspace
     * @param idJobOutsourcing identificador del jobOutsourcing
     *
     * @returns Promise
     */
    getPublicJobAcceptance(idWorkspace: string, idJobOutsourcing: string): Promise<any> {
        return this.http.get(environment.api.base + '/job-outsourcing/' + idWorkspace + '/job-acceptance/' + idJobOutsourcing).toPromise();
    }

    /**
     * permite obtener el link del documento job acceptance de un trabajo asignado en un ambiente publico
     * 
     * @param idWorkspace identificador del workspace
     * @param idJobOutsourcing identificador del jobOutsourcing
     *
     * @returns Promise
     */
    savePublicJobAcceptance(idWorkspace: string, idJobOutsourcing: string, signatureFile: ElectronicSignature): Promise<any> {
        return this.http.post(environment.api.base + '/job-outsourcing/' + idWorkspace + '/job-acceptance/' + idJobOutsourcing, signatureFile).toPromise();
    }

    /**
     * permite obtener el link del documento rate shieet asignado en un ambiente publico
     * 
     * @param idWorkspace identificador del workspace
     * @param idJobOutsourcing identificador del jobOutsourcing
     *
     * @returns Promise
     */
     getPublicRateShieet(idWorkspace: string, idJobOutsourcing: string): Promise<any> {
        return this.http.get(environment.api.base + '/job-outsourcing/' + idWorkspace + '/freight-rate-shieet/' + idJobOutsourcing).toPromise();
    }

    /**
     * permite obtener el link del documento job acceptance de un trabajo asignado en un ambiente publico
     * 
     * @param idWorkspace identificador del workspace
     * @param idJobOutsourcing identificador del jobOutsourcing
     *
     * @returns Promise
     */
     savePublicRateShieet(idWorkspace: string, idJobOutsourcing: string, signatureFile: ElectronicSignature): Promise<any> {
        return this.http.post(environment.api.base + '/job-outsourcing/' + idWorkspace + '/freight-rate-shieet/' + idJobOutsourcing, signatureFile).toPromise();
    }

    /**
     * 
     * @param idWorkspace 
     * @param idJobOutsourcing 
     * @returns 
     */
    getJobOutsourcing(idWorkspace: string, idJobOutsourcing: string, ): Promise<any> {
        return this.http.get(environment.api.base + '/job-outsourcing/' + idWorkspace + '/outsourcing/' + idJobOutsourcing).toPromise();
    }
}
