import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Carrier } from 'src/app/entities/carrier';
import { CarrierDocument } from 'src/app/entities/carrier-document';
import { ElectronicSignature } from 'src/app/entities/electronic-signature';
import { AuthService } from 'src/app/services/auth.service';
import { BrokerDocumentsService } from 'src/app/services/broker-documents.service';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';

declare var jQuery
declare var swal
@Component({
    selector: 'app-broker-documents',
    templateUrl: './broker-documents.component.html',
    styleUrls: ['./broker-documents.component.scss']
})
export class BrokerDocumentsComponent implements OnInit {
    readonly environment = environment;

    brokerDocuments: Array<CarrierDocument>;

    // cargamos el elemento que trabaja la modal
    @ViewChild('modalSignDoc')
    modalFormElem: ElementRef;
    signature: File;
    formStatus: "FILLING_OUT" | "SENDING";
    documentSelect: string;
    public urlJobAcceptanceTrusted;

    constructor(
        private brokerDocumentsService: BrokerDocumentsService,
        private helperService: HelperService,
        private sanitizer: DomSanitizer,
        public authService: AuthService,
    ) {
        this.signature = null;
        this.formStatus = "FILLING_OUT";
        this.documentSelect = '';
    }

    ngOnInit(): void {
        this.load();
    }

    ngAfterViewInit(): void {
        this.toggleCollapse()
    }


    load() {
        this.helperService.showLoadingMxpro360();
        this.brokerDocumentsService
            .getAll()
            .then((response) => {
                this.brokerDocuments = response;
                this.rowCollapse();                
            })
            .catch((error) => {
                this.helperService.showMessageError(error, {});
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }


    /**
     * Permite escuchar la firma
     */
    onSignature(signature: File) {
        this.signature = signature;
    }


    /**
     * Permite abrir la modal de formulario de candidato, 
     * si no se pasa argumento, se esta estableciendo como nuevo candidato
     * de lo contrario, se edita la postulacion
     * 
     * @param id indica el id del job outsourcing  
     */
    openModal(id: string, brokerDocument): void {
        // asignamos el identificador del job outsorcing para saber posteriormente a quien asignar la firma
        this.documentSelect = id;
        jQuery(this.modalFormElem.nativeElement).modal('show');

        const urlJobAcceptanceUntrusted = `${environment.az_api.cdn}/${this.authService.session.workspace.id}/documents/${brokerDocument.document.file.display_name}`;
        this.urlJobAcceptanceTrusted = this.sanitizer.bypassSecurityTrustResourceUrl(urlJobAcceptanceUntrusted);
    }


    /**
     * Permite cerrar la modal
     */
    closeModal(): void {
        // accesamos al controlador de la modal para poder cerrar la
        jQuery(this.modalFormElem.nativeElement).modal('hide');
        // nuleamos el formulario para poder ahorrar memoria
        this.signature = null;
    }


    /**
     * Permite registrar o actualizar la candidatura del carrier
     */
    saveFormData() {

        // revisamos si no esta se habia enviando previamente 
        if (this.formStatus == 'SENDING') {
            return;
        }

        const formData = new FormData();
        formData.append('files[]', this.signature);
        this.helperService.showLoadingMxpro360();

        jQuery.ajax({
            url: environment.api.base + '/files',
            type: 'post',
            dataType: 'json',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        })
            .done((response) => {

                const electronicSignature = new ElectronicSignature();
                electronicSignature.esignature_file.display_name = response.files[0];
                electronicSignature.esignature_file.name = response.files[0];
                electronicSignature.esignature_file.mime_type = this.helperService.getMimeType(response.files[0]);
                electronicSignature.internet_browser = this.helperService.detectBrowser();
                // enviamos la firma y e indicamos a que job se asignara
                this.brokerDocumentsService
                    .signDocument(this.documentSelect, electronicSignature)
                    .then(() => {

                        // refrescamos la lista para que se actualize la informacion
                        this.load();
                        // cerramos la modal
                        this.closeModal();
                    })
                    .catch((error) => {
                        // verificamos que se este recibiendo un error del servidor
                        if ('message' in error) {
                            // avisamos lo que esta pasando
                            swal({
                                title: 'Ops',
                                text: error.message,
                                type: 'error',
                            });
                        }
                    })
                    .finally(() => {
                        // actualizamos estado
                        this.formStatus = "FILLING_OUT";
                        this.helperService.hideLoadingMxpro360();
                    })
            });
    }


    downloadDocument(carrierDocument: CarrierDocument) {
        this.helperService.downloadReport('DOCUMENTS_CARRIERS', null, carrierDocument.document.file.display_name);
    }


    /**
     * Se alterna entre agregar y eliminar el Collapse a los row
     */
     toggleCollapse(){
        jQuery('#invoice-list tbody').on('click', '.headerCollapse', function () {
            jQuery(this).nextUntil('tr.header').slideToggle(500);
            jQuery(this).children('.rotate').toggleClass('down')
        });

        jQuery(window).resize(function () {
            if (jQuery(window).width() > 1024) {                
                jQuery("table tbody tr td").removeAttr("style");
            }
            else {
                jQuery(".tdHeader").addClass("headerCollapse");
                jQuery(".rowCollapse").css("display", "none");  
            }
        }); 
    }

    /**
     * Se define como estado inicial el Collapse para los row
     */
    rowCollapse(){
        setTimeout(() => {
            if (jQuery(window).width() < 1024) {                
                    jQuery(".tdHeader").addClass("headerCollapse");
                    jQuery(".rowCollapse").css("display", "none");                
            }
            else {
                jQuery(".tdHeader").removeClass("headerCollapse");
                jQuery("table tbody tr td").removeAttr("style");
            }
        }, 50);
    }

}
