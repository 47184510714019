import { BalanceListView } from './balance-list-view';
import { Carrier } from './carrier';
import { CarrierContact } from './carrier-contact';
import { Company } from './company';
import { Payment } from './payment';
import { Document } from './document';
import { Customer } from './customer';

export class BalanceView extends BalanceListView{
    
    company: Company;
    carrier: Carrier;
    payment: Payment;
    carrier_contact: CarrierContact;
    estimate_document: Document;   
    customer: Customer;
    pickup_date: number;

    constructor(){
        super();
        this.carrier = new Carrier;
        this.company = new Company;
        this.payment = new Payment;
        this.carrier_contact = new CarrierContact;
        this.estimate_document = new Document();
        this.estimate_document = new Document();
        this.customer = new Customer();
        this.pickup_date = null
    }

    
}
