import { PublicAppointment } from './public-appointment';

export class JobCarrierAppointmentView {
    
    box_delivery_day: PublicAppointment;
    pack_day: PublicAppointment;
    pickup_day: PublicAppointment;
    delivery_day: PublicAppointment;
    request_box_delivery_day?: boolean;
    request_pack_day?: boolean;

    constructor() {
        this.box_delivery_day = new PublicAppointment();
        this.pack_day = new PublicAppointment();
        this.pickup_day = new PublicAppointment();
        this.delivery_day = new PublicAppointment();
        this.request_box_delivery_day = false;
        this.request_pack_day = false;
    }
}
