export class Balance {
    public id: string;
    public target: 'STORAGE' | 'OPERATIONS' | 'NEW_BOOKING' | 'UPSALES' | 'CARRIER_TO_BROKER';
    public amount: number;
    public concept: string;
    public status: 'PENDING' | 'PROCESSING' | 'PAID' | 'PENDING_REVIEW';
    public job_id: string;
    public customer_id: string;
    public employee_id: string;
    public payment_methods_enabled_id: Array<string>;
    public comissioning_employee_id: string;
    public expiration_date: number;
    public carrier_id: string;
    public company_id: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.target = 'NEW_BOOKING';
        this.amount = null;
        this.concept = null;
        this.status = 'PENDING';
        this.job_id = null;
        this.customer_id = null;
        this.employee_id = null;
        this.payment_methods_enabled_id = [];
        this.comissioning_employee_id = null;
        this.expiration_date = null;
        this.carrier_id = null;
        this.company_id = null;
        this.created = null;
        this.updated = null;
    }
}
