import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';


declare const jQuery;

@Injectable({
    providedIn: 'root'
})
export class HttpInterceptorService {
    
    constructor(
        private injector: Injector
    ) {
    }

    /**
     * Permite verificar si se esta haciendo una peticion a la api del proyecto
     * @param urlRequest
     */
    private isRequestToApi(urlRequest: string): boolean {

        const apiUrl = new URL(environment.api.base);
        const urlReq = new URL(urlRequest);

        // verificamos si el host es el mismo del host de la api
        if (apiUrl.hostname === urlReq.hostname) {

            // verificamos si se esta haciendo uso de la api como tal
            if (urlRequest.includes(apiUrl.href)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Permite organizar el request que va dirigido a la api para adicionarle el access token
     * @param req 
     */
    private cloneRequestToApi(req: HttpRequest<any>): HttpRequest<any> {

        // consultamos por otro medio el auth para luego acceder al session
        const authService = this.injector.get<AuthService>(AuthService);

        // obtiene url de la api a la que se desea hacer request
        let url = req.url;

        // en caso de que no haya sesion
        if (authService.session === null) {
            return req;
        }
        
        // clonamos el request y le adicionamos el access token
        const clonReq = req.clone({
            url: url,
            setHeaders: {
                'Content-Type': 'application/json',
                'Authorization': authService.session.token
            },
            setParams: {
                'Authorization': authService.session.token
            }
        });

        return clonReq;
    }

    /**
     * Metodo principal que se ejecuta por cada llamado de un http Client
     * @param req 
     * @param next 
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        // console.log("peticion: ", req)

        // en caso de que no sea una peticion al servidor api dejamos la solicitud tal cual
        if (!this.isRequestToApi(req.url)) {
            return next.handle(req);
        }

        // como la peticion es para el servidor api, se hace un clon de la solicitud
        // y se le adiciona el access token para las credenciales
        return next.handle(this.cloneRequestToApi(req))
            .pipe(
                tap(
                    event => {
                        // logging the http response to browser's console in case of a success
                        if (event instanceof HttpResponse) {
                            // console.log('api call success : ', event);
                        }
                    },
                    error => {
                        // logging the http response to browser's console in case of a failuer
                        if (event instanceof HttpResponse) {
                            // console.log('api call error : ', event);
                        } else {
                            // The backend returned an unsuccessful response code.
                            // The response body may contain clues as to what went wrong,
                            console.error(`Backend returned code ${error.status}, ` + `body was:`, error);

                            // si entra aca es por que se cerro la sesion
                            if (error.status === 401) {

                                // injectamos los respectivos servicios
                                const authService = this.injector.get<AuthService>(AuthService);
                                const router = this.injector.get<Router>(Router);

                                // cerramos session
                                authService.logout();

                                // quitamos el loading
                                /* this.helperService.hideLoadingMxpro360(); */

                                // enviamos al login
                                router.navigateByUrl('/login?workspace='+authService.session.workspace.id);
                            }
                        }
                    }
                ));
    }


}

