import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Session } from '../entities/session';
import { Credential } from 'src/app/entities/credential';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    /**
     * Contiene la informacion de la session del carrier logueado
     */
    session: Session;


    constructor(
        private http: HttpClient,
    ) {
        // inicializamos la session
        this.session = null;

        // verificamos si hay informcion de sesion guardada en el localstorage
        this.load();
    }

    /**
     * permite cargar la informacion de la session guardada localmente
     */
    load() {
        const session = localStorage.getItem('session');
        if (session !== null) {
            this.session = JSON.parse(session);
        }
    }

    /**
     * permite guardar la sesion localmente
     */
    save() {
        if (this.session !== null) {
            localStorage.setItem('session', JSON.stringify(this.session));
        }
    }

    /**
     * permite iniciar sesión a los Carriers
     * @param formData
     */
    login(formData: Credential) {
        return new Promise((resolve, reject) => {
            this.http
                .post(environment.api.base + '/login/carriers', formData)
                .subscribe((response) => {

                    this.session = new Session();
                    this.session.token = response['token'];
                    this.session.carrier = response['carrier'];
                    this.session.workspace = response['workspace'];

                    // guardamos la sesion
                    this.save();

                    resolve(true);
                }, (error) => {
                    console.info("error login: ", error);
                    reject(error);
                });
        });
    }

    /**
     * permite cerrar la sesion en el servidor
     */
     logout() {
        return new Promise((resolve, reject) => {
            this.http
                .post(environment.api.base + '/logout', {})
                .subscribe((response) => {
                    
                    // reseteamos la variable que almacena la informacion
                    this.session = null;

                    // guardamos la informacion
                    localStorage.removeItem('session');

                    resolve(true);
                }, (error) => {
                    reject(error.error);
                });
        });
    }

}
