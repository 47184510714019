import { HelperService } from './../../services/helper.service';
import { PaymentsService } from 'src/app/services/payments.service';
import { UploadFilesService } from 'src/app/services/upload-files.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BalanceView } from '../../entities/balance-view';
import { File } from '../../entities/file';
import { PaymentMethod } from '../../entities/payment-method';
import { PaymentProfile } from '../../entities/payment-profile';
import { ResponseTransaction } from '../../entities/response-transaction';
import { BalancesService } from '../../services/balances.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { CarrierPayment } from 'src/app/entities/carrier-payment';
/* import { CustomerEstimate } from 'src/app/entities/customer-estimate'; */

@Component({
  selector: 'app-payments-offline',
  templateUrl: './payments-offline.component.html',
  styleUrls: ['./payments-offline.component.scss']
})
export class PaymentsOfflineComponent implements OnInit {

  readonly environment = environment;

  public paymentProfile: PaymentProfile;
  public paymentMethod: PaymentMethod;
  public balance: BalanceView;
  private idPaymentMethod: string;
  public tabBank: string;
  public tabResult: string;
  public responseTransaction: ResponseTransaction;
/*   public customerEstimate: CustomerEstimate; */

  constructor(
    public authService: AuthService,
    private balancesService: BalancesService,
    private currentRoute: ActivatedRoute,
    private helperService: HelperService,
    private paymentsService: PaymentsService,
    private uploadFilesService: UploadFilesService
  ) {
    this.paymentMethod = new PaymentMethod();
    this.paymentProfile = new PaymentProfile();
    this.tabBank = 'active';
    this.tabResult = 'inactive';
    this.balance = new BalanceView();
    this.responseTransaction = new ResponseTransaction();
  }

  ngOnInit(): void {
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id_balance !== 'undefined' && typeof params.id_method !== 'undefined') {
        this.idPaymentMethod = params.id_method;
        this.loadPaymentMethod(params.id_method);
        this.loadPayment(params.id_balance);
      }
    });

    /* this.customerEstimate = this.helperService.loadCustomerServiceLocalStorage(); */
    
  }

  private loadPaymentMethod(idMethod: string): void {
    this.helperService.showLoadingMxpro360();
    this.paymentsService.
      getPaymentMethod(idMethod)
      .then(async (response) => {
        console.log('response', response);
        this.paymentMethod = response;
      })
      .catch((error) => {
        this.helperService.showMessageError(error, idMethod);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  private loadPayment(id) {
    this.helperService.showLoadingMxpro360();
    this.balancesService
      .getPendingBalanceById(id)
      .then((response) => {
        this.balance = response;
      })
      .catch((error) => {
        this.helperService.showMessageError(error, id);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  public sendPayment() {
    this.helperService.showLoadingMxpro360();
    // inicializa datos para post de pagos
    const dataPost: CarrierPayment = new CarrierPayment();

    dataPost.payment_profile.bank_information = this.paymentProfile.bank_information;

    // envia pago a api
    this.paymentsService
      .sendPayment(this.balance.id, this.idPaymentMethod, dataPost)
      .then(async (response) => {
        this.responseTransaction = response;
        this.changeTab();
      })
      .catch((error) => {
        this.helperService.showMessageError(error, {balance: this.balance.id, idPaymentMethod: this.idPaymentMethod, dataPost: dataPost});
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  public changeTab(): void {
    // Realiza el cambio de tab al dar clic en next
    if (this.tabBank === 'active') {
      this.tabBank = 'inactive';
      this.tabResult = 'active';
    }
  }

  uploadFiles() {
    this.uploadFilesService.openModal()
      .then((response: any) => {
        this.helperService.showLoadingMxpro360();
        if (response.length == 0) {
          return;
        }
        const dataPost: CarrierPayment = new CarrierPayment();
        let vouchers: Array<File> = [];
        for (const voucher of response) {
          const file = new File();
          file.name = voucher;
          file.display_name = voucher;
          file.mime_type = this.helperService.getMimeType(voucher);
          vouchers.push(file);
        }

        dataPost.amount = this.balance.amount;
        dataPost.vouchers = vouchers;

        // envia pago a api
        this.paymentsService
          .sendPayment(this.balance.id, this.idPaymentMethod, dataPost)
          .then(async (responseTransaction) => {
            this.responseTransaction = responseTransaction;
            console.log("responseTransaction", responseTransaction);
            
            this.changeTab();
          })
          .catch((error) => {
              this.helperService.showMessageError(error, {balance: this.balance.id, idPaymentMethod: this.idPaymentMethod, dataPost: dataPost});
          })
          .finally(() => {
            this.helperService.hideLoadingMxpro360();
          });
        console.log('vouchers', vouchers);
      });
  }

}
