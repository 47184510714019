<header class="header navbar navbar-expand-sm">

    <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></a>

    <div class="nav-logo align-self-center">
        <a class="" href="/">
            <img alt="logo" src="../../../assets/img/logotipo_moverxpro360.png" height="65px" class="navbar-logo pr-2" alt="logo">
            <img alt="logo" src="../../../assets/img/logotipo_text_moverxpro360.png" width="110px" class="navbar-logo" alt="logo">
        </a>
    </div> 


    <ul class="list-unstyled menu-categories current-company">
        <li class="menu single-menu">
            <button class="company-name">
                <span>
                   {{nameCarrier}}
                </span>
            </button>
        </li>
    </ul>

    
    <ul class="navbar-item flex-row nav-dropdowns ml-auto">
        <li class="nav-item dropdown language-dropdown more-dropdown">
            <div class="dropdown  custom-dropdown-icon">
                <a class="dropdown-toggle btn" href="#" role="button" id="customDropdown" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <img src="assets/img/usa.png" class="flag-width" alt="flag">
                    <span>English</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-chevron-down">
                        <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="customDropdown">
                    <a class="dropdown-item" data-img-value="de" data-value="German" href="javascript:void(0);"><img
                            src="assets/img/de.png" class="flag-width" alt="flag"> German</a>
                    <a class="dropdown-item" data-img-value="jp" data-value="Japanese" href="javascript:void(0);"><img
                            src="assets/img/jp.png" class="flag-width" alt="flag"> Japanese</a>
                    <a class="dropdown-item" data-img-value="fr" data-value="French" href="javascript:void(0);"><img
                            src="assets/img/fr.png" class="flag-width" alt="flag"> French</a>
                    <a class="dropdown-item" data-img-value="usa" data-value="English" href="javascript:void(0);"><img
                            src="assets/img/usa.png" class="flag-width" alt="flag"> English</a>
                </div>
            </div>
        </li>

        <li class="nav-item dropdown message-dropdown">
            <a href="javascript:void(0);" class="nav-link dropdown-toggle" id="messageDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg><span class="badge badge-success"></span>
            </a>
        </li>

        <li class="nav-item dropdown notification-dropdown">
            <a href="javascript:void(0);" class="nav-link dropdown-toggle" id="notificationDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg><span class="badge badge-success"></span>
            </a>
        </li>

        <li class="nav-item dropdown user-profile-dropdown order-lg-0 order-1">
            <a href="javascript:void(0);" class="nav-link dropdown-toggle user icon-more" id="user-profile-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
            </a>
            <div class="dropdown-menu position-absolute" aria-labelledby="userProfileDropdown">
                
                <div class="dropdown-item" style="cursor: pointer">
                    <a (click)="logout()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                        <span>
                            Log Out
                        </span>
                    </a>
                </div>
            </div>

        </li>
    </ul>

</header>

