import { Component, OnInit } from '@angular/core';
import { UploadFilesService } from 'src/app/services/upload-files.service';

declare var swal;

@Component({
  selector: 'app-payments-zelle',
  templateUrl: './payments-zelle.component.html',
  styleUrls: ['./payments-zelle.component.scss']
})
export class PaymentsZelleComponent implements OnInit {

  constructor(
    private uploadFilesService: UploadFilesService
  ) { }

  ngOnInit(): void {
  }

  successEvent() {
    swal({
      title: 'Good job!',
      text: "You clicked the!",
      type: 'success',
      padding: '2em'
    })
  }

  failEvent() {
    swal({
      title: 'Oops...',
      text: 'Something went wrong!',
      type: 'error',
      padding: '2em'
    })
  }

  uploadFiles() {
    this.uploadFilesService.openModal();
  }
}
