import { NgxPaginationModule } from 'ngx-pagination';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { PanelLayoutComponent } from './components/panel-layout/panel-layout.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { TopbarNavComponent } from './components/topbar-nav/topbar-nav.component';
import { BalancesComponent } from './pages/balances/balances.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { JobCalendarComponent } from './pages/job-calendar/job-calendar.component';
import { LoadBoardComponent } from './pages/load-board/load-board.component';
import { BrokerDocumentsComponent } from './pages/broker-documents/broker-documents.component';
import { PaymentsCreditCardComponent } from './pages/payments-credit-card/payments-credit-card.component';
import { PaymentsAchComponent } from './pages/payments-ach/payments-ach.component';
import { PaymentsOfflineComponent } from './pages/payments-offline/payments-offline.component';
import { PaymentsCheckComponent } from './pages/payments-check/payments-check.component';
import { PaymentsZelleComponent } from './pages/payments-zelle/payments-zelle.component';
import { FileUploadModalComponent } from './modals/file-upload-modal/file-upload-modal.component';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { DatePipe } from './pipes/date.pipe';
import { SignComponent } from './components/sign/sign.component';
import { RegisterComponent } from './pages/register/register.component';
import { VipLoadBoardComponent } from './pages/vip-load-board/vip-load-board.component';
import { PublicLoadBoardComponent } from './pages/public-load-board/public-load-board.component';
import { JobsViewComponent } from './pages/jobs-view/jobs-view.component';
import { SignJobAcceptanceComponent } from './pages/sign-job-acceptance/sign-job-acceptance.component';
import { DatePickerComponent } from './pages/date-picker/date-picker.component';
import { RateShieetComponent } from './pages/rate-shieet/rate-shieet.component';
import { FilterReplacePipe } from './pipes/filter-replace.pipe';

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        HeaderComponent,
        PanelLayoutComponent,
        LoginComponent,
        DashboardComponent,
        PageNotFoundComponent,
        TopbarNavComponent,
        BalancesComponent,
        JobsComponent,
        JobCalendarComponent,
        LoadBoardComponent,
        BrokerDocumentsComponent,
        PaymentsCreditCardComponent,
        PaymentsAchComponent,
        PaymentsOfflineComponent,
        PaymentsCheckComponent,
        PaymentsZelleComponent,
        FileUploadModalComponent,
        DatePipe,
        SignComponent,
        RegisterComponent,
        VipLoadBoardComponent,
        PublicLoadBoardComponent,
        JobsViewComponent,
        SignJobAcceptanceComponent,
        DatePickerComponent,
        RateShieetComponent,
        FilterReplacePipe
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        NgxPaginationModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        }
    ],
    bootstrap: [
        AppComponent
    ],
})
export class AppModule { }
