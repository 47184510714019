import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CarrierCandidate } from '../entities/carrier-candidate';

@Injectable({
    providedIn: 'root'
})
export class CarrierCandidatesService {

    constructor(private http: HttpClient) {

    }

    /**
     * permite GUARDAR el carrier candidate
     */
    save(carrierCandidate: CarrierCandidate): Promise<any> {

        // si no tiene id es por que el registro es nuevo
        if (carrierCandidate.id === null) {
            return this.add(carrierCandidate);
        }

        // como tiene id es envia para actualizar
        return this.edit(carrierCandidate);
    }

    /**
     * Permite crear un nuevo carrier candidate
     * @param carrierCandidate 
     * @returns 
     */
    add(carrierCandidate: CarrierCandidate): Promise<any> {
        return this.http.post(environment.api.carrier + '/carrier-candidates', carrierCandidate).toPromise()
    }

    /**
     * Permite editar un  carrier Candidate
     * @param carrierCandidate 
     * @returns 
     */
    edit(carrierCandidate: CarrierCandidate): Promise<any> {
        return this.http.put(environment.api.carrier + '/carrier-candidates/' + carrierCandidate.id, carrierCandidate).toPromise()
    }

}
