import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BoardJobView } from 'src/app/entities/board-job-view';
import { CarrierCandidate } from 'src/app/entities/carrier-candidate';
import { BoardJobsService } from 'src/app/services/board-jobs.service';
import { CarrierCandidatesService } from 'src/app/services/carrier-candidates.service';

declare const jQuery;
declare const bootstrap;
declare const swal;

@Component({
    selector: 'app-load-board',
    templateUrl: './load-board.component.html',
    styleUrls: ['./load-board.component.scss']
})
export class LoadBoardComponent implements OnInit {

    // cargamos el elemento que trabaja la modal
    @ViewChild('modalApplyJob')
    modalFormElem: ElementRef;

    // contiene la lista de los jobs ofrecidos
    boardJobs: Array<BoardJobView>;

    // creamos el objeto para registrar el carrier como candidate
    formData: CarrierCandidate;

    // variable para saber el estado del formulario
    formStatus: "FILLING_OUT" | "SENDING";

    /**
     * 
     * @param boardJobsService 
     * @param carrierCandidatesService 
     */
    constructor(
        private boardJobsService: BoardJobsService,
        private carrierCandidatesService: CarrierCandidatesService) {
        this.boardJobs = [];
        this.formData = null;
        this.formStatus = "FILLING_OUT";
    }

    ngOnInit(): void {

        // cargamos los registros
        this.load();
    }

    ngAfterViewInit(): void {
        this.toggleCollapse()
    }

    

    /**
     * Permite cargar todos los registros
     */
    load(): void {
        this.boardJobsService
            .getAll()
            .then((response: Array<BoardJobView>) => {
                this.boardJobs = response;
                this.rowCollapse(); 
                this.tooltip()                
            });
    }

    /**
     * Permite abrir la modal de formulario de candidato, 
     * si no se pasa argumento, se esta estableciendo como nuevo candidato
     * de lo contrario, se edita la postulacion
     * 
     * @param board_job_id 
     * @param carrierCandidate 
     */
    openModal(board_job_id: string, carrierCandidate: CarrierCandidate = null): void {

        // se se recibe argumento, se asigna si no se establece uno nuevo
        if (carrierCandidate == null) {
            this.formData = new CarrierCandidate();
            this.formData.job_board_id = board_job_id;
        } else {
            this.formData = carrierCandidate;
        }

        // accesamos al controlador de la modal para poder abrir la
        jQuery(this.modalFormElem.nativeElement).modal('show');
    }

    /**
     * Permite cerrar la modal
     */
    closeModal(): void {

        // accesamos al controlador de la modal para poder cerrar la
        jQuery(this.modalFormElem.nativeElement).modal('hide');

        // nuleamos el formulario para poder ahorrar memoria
        this.formData = null;
    }

    /**
     * Permite registrar o actualizar la candidatura del carrier
     */
    saveFormData() {

        // revisamos si no esta se habia enviando previamente 
        if(this.formStatus == 'SENDING'){
            return;
        }

        // indicamos el estado de que se esta enviando...
        this.formStatus = "SENDING";

        // enviamos los datos
        this.carrierCandidatesService
            .save(this.formData)
            .then(() => {

                // refrescamos la lista para que se actualize la informacion
                this.load();

                // cerramos la modal
                this.closeModal();

            })
            .catch((error) => {
                // verificamos que se este recibiendo un error del servidor
                if('message' in error) {
                    // avisamos lo que esta pasando
                    swal({
                        title: 'Ops',
                        text: error.message,
                        type: 'error',
                    });
                }
            })
            .finally(()=>{

                // actualizamos estado
                this.formStatus = "FILLING_OUT";
            })
    }

    
    /**
     * tooltip
     */
    tooltip(){
        setTimeout(function() {
            jQuery('.bs-tooltip').tooltip();
            jQuery('.success').tooltip({
                template: '<div class="tooltip tooltip-success" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
                title: "Success"
            });
            jQuery('.danger').tooltip({
                template: '<div class="tooltip tooltip-danger" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
                title: "Danger"
            });
        },10);
    }

    /**
     * Se alterna entre agregar y eliminar el Collapse a los row
     */
     toggleCollapse(){
        jQuery('#invoice-list tbody').on('click', '.headerCollapse', function () {
            jQuery(this).nextUntil('tr.header').slideToggle(500);
            jQuery(this).children('.rotate').toggleClass('down')
        });

        jQuery(window).resize(function () {
            if (jQuery(window).width() > 1024) {                
                jQuery("table tbody tr td").removeAttr("style");
            }
            else {
                jQuery(".tdHeader").addClass("headerCollapse");
                jQuery(".rowCollapse").css("display", "none");  
            }
        }); 
    }

    /**
     * Se define como estado inicial el Collapse para los row
     */
    rowCollapse(){
        setTimeout(() => {
            if (jQuery(window).width() < 1024) {                
                    jQuery(".tdHeader").addClass("headerCollapse");
                    jQuery(".rowCollapse").css("display", "none");                
            }
            else {
                jQuery(".tdHeader").removeClass("headerCollapse");
                jQuery("table tbody tr td").removeAttr("style");
            }
        }, 50);
    }

}
