import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CarrierPayment } from '../entities/carrier-payment';
import { Company } from '../entities/company';
import { PaymentMethod } from '../entities/payment-method';
import { PaymentTransaction } from '../entities/payment-transaction';
import { ResponseTransaction } from '../entities/response-transaction';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private http: HttpClient
  ) { }

  sendPaymentAuthorization(id: string, carrierPayment, companyId) {
    return this.http.post<any>(environment.api.carrier + '/payment-authorization?company_id=' + companyId, carrierPayment).toPromise();
  }

  sendPayment(idBalance: string, idMethod: string, carrierPayment: CarrierPayment) {
    return this.http.post<ResponseTransaction>(environment.api.carrier + '/payment/' +
      idBalance + '/method/' + idMethod, carrierPayment).toPromise();
  }

  getPaymentMethod(idMethod: string) {
    return this.http.get<PaymentMethod>(environment.api.carrier + '/payment-method/' + idMethod).toPromise();
  }
}
