import { Component, OnInit } from '@angular/core';
import { UploadFilesService } from 'src/app/services/upload-files.service';

declare var swal;

@Component({
  selector: 'app-payments-check',
  templateUrl: './payments-check.component.html',
  styleUrls: ['./payments-check.component.scss']
})
export class PaymentsCheckComponent implements OnInit {

  constructor(
      private uploadFilesService: UploadFilesService
  ) {
    
  }

  ngOnInit(): void {   
    

    }

    successEvent() {
      swal({
        title: 'Good job!',
        text: "You clicked the!",
        type: 'success',
        padding: '2em'
      })
    }

    failEvent() {
      swal({
        title: 'Oops...',
        text: 'Something went wrong!',
        type: 'error',
        padding: '2em'
      })
    }

    uploadFiles() {
      this.uploadFilesService.openModal();
    }


}
