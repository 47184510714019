import { AuthService } from 'src/app/services/auth.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ElectronicSignature } from '../../entities/electronic-signature';
import { environment } from 'src/environments/environment';
import { FilesService } from 'src/app/services/files.service';
import { HelperService } from 'src/app/services/helper.service';
import { JobOutsourcingsService } from 'src/app/services/job-outsourcings.service';
import { JobOutsourcingView } from 'src/app/entities/job-outsourcing-view';
import { query } from '@angular/animations';

declare const jQuery: any;
declare const swal: any;

@Component({
    selector: 'app-jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss']
})

export class JobsComponent implements OnInit {

    // contiene los trabajos asignado al carrier logueaedo
    jobOutsourcings: Array<JobOutsourcingView>;

    // creamos un objeto para en el establecer la informacion del archivo que contiene la firma
    signature: File;

    // para indicar el estado del proceso respecto al formulario de la firma
    formStatus: "FILLING_OUT" | "SENDING";

    // contiene el identificador del job acceptance(job_out_sourcing_id) que firmara el carrier
    formJOSID: string;

    // contiene el link del job acceptance PDF
    JobAcceptanceFile: string;

    // cargamos el elemento que trabaja la modal
    @ViewChild('modalSignDoc') modalFormElem: ElementRef;

    public urlJobAcceptanceTrusted;
    public urlJobAcceptanceUntrusted;
    public specialConditions: string;

    typingSignature: boolean;

    constructor(
        private helperService: HelperService,
        private jobOutsourcingsService: JobOutsourcingsService,
        private sanitizer: DomSanitizer,
        public authService: AuthService
    ) {
        this.formJOSID = null;
        this.formStatus = "FILLING_OUT";
        this.JobAcceptanceFile = null;
        this.jobOutsourcings = [];
        this.signature = null;
        this.specialConditions = '';
        this.typingSignature = false;
    }

    ngOnInit(): void {
        this.load();
    }

    ngAfterViewInit(): void {
        this.toggleCollapse();
    }

    load(): void {
        this.helperService.showLoadingMxpro360();
        this.jobOutsourcingsService
            .getAll()
            .then((response: Array<JobOutsourcingView>) => {
                this.jobOutsourcings = response;
                this.rowCollapse(); 
                this.tooltip();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Permite abrir la modal de formulario de candidato, 
     * si no se pasa argumento, se esta estableciendo como nuevo candidato
     * de lo contrario, se edita la postulacion
     * 
     * @param id indica el id del job outsourcing  
     */
    openModal(id: string, specialConditions: string): void {
        this.helperService.showLoadingMxpro360();
        this.specialConditions = specialConditions;

        // asignamos el identificador del job outsorcing para saber posteriormente a quien asignar la firma
        this.formJOSID = id;
        this.jobOutsourcingsService
            .getJobAcceptance(id)
            .then((filename) => {

                this.urlJobAcceptanceUntrusted = environment.api.base + '/tmp/' + filename + '?Authorization=' + this.authService.session.token;
                this.urlJobAcceptanceTrusted = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlJobAcceptanceUntrusted);

                setTimeout(() => {
                    jQuery('#modalSignature').css('background', '#00000069')
                    jQuery('.modal-backdrop').remove();
                }, 200);
                

                jQuery(this.modalFormElem.nativeElement).modal('show');
                jQuery('#clean-signature').click();
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    /**
     * Permite cerrar la modal
     */
    closeModal(): void {

        // accesamos al controlador de la modal para poder cerrar la
        jQuery(this.modalFormElem.nativeElement).modal('hide');

        // nuleamos el formulario para poder ahorrar memoria
        this.signature = null;
    }

    /**
     * Permite registrar o actualizar la candidatura del carrier
     */
    saveFormData() {

        // revisamos si no esta se habia enviando previamente 
        if (this.formStatus == 'SENDING') {
            return;
        }
        this.helperService.showLoadingMxpro360();
        const formData = new FormData();
        formData.append('files[]', this.signature);
        jQuery.ajax({
            url: environment.api.base + '/files',
            type: 'post',
            dataType: 'json',
            data: formData,
            cache: false,
            contentType: false,
            processData: false
        })
            .done((response) => {

                const electronicSignature = new ElectronicSignature();
                electronicSignature.esignature_file.display_name = response.files[0];
                electronicSignature.esignature_file.name = response.files[0];
                electronicSignature.esignature_file.mime_type = this.helperService.getMimeType(response.files[0]);
                electronicSignature.internet_browser = this.helperService.detectBrowser();
                // enviamos la firma y e indicamos a que job se asignara
                this.helperService.showLoadingMxpro360();
                this.jobOutsourcingsService
                    .signJobAcceptance(this.formJOSID, electronicSignature)
                    .then(() => {

                        // refrescamos la lista para que se actualize la informacion
                        this.load();
                        // cerramos la modal
                        this.closeModal();
                    })
                    .catch((error) => {
                        // verificamos que se este recibiendo un error del servidor
                        if ('message' in error) {
                            // avisamos lo que esta pasando
                            swal({
                                title: 'Ops',
                                text: error.error.message,
                                type: 'error',
                            });
                        }
                    })
                    .finally(() => {
                        // actualizamos estado
                        this.formStatus = "FILLING_OUT";
                        this.helperService.hideLoadingMxpro360();
                    })
            });
    }

    /**
     * Permite escuchar la firma
     */
    onSignature(signature: File) {
        
        this.signature = signature;
        console.log('signature  : ', this.signature);
        
    }

    /**
     * Permite escuchar la firma
     */
    onTypingSignature(typing: boolean) {
        
        this.typingSignature = typing;
        
    }

    /**
     * Permite descargar el documento Job Acceptance
     */
    downloadDocument() {
        window.open(this.urlJobAcceptanceUntrusted, "_blank");
    }


    /**
     * Se alterna entre agregar y eliminar el Collapse a los row
     */
    toggleCollapse(){
        jQuery('#invoice-list tbody').on('click', '.headerCollapse', function () {
            jQuery(this).nextUntil('tr.header').slideToggle(500);
            jQuery(this).children('.rotate').toggleClass('down')
        });

        jQuery(window).resize(function () {
            if (jQuery(window).width() > 1024) {                
                jQuery("table tbody tr td").removeAttr("style");
            }
            else {
                jQuery(".tdHeader").addClass("headerCollapse");
                jQuery(".rowCollapse").css("display", "none");  
            }
        }); 
    }

    /**
     * Se define como estado inicial el Collapse para los row
     */
    rowCollapse(){
        setTimeout(() => {
            if (jQuery(window).width() < 1024) {                
                    jQuery(".tdHeader").addClass("headerCollapse");
                    jQuery(".rowCollapse").css("display", "none");                
            }
            else {
                jQuery(".tdHeader").removeClass("headerCollapse");
                jQuery("table tbody tr td").removeAttr("style");
            }
        }, 50);
    }

    /**
     * tooltip
     */
    tooltip(){
        setTimeout(function() {
            jQuery('.bs-tooltip').tooltip();
            jQuery('.success').tooltip({
                template: '<div class="tooltip tooltip-success" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
                title: "Success"
            });
            jQuery('.danger').tooltip({
                template: '<div class="tooltip tooltip-danger" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
                title: "Danger"
            });
        },10);
    }

}
