import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EstimateAdditionalCharge } from 'src/app/entities/estimate-additional-charge';
import { JobCarrierAppointmentView } from 'src/app/entities/job-carrier-appointment-view';
import { JobCarrierNote } from 'src/app/entities/job-carrier-note';
import { JobNote } from 'src/app/entities/job-note';
import { JobOperationStatus } from 'src/app/entities/job-operation-status';
import { JobOutsourcingView } from 'src/app/entities/job-outsourcing-view';
import { JobStatus } from 'src/app/entities/job-status';
import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { JobNotesService } from 'src/app/services/job-notes.service';
import { JobsService } from 'src/app/services/jobs.service';
import { StatusesHistoryService } from 'src/app/services/statuses-history.service';
import { environment } from 'src/environments/environment';

declare const jQuery;
@Component({
  selector: 'app-jobs-view',
  templateUrl: './jobs-view.component.html',
  styleUrls: ['./jobs-view.component.scss']
})
export class JobsViewComponent implements OnInit {
  readonly environment = environment;

  @ViewChild('modalCarrierNotes') modalCarrierNotes: ElementRef;
  @ViewChild('modalOperatingStatusHistory') modalOperatingStatusHistory: ElementRef;
  job: JobOutsourcingView;
  jobNotes: Array<JobCarrierNote>;
  jobNote: JobCarrierNote;
  customerPayment: number;
  bulkyFee: Array<any>;
  discounts: Array<EstimateAdditionalCharge>;
  additionals: Array<EstimateAdditionalCharge>;
  notesColors: Array<string>;
  totalItems: number;
  totalCf: number;
  statusesHistory: Array<JobOperationStatus>;
  jobStatus: JobStatus;
  jobOperatingStatus: JobOperationStatus;
  typeJobAppoinment: string;
  jobAppoinments: JobCarrierAppointmentView;
  jobId: string;
  //   historyStatus: 
  constructor(
    private jobsService: JobsService,
    private jobNotesService: JobNotesService,
    private currentRoute: ActivatedRoute,
    private statusesHistoryService: StatusesHistoryService,
    public authService: AuthService,
    private helperService: HelperService,

  ) {
    this.job = new JobOutsourcingView();
    this.jobNotes = [];
    this.jobNote = new JobCarrierNote();
    this.customerPayment = 0;
    this.bulkyFee = [];
    this.discounts = [];
    this.additionals = [];
    this.notesColors = ['note-personal', 'note-fav', 'note-work', 'note-social', 'note-important', 'note-dafault'];
    this.totalItems = 0;
    this.totalCf = 0;
    this.statusesHistory = [];
    this.statusesHistory = [];
    this.jobStatus = new JobStatus();
    this.jobOperatingStatus = new JobOperationStatus();
    this.typeJobAppoinment = '';
    this.jobAppoinments = new JobCarrierAppointmentView();
    this.jobId = '';
  }

  /**
   * inicaliza la informacion del job
   */
  ngOnInit(): void {
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id !== 'undefined') {
        this.jobId = params.id;
        this.loadJob(params.id);
      }
    });
  }
  /**
   * carga info del job
   * @param id id del job a consultar
   */
  loadJob(id) {
    this.helperService.showLoadingMxpro360();
    this.jobsService
      .getById(id)
      .then((response) => {
        this.job = response;
        this.chargeBulkyFee();
        this.getAdditionalAndDiscount();
        this.loadColorInventory();
        this.loadCustomerPayment();
        this.loadStatusesHistory(id);
        this.loadJobNotes(id);
        this.loadJobAppoinment(id);
      })
      .catch((error) => {
        this.helperService.showMessageError(error, id);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
   * carga informacion historial del job
   * @param id
   */
  loadStatusesHistory(id) {
    this.helperService.showLoadingMxpro360();
    this.statusesHistoryService
      .getStatusHistoryCharge(id)
      .then((response) => {
        this.statusesHistory = response;
      })
      .catch((error) => {
        this.helperService.showMessageError(error, id);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }
  /**
   * giarda notas al job
   */
  saveJobNotes() {
    this.helperService.showLoadingMxpro360();
    this.jobNotesService
        .saveNote(this.jobId, this.jobNote)
        .then(() => {
            this.jobNote = new JobCarrierNote();
            this.loadJobNotes(this.jobId);
        })
        .catch((error) => {
            this.helperService.showMessageError(error, {jobId: this.jobId, jobNote: this.jobNote});
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
  }
  /**
   * carga info de notas del job
   * @param id 
   */
  loadJobNotes(id) {
    this.helperService.showLoadingMxpro360();
    this.jobNotesService
      .getAll(id)
      .then((response) => {
        this.jobNotes = response;
      })
      .catch((error) => {
        this.helperService.showMessageError(error, id);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }
  /**
   * carga info de appointmets
   * @param id
   */
  loadJobAppoinment(id) {
    this.helperService.showLoadingMxpro360();
    this.jobsService
      .getJobAppoinment(id)
      .then((response) => {
        this.jobAppoinments = response;
      })
      .catch((error) => {
        this.helperService.showMessageError(error, id);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
   * carga colores para los inventarios
   */
  private loadColorInventory() {
    const tmpColors = ['note-personal', 'note-fav', 'note-work', 'note-social', 'note-important', 'note-dafault'];
    if (this.job.estimate_inventory_container.length > tmpColors.length) {
      this.notesColors = [];
      for (let i = 0; i < this.job.estimate_inventory_container.length; i++) {
        this.notesColors.push(tmpColors[Math.random() * tmpColors.length]);
      }
    }
    // recorre containers e items para calcular totales
    for (const key of this.job.estimate_inventory_container) {
      for (const item of key.items) {
        this.totalItems = this.totalItems + item.quantity;
        this.totalCf = this.totalCf + item.inventory_item.cubic_feet;
      }
    }
  }

  /**
   * carga los pagos del cliente para calcular lo adeudado
   */
  private loadCustomerPayment() {
    for (const balance of this.job.balances) {
      if (balance.status === 'PAID' && balance.target !== 'STORAGE') {
        this.customerPayment = this.customerPayment + balance.amount;
      }
    }
  }

  /**
   * carga info de sobre costos por carga de elementos pesados
   */
  private chargeBulkyFee() {
    this.bulkyFee = [];
    const that = this;
    // recorre contenedores de items
    for (let container of this.job.estimate_inventory_container) {
      // recorre items de un contenedor
      for (let item of container['items']) {
        // valida y suma el bulkyFee
        if (item.inventory_item.bulky_fee) {
          if (item.inventory_item.bulky_fee.name) {
            that.bulkyFee.push(item);
          }
        }
      }
    }
  }

  /**
   * carga los descuentos y sobre cargos
   */
  private getAdditionalAndDiscount() {
    this.discounts = [];
    this.additionals = [];
    // recorre additional charges y determia si son descuentos o cargos
    for (const item of this.job.estimate_additional_charges) {
      if (item.amount < 0) {
        this.discounts.push(item);
      } else {
        this.additionals.push(item);
      }
    }
  }

  /**
   * se encarga de guardar informacion de historial
   * @param saveHistory 
   */
  saveOperatingStatus(saveHistory: boolean) {
    if (saveHistory) {
        this.job.statuses = new JobStatus();
        this.job.statuses.operation = this.jobStatus.operation;
    }
    let data = {
      job_status: this.job.statuses,
      job_operation_status: this.jobOperatingStatus,
      save_history: saveHistory
    }
    this.helperService.showLoadingMxpro360();
    this.statusesHistoryService
      .saveStatus(this.jobId, data)
      .then((response) => {
        this.loadStatusesHistory(this.jobId);

      })
      .catch((error) => {
        this.helperService.showMessageError(error, {jobId: this.jobId, data: this.jobId});
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
   * guarda info de job appointments
   */
  saveJobAppoinment() {
    const moving = new JobCarrierAppointmentView();
    moving.box_delivery_day = this.jobAppoinments.box_delivery_day;
    moving.delivery_day = this.jobAppoinments.delivery_day;
    moving.pack_day = this.jobAppoinments.pack_day;
    moving.pickup_day = this.jobAppoinments.pickup_day;

    this.helperService.showLoadingMxpro360();
    this.jobsService
      .saveJobAppoinment(this.jobId, moving)
      .then(() => {
        this.loadJobAppoinment(this.jobId);
      })
      .catch(() => {
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }
  /**
   * carga datos de fechas
   * @param date 
   * @param item 
   */
  changeDayDate(date, item) {
    this.jobAppoinments[item].date = date;
  }
  /**
   * agrega eventos de jquery despues de iniciar la vista
   */
  ngAfterViewInit(): void {
    jQuery('nav a').click(function (e) {
      e.preventDefault();		//evitar el eventos del enlace normal
      var strAncla = jQuery(this).attr('href'); //id del ancla
      jQuery('body,html').stop(true, true).animate({
        scrollTop: jQuery(strAncla).offset().top
      }, 1000);

    });
  }

  /**
   * abre modal de carriers seteando la informacion inicial
   */
  openModalCarrierNotes() {
    jQuery(this.modalCarrierNotes.nativeElement).modal('show');
  }
  /**
   * abre modal para guardar historiales
   */
  openModalStatusHistory() {
    jQuery(this.modalOperatingStatusHistory.nativeElement).modal('show');
  }

}
