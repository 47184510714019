<!-- CONTENT BREADCRUMB -->

<!-- CONTENT BREADCRUMB -->
<div id="content" class="main-content d-flex justify-content-center padd-t40">
    <div class="container justify-content-center">
        <div class="container">
            <div class="row layout-top-spacing marg-t0 step-progress" id="cancel-row">


                <ul *ngIf="tabBank == 'active'">
                    <li class="{{tabBank}}">
                        <!-- Circulo e icono -->
                        <span class="marker-number"><i class="fas fa-university fa-2x icon"></i></span>
                        <!-- Linea -->
                        <span class="marker-line"></span>
                        <!-- Texto debajo del circulo -->
                    </li>
                    <li class="{{tabResult}}">
                        <!-- Circulo e icono -->
                        <span class="marker-number"><i class="fa-solid fa-file-invoice fa-2x icon"></i></span>
                        <!-- <span class="marker-text"></span> -->
                    </li>
                </ul>

                <ng-container *ngIf="tabBank == 'active'">
                    <div class="row col-lg-12 m-auto layout-spacing pt-4">

                        <div class="col-12 col-md-1 padd-0">
                        </div>

                        <div class="col-12 col-md-5 padd-0">
                            <h3 class="">
                                <strong>Payment via {{paymentMethod.name}}</strong>
                            </h3>

                            <div class="widget-content-mbl" *ngIf="paymentMethod.description; else noneDescription">
                                <!-- Payment Successful -->
                                <div class="font-16 marg-wb-t35">
                                    <p class="text-method" [innerHTML]="paymentMethod.description">
                                    </p>
                                </div>
                            </div>
                            <ng-template #noneDescription>
                                <div class="font-16 widget-content-mbl marg-wb-t35">
                                    Make the bank transfer with the following information. Attach proof-of-payment
                                    (screenshot,
                                    receipt, etc.), and submit it in the field provided below. Then, notify your agent
                                    that
                                    the
                                    payment has been made.
                                </div>
                            </ng-template>

                            <div class="col-12 pd-0 margin-t15 widget-content-mbl marg-wb-t35">
                                <div class="infobox-3 infobox-amount mt-0">
                                    <div class="info-icon">
                                        <i class="fas fa-dollar-sign"></i>
                                    </div>
                                    <h5 class="info-heading">Payment amount</h5>
                                    <div class="amount">${{balance.amount}}</div>
                                </div>
                            </div>
                            <!-- 
                            <br>
    
                            <div class="col-12 pr-0 padd-l0">
                                <div class="infobox-3 mt-0">
                                    <div class="info-icon">
                                        <i class="far fa-building"></i>
                                    </div>
                                    <h5 class="info-heading">Company Information</h5>
                                    <div class="info-text m-0">New City Moving</div>
                                    <div class="info-text m-0">2148 Embassy Drive</div>
                                    <div class="info-text m-0">LANCASTER, PA 17601</div>
                                </div>
                            </div>
    
                            <br>
    
                            <div class="col-12 pr-0 padd-l0">
                                <div class="infobox-3 mt-0">
                                    <div class="info-icon">
                                        <i class="fas fa-university"></i>
                                    </div>
                                    <h5 class="info-heading">Bank Account</h5>
    
                                    <div class="col-12 row m-0 p-0">
                                        <div class="col-3 col-md-6"></div>
                                        <div class="col-9 col-md-6 p-0 bank-account-info">
                                            <div><strong>Account:</strong>898104370534</div>
                                            <div><strong>Routing:</strong>063100277</div>
                                            <div><strong>Wire:</strong>026009593</div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                        </div>

                        <div class="col-12 col-md-1 padd-0">
                        </div>

                        <div class="col-12 col-sm-5 padd-0 margin-t15">
                            <div class=" widget widget-content widget-content-area b-radius-10">

                                <div class="text-center">
                                    <img src="assets/img/upload-file.png" alt="" srcset="">
                                </div>

                                <div class="text-center mt-4">
                                    <button class="btn btn-success width-200" (click)="uploadFiles()">Upload</button>
                                </div>

                            </div>
                        </div>

                    </div>
                </ng-container>
                <ng-container *ngIf="tabResult == 'active'">
                  
                    <section class="row d-flex justify-content-center invoice">

                        <div class="invoice-container mb-3">
                            <div class="invoice-inbox col-12 pt-2 pb-2">
                                <div id="ct" class="">
                                    <div class="invoice-00001">
                    
                                        <!-- **** Payment Successful **** -->
                                        <div class="content-section">
                                            <div class="col-12 title-payment-pending">
                                                <h4 class="m-0 text-payment-pending">
                                                    Payment Pending Review
                                                </h4>
                                                <span class="desc-payment">
                                                    Your payment has been uploaded. For security reasons, it goes through a verification process
                                                </span>
                                            </div>
                                        </div>
                    
                                        <!-- **** Payment Failed **** -->
                                        <!-- <div class="content-section">
                                            <div class="row">
                                                <div class="col-2 pr-0 text-center">
                                                    <i class="far fa-times-circle icon-payment-failed"></i>
                                                </div>
                                                <div class="col-10 pl-0">
                                                    <h4 class="m-0 text-payment-failed">Payment Failed</h4>
                                                    <span class="desc-payment">Your payment coundn´t be processed! Details of transactions are incluided below</span>
                                                </div>
                                            </div>                                                                                                    
                                        </div>   -->
                    
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-2 download-invoice">
                                <button class="btn btn-secondary">Download</button>
                            </div> -->
                        </div>
                    
                        <div class="invoice-container">
                            <div class="invoice-inbox">
                    
                                <div id="ct" class="">
                    
                                    <div class="invoice-00001">
                                        <div class="content-section">
                    
                                            <div class="inv--head-section inv--detail-section border-0 mb-2">
                    
                                                <div class="row text-mbl-center">
                    
                                                    <div class="col-sm-6 col-12 mr-auto">
                                                        <img class="company-logo" 
                                                            src="{{environment.az_api.cdn}}/{{authService.session.workspace.id}}/media-files/{{responseTransaction.company.logo}}"
                                                            alt="company">
                                                    </div>
                    
                                                    <div class="col-sm-6 text-sm-right info-company">
                                                        <p class="inv-email-address">
                                                            {{responseTransaction.company.name}}
                                                        </p>
                                                        <p class="inv-email-address">
                                                            {{responseTransaction.company.address.street}}
                                                        </p>
                                                        <p class="inv-email-address">
                                                            {{responseTransaction.company.address.city}},
                                                            {{responseTransaction.company.address.state}}
                                                            {{responseTransaction.company.address.zip_code}}
                                                        </p>
                                                        <p class="inv-email-address">
                                                            <span>ICC MC: </span>
                                                            {{responseTransaction.company.license}}
                                                        </p>
                                                        <p class="inv-email-address">
                                                            <span>US D.O.T: </span>
                                                            {{responseTransaction.company.dot}}
                                                        </p>
                                                    </div>
                    
                                                </div>
                    
                                            </div>
                    
                                            <div class="inv--detail-section inv--customer-detail-section mt-4">
                    
                                                <div class="row">
                    
                                                    <div class="col-sm-6 text-left">                                                        
                                                        <p class="inv-created-date">
                                                            <span class="inv-title title-details">Invoice Date : </span>                                                           
                                                            <span class="">{{balance.created | dateTime}}</span>                                                  
                                                        </p>
                                                    </div>
                                                    <div class="col-sm-6 align-self-center header-company-info invoice-to">
                                                        <p class="title-details mb-1">Invoice To</p>
                                                        <p>
                                                            <span class="inv-name">Name:</span>
                                                            <span class="invoice-to-name">{{balance.customer.name}} {{balance.customer.last_name}}</span>
                                                        </p>
                                                        <p>
                                                            <span class="inv-phone">Phone:</span>
                                                            <span>{{balance.customer.phone}}</span>
                                                        </p>
                                                        <p>
                                                            <span class="inv-email">Email:</span>
                                                            <span class="invoice-to-email">{{balance.customer.email}}</span>
                                                        </p>
                                                    </div>
                                                </div>
                    
                                                <div class="row mt-4">
                                                    <div class="col-sm-6 payment-info">                                           
                                                        <p class="title-details mb-1">Payment Info</p>                                                
                                                        <p>
                                                            <span class="inv-name">Concept:</span>
                                                            <span>{{balance.concept}}</span>
                                                        </p>
                                                        <p>
                                                            <span class="inv-name">Payment Method:</span>
                                                            <span>{{paymentMethod.name}}</span>
                                                        </p>          
                                                    </div>
                    
                                                    <!-- Payment Successful -->
                                                    <div class="col-sm-6 align-self-end inv--payment-info">
                                                        <p>
                                                            <span class="txt-payment-status">Payment Status:</span>
                                                            <span class="txt-approved">In Process</span>
                                                        </p>
                                                        <div class="total-section-successful mt-2">
                                                            <span>Amount Paid</span> 
                                                            <span>${{balance.amount}}</span>
                                                        </div>
                                                    </div>
                    
                                                    <!-- Payment Failed -->
                                                    <!-- <div class="col-sm-6 align-self-end inv--payment-info">
                                                        <p>
                                                            <span class="txt-payment-status">Payment Status:</span>
                                                            <span class="txt-failed">Denied</span>
                                                        </p>
                                                        <div class="total-section-failed mt-2">
                                                            <span>Amount Paid</span> 
                                                            <span>{{viewBalance.amount | currency}}</span>
                                                        </div>
                                                    </div> -->
                    
                    
                                                    <div class="col-xl-6 col-lg-5 col-md-6 col-sm-8 col-12 order-sm-0 order-1">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row invoice-container mt-3" style="width: 750px;">
                            <div class="invoice-inbox col-12">
                                <div id="ct" class="">
                                    <div class="invoice-00001">
                                        <div class="content-section">
                                            <div class="response-payment-status">response_payment_status:</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </section>
                
                </ng-container>

            </div>
        </div>
    </div>
</div>