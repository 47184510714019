import { File } from './file';
import { Document } from './document';

export class Payment {
    public id: string;
    public date: number;
    public gateway: string;
    public amount: number;
    public remarks: string;
    public method_id: string;
    public status: 'ACCEPTED' | 'PROCESSING' | 'DECLINED' | 'VOIDED' | 'REFUNDED' | 'PENDING_REVIEW';
    public customer_id: string;
    public employee_id: string;
    public payment_profile_id: string;
    public balance_id: string;
    public company_id: string;
    public vouchers: Array<File>;
    public document: Document;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.date = null;
        this.gateway = null;
        this.amount = null;
        this.remarks = null;
        this.method_id = null;
        this.status = null;
        this.customer_id = null;
        this.employee_id = null;
        this.payment_profile_id = null;
        this.balance_id = null;
        this.company_id = null;
        this.vouchers = [];
        this.document = new Document();
        this.created = null;
        this.updated = null;
    }
}
