import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BoardJobView } from "src/app/entities/board-job-view";
import { DispatchContacts } from "src/app/entities/dispatch-contact";
import { States } from "src/app/entities/states";
import { Workspace } from "src/app/entities/workspace";
import { BoardJobsService } from "src/app/services/board-jobs.service";
import { DispatchContactsService } from "src/app/services/dispatch-contacts.service";
import { HelperService } from "src/app/services/helper.service";
declare const jQuery;
declare const swal;
@Component({
	selector: "app-vip-load-board",
	templateUrl: "./vip-load-board.component.html",
	styleUrls: ["./vip-load-board.component.scss"],
})
export class VipLoadBoardComponent implements OnInit {

	workspaceId: string;
	workspace: Workspace;
	dispatchContacts: DispatchContacts[];

	// contiene la lista de los jobs ofrecidos
	boardJobs: Array<BoardJobView>;
	public state: string;
	public states: States;
	public zipcode: string;
	public radius: string;
	constructor(
		private currentRoute: ActivatedRoute,
		private boardJobsService: BoardJobsService,
		private helperService: HelperService,
		private dispatchContactsService: DispatchContactsService,
		private router: Router
	) {
		this.boardJobs = [];
		this.workspaceId = "";
		this.states = new States();
		this.state = "";
		this.radius = "";
		this.zipcode = "";

        this.workspace = new Workspace();
        this.dispatchContacts = [];
	}

	ngOnInit(): void {
		this.workspace.name = "FULLNATION MOVING SERVICES"; 
		this.currentRoute.params.subscribe((params) => {
			if (typeof params.id !== "undefined") {
				this.workspaceId = params.id;
				this.loadPublicJobs(params.id);
                this.load();
				this.loadWorkspace(params.id);
			}
		});
	}

    private load() {
        jQuery.fancybox.showLoading();
        this.dispatchContactsService
            .getAll(this.workspaceId)
            .then((response) => {
                this.dispatchContacts = response;
            })
            .catch((error) => {
                console.error('error', error);
            })
            .finally(() => {
                jQuery.fancybox.hideLoading();
            });
    }

	/**
	 * Carga los jobs en el tablero de trabajos publicos
	 */
	public loadPublicJobs(id): void {
		this.helperService.showLoadingMxpro360();
		this.boardJobsService
			.getVip(id)
			.then((response) => {
				this.boardJobs = response;
				console.log('this.boardJobs ==>', this.boardJobs);
				
			})
			.catch((error) => {
				this.helperService.showMessageError(error, {});
			})
			.finally(() => {
				this.helperService.hideLoadingMxpro360();
			});
	}

	loadWorkspace(id){
        this.boardJobsService
        .loadWorkspace(this.workspaceId )
        .then((response) => {
            this.workspace = response;            
        })
        .catch((error) => {
            this.helperService.showMessageError(error, id);
        })
        .finally(() => {
            this.helperService.hideLoadingMxpro360();
        });
    }

	getIt() {
		jQuery(".modal-backdrop").remove();
		this.router.navigateByUrl("/login?workspace=" + this.workspaceId);
	}

	forState() {
		if (this.state === "") {
			swal({
				title: "Ops",
				text: "Please select a state",
				type: "error",
			});
			return;
		}

		this.helperService.showLoadingMxpro360();
		this.boardJobsService
			.getVipByState(this.workspaceId, this.state)
			.then((response) => {
				this.boardJobs = response;
				console.log('his.boardJobs ===>', this.boardJobs);
				
			})
			.catch((error) => {
				console.log("error ==>", error);
				this.helperService.showMessageError(error, this.workspaceId);
			})
			.finally(() => {
				this.helperService.hideLoadingMxpro360();
			});
	}

	forZipcodeAndRAdius() {
		if (this.zipcode === "" || this.radius === "") {
			swal({
				title: "Ops",
				text: "Please select a zipcode and radius",
				type: "error",
			});
			return;
		}

		this.helperService.showLoadingMxpro360();
		this.boardJobsService
			.getVipByZipcodeAndRAdius(this.workspaceId, this.zipcode, this.radius)
			.then((response) => {
				this.boardJobs = response;
			})
			.catch((error) => {
				console.log("error ==>", error);

				this.helperService.showMessageError(error, this.workspaceId);
			})
			.finally(() => {
				this.helperService.hideLoadingMxpro360();
			});
	}
}
