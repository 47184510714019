import { Pipe, PipeTransform } from '@angular/core';

declare const moment;

@Pipe({
    name: 'date'
})
export class DatePipe implements PipeTransform {

    transform(unixDate: number = null): string {

        if(unixDate === null){
            return moment().format('MMM Do YYYY');
        }
        
        return moment(unixDate).format('MMM Do YYYY');
    }

}
