import { Company } from 'src/app/entities/company';
import { PaymentProfile } from 'src/app/entities/payment-profile';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BalanceView } from '../../entities/balance-view';
import { CarrierPayment } from '../../entities/carrier-payment';
import { PaymentMethod } from '../../entities/payment-method';
import { ResponseTransaction } from '../../entities/response-transaction';
import { BalancesService } from '../../services/balances.service';
import { PaymentsService } from '../../services/payments.service';
import { HelperService } from 'src/app/services/helper.service';

declare const jQuery;
@Component({
  selector: 'app-payments-ach',
  templateUrl: './payments-ach.component.html',
  styleUrls: ['./payments-ach.component.scss']
})
export class PaymentsAchComponent implements OnInit {

  public paymentProfile: PaymentProfile;

  public paymentMethod: PaymentMethod;

  public balance: BalanceView;

  private idPaymentMethod: string;

  public tabBank: string;
  public tabResult: string;

  public responseTransaction: ResponseTransaction;

  constructor(
    private balancesService: BalancesService,
    private currentRoute: ActivatedRoute,
    private helperService: HelperService,
    private paymentsService: PaymentsService,
  ) {
    this.paymentMethod = new PaymentMethod();
    this.paymentProfile = new PaymentProfile();
    this.tabBank = 'active';
    this.tabResult = 'inactive';
    this.balance = new BalanceView();
    this.responseTransaction = new ResponseTransaction();
  }

  ngOnInit(): void {
    this.currentRoute.params.subscribe(params => {
      if (typeof params.id_balance !== 'undefined' && typeof params.id_method !== 'undefined') {
        this.idPaymentMethod = params.id_method;
        this.loadPaymentMethod(params.id_method);
        this.loadPayment(params.id_balance);
      }
    });
  }

  private loadPaymentMethod(idMethod: string): void {
    this.helperService.showLoadingMxpro360();
    this.paymentsService.
      getPaymentMethod(idMethod).
      then(async (response) => {
        console.log('response', response);
        this.paymentMethod = response;
      })
      .catch((error) => {
        this.helperService.showMessageError(error, idMethod);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  private loadPayment(id) {
    this.helperService.showLoadingMxpro360();
    this.balancesService
      .getPendingBalanceById(id)
      .then((response) => {
        this.balance = response;
      })
      .catch((error) => {
        this.helperService.showMessageError(error, id);
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  public sendPayment() {
    this.helperService.showLoadingMxpro360();
    // inicializa datos para post de pagos
    const dataPost: CarrierPayment = new CarrierPayment();


    dataPost.reference = this.balance.id.substring(4, 24);
    dataPost.amount = this.balance.amount;
    dataPost.payment_profile.bank_information = this.paymentProfile.bank_information;

    // envia pago a api
    this.paymentsService.
      sendPayment(this.balance.id, this.idPaymentMethod, dataPost).
      then(async (response) => {
        this.responseTransaction = response;
        this.changeTab();
      })
      .catch((error) => {
        this.helperService.showMessageError(error, {balance: this.balance.id, idPaymentMethod: this.idPaymentMethod, dataPost: dataPost});
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  public changeTab(): void {
    // Realiza el cambio de tab al dar clic en next
    if (this.tabBank === 'active') {
      this.tabBank = 'inactive';
      this.tabResult = 'active';
    }
  }
}
