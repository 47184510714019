<!-- CONTENT BREADCRUMB -->

<div id="content" class="main-content">

    <div class="container text-center section-title pad-t70">
        <p class="h6 text-uppercase text-primary">Balances</p>
        <div class="col-lg-6 col-md-6 col-sm-9 filtered-list-search mx-auto mb-0">
            <form class="form-inline my-2 my-lg-0 justify-content-center">
                <div class="w-100">
                    <input type="text" class="w-100 form-control product-search br-30" id="input-search" placeholder="Search..." >
                    <button class="btn btn-search" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg></button>
                </div>
            </form>
        </div>
    </div> 

    <div class="layout-px-spacing-head pad-0">
        <div class="row" id="cancel-row">

            <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing header-table">
                <div class="widget-content widget-content-area widget-content-head br-6 py-2">
                    <table id="invoice-list" class="table table-hover m-0" style="width:100%">
                        <thead>
                            <tr>
                                <th>Job #</th>
                                <th>Concept</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>

            <div id="resize"></div>

            <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing layout-spacing body-table">
                <div class="widget-content widget-content-area br-6">
                    <table id="invoice-list" class="table table-hover" style="width:100%">
                        <tbody>

                            <tr *ngFor="let balance of balances">
                                <td class="tdHeader headerCollapse">
                                    <button class="btn-arrow rotate"><i class="fas fa-chevron-down"></i></button>
                                    <div>
                                        <span class="key">Job: </span>{{balance.job_number}}
                                        <span class="badge badge-warning ml-3">Pending</span>
                                    </div>
                                </td>
                                <td class="rowCollapse" data-label="Customer">
                                    <div class="arrow-down"></div>
                                    <div><span class="key">Job: </span>{{balance.job_number}}</div>

                                </td>

                                <td class="rowCollapse" data-label="Concept">
                                    <div class="concept text-justify"><br class="break">{{balance.concept}}</div>
                                </td>
                                <td class="rowCollapse t-align" data-label="Amount">
                                    <span class="inv-amount">{{balance.amount | currency}}</span>
                                </td>
                                <td class="rowCollapse t-align" data-label="Status">
                                    <span class="badge badge-warning">{{balance.status}}</span>
                                </td>
                                <td class="rowCollapse t-align" data-label="Action">
                                    <button *ngIf="balance.status == 'PENDING'" (click)="balancetSelect = balance.id"
                                        class="btn btn-success m-0" data-toggle="modal"
                                        data-target="#modalPaymentMethod" (click)="loadPaymentMethods(balance.id)">Pay</button>
                                    <button *ngIf="balance.status == 'PAID'" (click)="loadPayment(balance.id)"
                                        class="btn btn-info m-0" data-toggle="modal"
                                        data-target="#modalPaymentView">View</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

        </div>
    </div>
</div>
<!--  END CONTENT AREA  -->

<!-- Modal -->
<div class="modal fade" id="modalPaymentMethod" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modalPaymentMethod" role="document">
        <div class="modal-content">

            <div class="modal-body">
                <div class="col-lg-12 layout-spacing">
                    <div class="mb-4">
                        <h5 class="modal-title mb-2" id="exampleModalLabel">
                            <strong>Payment Methods</strong>
                        </h5>
                        <span>
                            What type of payment method would you like to use?
                        </span>
                        <button type="button" class="close close-button" data-dismiss="modal" aria-label="Close">
                            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </button>
                    </div>

                    <a *ngFor="let method of paymentMethods.payment_methods" data-backdrop="false" y
                        data-dismiss="modal" (click)="redirectToPayment(method, paymentMethods.id)">
                        <div class="widget-content widget-content-area method-content cursor mb-2 px-3 py-2">
                            <div class="media d-flex align-items-center">
                                <img class="align-self-center rounded pr-4" src="{{method.logo}}"
                                    alt="pic1">
                                <div class="media-body">
                                    <p class="media-text">{{method.name}}</p>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-chevron-right">
                                    <polyline points="9 18 15 12 9 6"></polyline>
                                </svg>
                            </div>
                        </div>
                    </a>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal View Payment -->
<div class="modal fade" id="modalPaymentView" tabindex="-1" role="dialog" aria-labelledby="modalPaymentView"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-payment-view" role="document">
        <div class="modal-content">

            <div class="modal-body">
                <div class="col-lg-12 layout-spacing">
                    <div class="mb-4">
                        <h5 class="modal-title mb-2" id="exampleModalLabel">
                            <strong>Invoice / Job: <strong>{{viewBalance.job_number}}</strong></strong>
                        </h5>
                        <!-- <span>
                            Job: <strong>NCE02134</strong> 
                        </span> -->
                        <button type="button" class="close close-button" data-dismiss="modal" aria-label="Close">
                            <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                        </button>
                    </div>

                    <section class="row d-flex justify-content-center invoice">


                        <div class="row invoice-container mb-3" style="width: 750px;">
                            <div class="invoice-inbox col-12 pt-2 pb-2">
                                <div id="ct" class="">
                                    <div class="invoice-00001">

                                        <!-- **** Payment Successful **** -->
                                        <div class="content-section">
                                            <div class="row">
                                                <div class="col-2 pr-0 text-center">
                                                    <i class="far fa-check-circle icon-payment-successful"></i>
                                                </div>
                                                <div class="col-10 pl-0">
                                                    <h4 class="m-0 text-payment-successful">Payment Successful</h4>
                                                    <span class="desc-payment-successful">Your payment has been
                                                        processed! Details of transactions are incluided below</span>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- **** Payment Failed **** -->
                                        <!-- <div class="content-section">
                                            <div class="row">
                                                <div class="col-2 pr-0 text-center">
                                                    <i class="far fa-times-circle icon-payment-failed"></i>
                                                </div>
                                                <div class="col-10 pl-0">
                                                    <h4 class="m-0 text-payment-failed">Payment Failed</h4>
                                                    <span class="desc-payment-successful">Your payment coundn´t be processed! Details of transactions are incluided below</span>
                                                </div>
                                            </div>                                                                                                    
                                        </div>   -->

                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-2 download-invoice">
                                <button class="btn btn-secondary">Download</button>
                            </div> -->
                        </div>

                        <div class="invoice-container" style="width: 750px;">
                            <div class="invoice-inbox">

                                <div id="ct" class="">

                                    <div class="invoice-00001">
                                        <div class="content-section">

                                            <div class="inv--head-section inv--detail-section border-0 mb-2">

                                                <div class="row">

                                                    <div class="col-sm-6 col-12 mr-auto">
                                                        <img class="company-logo"
                                                            src="{{environment.az_api.cdn}}/{{authService.session.workspace.id}}/media-files/{{viewBalance.company.logo}}"
                                                            alt="company">
                                                    </div>

                                                    <div class="col-sm-6 text-sm-right">
                                                        <p class="inv-email-address">{{viewBalance.company.name}}</p>
                                                        <p class="inv-email-address">
                                                            {{viewBalance.company.address.street}}</p>
                                                        <p class="inv-email-address">
                                                            {{viewBalance.company.address.city}},
                                                            {{viewBalance.company.address.state}}
                                                            {{viewBalance.company.address.zip_code}}</p>
                                                        <p class="inv-email-address">IC MC:
                                                            {{viewBalance.company.license}} </p>
                                                        <p class="inv-email-address">US D.O.T:
                                                            {{viewBalance.company.dot}}</p>
                                                    </div>

                                                </div>

                                            </div>

                                            <div class="inv--detail-section inv--customer-detail-section mt-4">

                                                <div class="row">

                                                    <div class="col-sm-6 text-left" *ngIf="viewBalance.payment">
                                                        <p class="inv-created-date mb-0"><span
                                                                class="inv-title">Transaction ID : </span>
                                                            <ng-container
                                                                *ngFor="let transaction of viewBalance.payment.transaction">
                                                                <span class="inv-date"
                                                                    *ngIf="transaction.status == 'PAID'">{{transaction.code}}</span>
                                                            </ng-container>

                                                        </p>
                                                        <p class="inv-created-date mb-0">
                                                            <span class="inv-title">Invoice Date : </span>
                                                            <ng-container
                                                                *ngFor="let transaction of viewBalance.payment.transaction">
                                                                <span class="inv-date"
                                                                    *ngIf="transaction.status == 'PAID'">{{transaction.created
                                                                    | date}}</span>
                                                            </ng-container>
                                                        </p>
                                                    </div>
                                                    <div
                                                        class="col-sm-6 align-self-center header-company-info invoice-to">
                                                        <p class="title-details mb-1">Invoice To</p>
                                                        <p><span class="inv-name">Name:</span>
                                                            <span>{{viewBalance.carrier_contact.name}}</span></p>
                                                        <p><span class="inv-phone">Phone:</span>
                                                            <span>{{viewBalance.carrier_contact.phone}}</span></p>
                                                        <p><span class="inv-email">Email:</span>
                                                            <span>{{viewBalance.carrier_contact.email}}</span></p>
                                                    </div>
                                                </div>

                                                <div class="row mt-4">
                                                    <div class="col-sm-6">
                                                        <div class="inv--payment-info" *ngIf="viewBalance.payment">
                                                            <p class="title-details mb-1">Payment Info</p>
                                                            <ng-container
                                                                *ngFor="let transaction of viewBalance.payment.transaction">
                                                                <ng-container *ngIf="transaction.status == 'PAID'">
                                                                    <p class=""><span
                                                                            class=" inv-concept">Concept:</span>
                                                                        <span>Initial payment</span></p>
                                                                    <p><span class=" inv-payment-type">Payment Type:
                                                                        </span> <span>{{transaction.card_type}}</span>
                                                                    </p>
                                                                    <p><span class=" inv-product">Product:</span>
                                                                        <span>{{transaction.card_number}}</span></p>
                                                                </ng-container>
                                                            </ng-container>
                                                        </div>
                                                    </div>

                                                    <!-- Payment Successful -->
                                                    <div class="col-sm-6 align-self-end text-right inv--payment-info">
                                                        <p><span class=" inv-subtitle">Payment Status:</span>
                                                            <span>Approved</span></p>
                                                        <div class="total-section-successful mt-2 pr-3">Amount Paid
                                                            {{viewBalance.payment.amount | currency}}</div>
                                                    </div>

                                                    <!-- Payment Failed -->
                                                    <!-- <div class="col-sm-6 align-self-end text-right inv--payment-info">
                                                            <p><span class=" inv-subtitle">Payment Status:</span> <span>Denied</span></p>
                                                            <div class="total-section-failed mt-2 pr-3">Amount Paid $8900.00</div>                                                                                
                                                        </div> -->


                                                    <div
                                                        class="col-xl-6 col-lg-5 col-md-6 col-sm-8 col-12 order-sm-0 order-1">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row invoice-container mt-3" style="width: 750px;">
                            <div class="invoice-inbox col-12">
                                <div id="ct" class="">
                                    <div class="invoice-00001">
                                        <div class="content-section">
                                            <div class="response-payment-status">response_payment_status:</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

            </div>
        </div>
    </div>
</div>