import { Address } from './address';

export class Company {
    public id: string;
    public logo: string;
    public name: string;
    public email: string;
    public phone: string;
    public dot: string;
    public license: string;
    public website: string;
    public address: Address;
    public created: number;
    public updated: number;


    constructor() {
        this.id = null;
        this.logo = null;
        this.dot = null;
        this.name = null;
        this.email = null;
        this.phone = null;
        this.website = null;
        this.license = null;
        this.address = new Address();
        this.created = null;
        this.updated = null;
    }
}
