<div id="content" class="main-content">

    <div class="container text-center section-title pad-t70">
        <p class="h6 text-uppercase text-primary">Load Board</p>
        <div class="col-lg-6 col-md-6 col-sm-9 filtered-list-search mx-auto mb-0">
            <form class="form-inline my-2 my-lg-0 justify-content-center">
                <div class="w-100">
                    <input type="text" class="w-100 form-control product-search br-30" id="input-search" placeholder="Search..." >
                    <button class="btn btn-search" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg></button>
                </div>
            </form>
        </div>
    </div>

    <div class="layout-px-spacing-head pad-0">
        <div class="row" id="cancel-row">

            <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing header-table">
                <div class="widget-content widget-content-area widget-content-head br-6 py-2">
                    <table id="invoice-list" class="table table-hover m-0" style="width:100%">
                        <thead>
                            <tr>                                
                                <th>Customer</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Volume</th>
                                <th>Special Conditions</th>
                                <th>Offer Value</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>            
        
            <div id="resize"></div>

            <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing layout-spacing body-table">
                <div class="widget-content widget-content-area br-6">
                    <table id="invoice-list" class="table table-hover" style="width:100%">                        
                        <tbody>
                           
                            <tr *ngFor="let boardJob of boardJobs" [ngClass]="{'vip-job': boardJob.vip }" >
                                <td class="tdHeader headerCollapse">
                                    <button class="btn-arrow rotate">
                                        <i class="fas fa-chevron-down"></i>
                                    </button>                                    
                                    <div>
                                        <i class="fas fa-crown icon-vip-job"></i> 
                                        <span class="key">Job: </span>{{boardJob.job.document_code}}
                                        <span class="badge badge-success ml-3">{{boardJob.offer | currency}}</span>
                                    </div>
                                </td>                                
                                <td class="rowCollapse" data-label="Customer">
                                    <div class="arrow-down"></div>                 
                                    <i class="fas fa-crown web bs-tooltip" title="VIP Job"></i>                                                                                         
                                    <div><span class="key">Job: </span>{{boardJob.job.document_code}}</div>
                                    <div><span class="key">Service: </span>{{boardJob.job.service}}</div>
                                </td>
                                <td class="rowCollapse" data-label="From">                                    
                                    <div class="text-right">{{boardJob.job.from.address.state}} {{boardJob.job.from.address.zip_code}}</div>
                                    <div class="content-between">
                                        <span class="inv-date-start mr-2 success" title="Range Start">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                        </span>
                                        {{boardJob.job.from.range_start | date }}
                                    </div>
                                    <div class="content-between">
                                        <span class="inv-date-end mr-2 danger" title="Range End">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                        </span>
                                        {{boardJob.job.from.range_end | date }}
                                    </div>
                                </td>
                                <td class="rowCollapse" data-label="To">
                                    <div class="text-right">{{boardJob.job.to.address.state}} {{boardJob.job.to.address.zip_code}}</div>
                                    <div class="content-between">
                                        <span class="inv-date-start mr-2 success" title="Range Start">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                        </span>
                                        {{boardJob.job.to.range_start | date }}
                                    </div>
                                    <div class="content-between">
                                        <span class="inv-date-end mr-2 danger" title="Range End">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                        </span>
                                        {{boardJob.job.to.range_end | date }}
                                    </div>
                                </td> 
                                <td class="rowCollapse" data-label="Volume">
                                    <div><span class="key">lb:</span> {{boardJob.job.volume.lbs}} </div>
                                    <div><span class="key">cf:</span> {{boardJob.job.volume.cf}} </div>
                                    <div><span class="key">miles:</span> {{boardJob.job.volume.ml}} </div>
                                </td>                                
                                <td class="t-center rowCollapse special-conditions-container" data-label="Special Conditions">                                    

                                    <span *ngIf="boardJob.special_conditions == null" class="badge badge-danger">None</span>

                                    <div *ngIf="boardJob.special_conditions != null" class="special-conditions text-justify">
                                        <br class="break" >
                                        {{boardJob.special_conditions}}
                                    </div>

                                </td>
                                <td class="rowCollapse t-align" data-label="Offer Value">
                                    <span class="inv-amount"> {{boardJob.offer | currency}} </span>
                                </td>
                                <td class="rowCollapse t-align" data-label="Action">
                                    
                                    <button *ngIf="boardJob.candidate == null" (click)="openModal(boardJob.id)" class="btn btn-success" >
                                        Apply
                                    </button>

                                    <ng-container *ngIf="boardJob.candidate != null">
                                        <button *ngIf=" boardJob.candidate.status == 'PENDING'"  (click)="openModal(boardJob.id, boardJob.candidate)" class="btn btn-success" >
                                            Update Bid
                                        </button>
                                    </ng-container>

                                </td>                                
                            </tr>                                                      

                        </tbody>
                    </table>                    

                </div>
            </div>

        </div>
    </div>

</div>
<!--  END CONTENT AREA  -->



<!-- Modal -->
<div class="modal fade" id="modalApplyJob" #modalApplyJob data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Carrier Bid
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </button>
            </div>
            <div class="modal-body">

                <p class="modal-text text-justify">
                    The following value is the one that is paid for the job, if you perceive that the payment is not adequate to do the job, you can make a bid for the price that you consider pertinent. Additionally, you can add your opinions in the comments about the job and finally click on the save button to be registered as a candidate.    
                </p>

                <ng-container *ngIf="formData != null"> 
                    <br>

                    <div class="form-group">
                        <label for="bid" class="m-0">Bid</label>
                        <input type="number" step="0.01" [(ngModel)]="formData.bid" class="form-control" id="bid" placeholder="0.00">
                    </div> 
    
                    <div class="form-group">
                        <label for="comments" class="m-0">Comments</label>
                        <textarea class="form-control"[(ngModel)]="formData.comments" id="comments" rows="3" placeholder="Enter your comment here..."></textarea>
                    </div>
                </ng-container>

            </div>
            <div class="modal-footer">
                <button class="btn" [disabled]="formStatus != 'FILLING_OUT'" (click)="closeModal()">
                    <i class="flaticon-cancel-12"></i> 
                    Discard
                </button>
                <button type="button" [disabled]="formStatus != 'FILLING_OUT'" (click)="saveFormData()" class="btn btn-warning">
                    <div *ngIf="formStatus == 'SENDING'" class="spinner-border text-white mr-2 align-self-center loader-sm ">Loading...</div>
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
