import { Component, OnInit } from '@angular/core';

declare const jQuery;
@Component({
  selector: 'app-topbar-nav',
  templateUrl: './topbar-nav.component.html',
  styleUrls: ['./topbar-nav.component.scss']
})
export class TopbarNavComponent implements OnInit {

  selectOptNav: string;

  constructor() { }

  ngOnInit(): void {

    this.selectOptNav = 'my-jobs';
    if (localStorage.getItem('selectOptNav')) {
      this.selectOptNav = localStorage.getItem('selectOptNav');
    }

  }

  changeNav(opt){
    this.selectOptNav = opt;
    localStorage.setItem('selectOptNav', opt);
  }


  ngAfterViewInit(): void {

    var tabs = jQuery('.tabs');
    var selector = jQuery('.tabs').find('a').length;
    //var selector = jQuery(".tabs").find(".selector");
    var activeItem = tabs.find('.active');
    var activeWidth = activeItem.innerWidth();
    jQuery(".selector").css({
      "left": activeItem.position.left + "px", 
      "width": activeWidth + "px"
    });

    jQuery(".tabs").on("click","a",function(e){
      e.preventDefault();
      jQuery('.tabs a').removeClass("active");
      jQuery(this).addClass('active');
      var activeWidth = jQuery(this).innerWidth();
      var itemPos = jQuery(this).position();
      jQuery(".selector").css({
        "left":itemPos.left + "px", 
        "width": activeWidth + "px"
      });
    });
  }

}
