import { DocumentEsignature } from "./document-esignature";
import { File } from "./file";

export class Document {
    id: string;
    prefix: string;
    consecutive: string;
    code: string;
    document_type_id: string;
    text_file: string;
    text: string; // pre_pdf
    file: File; // pdf_file
    esignatures: DocumentEsignature;

    constructor(){
        this.id = null;
        this.prefix = null;
        this.consecutive = null;
        this.code = null;
        this.document_type_id = null;
        this.text_file = null;
        this.text = null;
        this.file = new File();
        this.esignatures = new DocumentEsignature();
    }
}
