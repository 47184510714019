export class PublicEstimateCharge {

    public cubic_feet_total_price: number;
    public packing_materials: number;
    public auto_transport: number;
    public fuel_surcharge_percentage: number;
    public fuel_surcharge: number;
    public packers_packing: number;
    public packers_unpacking: number;
    public insurance: number;
    public labor: number;
    public travel: number;

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.cubic_feet_total_price = 0;
        this.packing_materials = 0;
        this.auto_transport = 0;
        this.fuel_surcharge_percentage = 0;
        this.fuel_surcharge = 0;
        this.packers_packing = 0;
        this.packers_unpacking = 0;
        this.insurance = 0;
        this.labor = 0;
        this.travel = 0;
    }
}

