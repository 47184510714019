import { Address } from './address';

export class PublicEstimatePickup {

    public boxes_delivery_day: number;
    public pack_day: number;
    public range_start: number;
    public range_end: number;
    public pickup_day: number;
    public address: Address;

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.boxes_delivery_day = null;
        this.pack_day =  null;
        this.range_start = null;
        this.range_end = null;
        this.pickup_day = null;
        this.address = new Address();
    }
}
