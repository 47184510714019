export class Customer {
    
    id: string;
    name: string;
    last_name: string;
    email: string;
    phone: string;
    remarks: string;
    parent_id: string;
    company_id: string;
    created: number;
    updated: number;


    constructor() {
        this.id = null;
        this.name = null;
        this.last_name = null;
        this.email = null;
        this.phone = null;
        this.remarks = null;
        this.parent_id = null;
        this.company_id = null;
        this.created = null;
        this.updated = null;
    }
}
