<div id="content" class="main-content">

    <div class="container text-center section-title pad-t70">
        <p class="h6 text-uppercase text-primary">My Jobs</p>
        <div class="col-lg-6 col-md-6 col-sm-9 filtered-list-search mx-auto mb-0">
            <form class="form-inline my-2 my-lg-0 justify-content-center">
                <div class="w-100">
                    <input type="text" class="w-100 form-control product-search br-30" id="input-search"
                        placeholder="Search...">
                    <button class="btn btn-search" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
                            <circle cx="11" cy="11" r="8"></circle>
                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg></button>
                </div>
            </form>
        </div>
    </div>

    <div class="layout-px-spacing-head pad-0">
        <div class="row" id="cancel-row">
            <div id="resize"></div>

            <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing layout-spacing body-table">
                <div class="widget-content widget-content-area br-6">
                    <!-- <table id="invoice-list" class="table table-hover" style="width:100%">
                        <tbody>

                            <tr *ngFor="let jobAssigned of jobOutsourcings">
                                <td class="tdHeader headerCollapse">
                                    <button class="btn-arrow rotate"><i class="fas fa-chevron-down"></i></button>
                                    <div>
                                        <span class="key">Job: </span> {{jobAssigned.job.document_code}}
                                        <span class="badge badge-success ml-3"> {{jobAssigned.job.service}} </span>
                                    </div>
                                </td>
                                <td class="rowCollapse" data-label="Customer">
                                    <div class="arrow-down"></div>
                                    <div><span class="key">Job: </span>{{jobAssigned.job.document_code}}</div>
                                    <div><span class="key">Service: </span>{{jobAssigned.job.service}}</div>
                                </td>
                                <td class="rowCollapse" data-label="From">
                                    <div class="text-right"> {{jobAssigned.job.from.address.state}}
                                        {{jobAssigned.job.from.address.zip_code}} </div>
                                    <div class="content-between">
                                        <span class="inv-date-start mr-2 success" title="Range Start">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-calendar">
                                                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                <line x1="16" y1="2" x2="16" y2="6"></line>
                                                <line x1="8" y1="2" x2="8" y2="6"></line>
                                                <line x1="3" y1="10" x2="21" y2="10"></line>
                                            </svg>
                                        </span>
                                        {{jobAssigned.job.from.range_start | date }}
                                    </div>
                                    <div class="content-between">
                                        <span class="inv-date-end mr-2 danger" title="Range End">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-calendar">
                                                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                <line x1="16" y1="2" x2="16" y2="6"></line>
                                                <line x1="8" y1="2" x2="8" y2="6"></line>
                                                <line x1="3" y1="10" x2="21" y2="10"></line>
                                            </svg>
                                        </span>
                                        {{jobAssigned.job.from.range_end | date }}
                                    </div>
                                </td>
                                <td class="rowCollapse" data-label="To">
                                    <div class="text-right">{{jobAssigned.job.to.address.state}}
                                        {{jobAssigned.job.to.address.zip_code}}</div>
                                    <div class="content-between">
                                        <span class="inv-date-start mr-2 success" title="Range Start">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-calendar">
                                                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                <line x1="16" y1="2" x2="16" y2="6"></line>
                                                <line x1="8" y1="2" x2="8" y2="6"></line>
                                                <line x1="3" y1="10" x2="21" y2="10"></line>
                                            </svg>
                                        </span>
                                        {{jobAssigned.job.to.range_start | date }}
                                    </div>
                                    <div class="content-between">
                                        <span class="inv-date-end mr-2 danger" title="Range End">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-calendar">
                                                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                                <line x1="16" y1="2" x2="16" y2="6"></line>
                                                <line x1="8" y1="2" x2="8" y2="6"></line>
                                                <line x1="3" y1="10" x2="21" y2="10"></line>
                                            </svg>
                                        </span>
                                        {{jobAssigned.job.to.range_end | date }}
                                    </div>
                                </td>
                                <td class="rowCollapse" data-label="Volume">
                                    <div><span class="key">lb:</span> {{jobAssigned.job.volume.lbs}}</div>
                                    <div><span class="key">cf:</span> {{jobAssigned.job.volume.cf}} </div>
                                    <div><span class="key">miles:</span> {{jobAssigned.job.volume.ml | number: '1.2-2'}}
                                    </div>
                                </td>
                                <td class="rowCollapse t-align" data-label="Balance Owed On Job">
                                    <span class="inv-amount"> {{jobAssigned.owed_from_job | currency}} </span>
                                </td>
                                <td class="rowCollapse t-align" data-label="Money Owed To Broker">
                                    <span class="inv-amount"> {{jobAssigned.owed_to_broker | currency}} </span>
                                </td>
                                <td class="rowCollapse t-align" data-label="Carrier Pay">
                                    <span class="inv-amount"> {{jobAssigned.carrier_pay | currency}} </span>
                                </td>
                                <td class="rowCollapse t-align" data-label="Action">
                                    <button *ngIf="jobAssigned.status == 'PENDING_SIGNATURE'" class="btn btn-secondary" type="button" (click)="openModal(jobAssigned.id)">
                                        Sign
                                    </button>
                                    <button *ngIf="jobAssigned.status == 'WORKING_ON'" routerLink="/my-jobs/{{jobAssigned.job.id}}/view" class="btn btn-secondary" type="button">
                                        Detail
                                    </button>
                                </td>
                            </tr>

                        </tbody>
                    </table> -->
                    <table id="invoice-list" class="table table-hover" style="width:100%">
                        <tbody>
                            <ng-container *ngFor="let jobAssigned of jobOutsourcings; index as i;">
                                <tr [ngClass]="{'odd-row': i % 2 !== 0}">
                                    <td>
                                        <div><strong>Customer:</strong></div>
                                        <div><strong>Name: </strong><span *ngIf="['PENDING_TO_SEND_THE_JOB_ACCEPTANCE','PENDING_SIGNATURE'].indexOf(jobAssigned.status) >= 0">************</span><span *ngIf="jobAssigned.status == 'WORKING_ON'">{{jobAssigned.customer.name}} {{jobAssigned.customer.last_name}}</span></div>
                                        <div><strong>Phone: </strong><span *ngIf="['PENDING_TO_SEND_THE_JOB_ACCEPTANCE','PENDING_SIGNATURE'].indexOf(jobAssigned.status) >= 0">************</span><span *ngIf="jobAssigned.status == 'WORKING_ON'">{{jobAssigned.customer.phone}}</span></div>
                                        <div><strong>Email: </strong><span *ngIf="['PENDING_TO_SEND_THE_JOB_ACCEPTANCE','PENDING_SIGNATURE'].indexOf(jobAssigned.status) >= 0">************</span><span *ngIf="jobAssigned.status == 'WORKING_ON'">{{jobAssigned.customer.email}}</span></div>
                                    </td>
                                    <td>
                                        <div><strong>From:</strong></div>
                                        <div><strong>Address:</strong> {{jobAssigned.job.from.address.state}} {{jobAssigned.job.from.address.zip_code}}</div>
                                        <div><strong>Start: </strong>{{jobAssigned.job.from.range_start | date}} </div>
                                        <div><strong>End: </strong>{{jobAssigned.job.from.range_end | date}}</div>
                                    </td>
                                    <td>
                                        <div><strong>To:</strong></div>
                                        <div><strong>Address:</strong> {{jobAssigned.job.to.address.state}} {{jobAssigned.job.to.address.zip_code}}</div>
                                        <div><strong>Start: </strong>{{jobAssigned.job.to.range_start | date}} </div>
                                        <div><strong>End: </strong>{{jobAssigned.job.to.range_end | date}}</div>
                                    </td>
                                    <td>
                                        <div><strong>Job:</strong></div>
                                        <div><strong>Service: </strong>{{jobAssigned.job.service}}</div>
                                        <div><strong>Job #: </strong>{{jobAssigned.job.document_code}}</div>
                                        <div><strong>Is Signed: </strong>
                                            <span *ngIf="jobAssigned.job_acceptance_doc?.esignature && jobAssigned.job.cancellation == null">Yes</span>
                                            <span *ngIf="!jobAssigned.job_acceptance_doc?.esignature && jobAssigned.job.cancellation == null">No</span>
                                            <span *ngIf="jobAssigned.job.cancellation != null" class="red-text">Canceled</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr [ngClass]="{'odd-row': i % 2 !== 0}">    
                                    <td>
                                        <div><strong>Volume:</strong></div>
                                        <div>
                                            <strong *ngIf="jobAssigned.measure_unit == 'POUNDS'">Cf: </strong>
                                            <strong *ngIf="jobAssigned.measure_unit == 'CUBIC_FEET'">Lbs: </strong>
                                            {{jobAssigned.job.volume.lbs}}</div>
                                        <div>
                                            <strong *ngIf="jobAssigned.measure_unit == 'POUNDS'">Lbs: </strong>
                                            <strong *ngIf="jobAssigned.measure_unit == 'CUBIC_FEET'">Cf: </strong>
                                            {{jobAssigned.job.volume.cf}}</div>
                                        <div><strong>Miles: </strong>{{jobAssigned.job.volume.ml | number: '1.2-2'}}</div>
                                    </td>
                                    <td>
                                        <div><strong>Balance:</strong></div>
                                        <div><strong>Owed On Job:</strong> {{jobAssigned.owed_from_job | currency }}</div>
                                        <div><strong>Owed On Broker:</strong> {{jobAssigned.owed_to_broker | currency }}</div>
                                        <div><strong>Missing to Carrier Pay:</strong> {{(jobAssigned.owed_from_job - jobAssigned.owed_to_broker) | currency}}</div>
                                    </td>
                                    <td colspan="2">
                                        <div><strong>Status: <span class="break-space">{{jobAssigned.status | filterReplace}}</span></strong></div>
                                        <button *ngIf="jobAssigned.status == 'PENDING_SIGNATURE' && jobAssigned.job.cancellation == null" class="btn btn-secondary" type="button" (click)="openModal(jobAssigned.id, jobAssigned.special_conditions)">
                                            Sign
                                        </button>
                                        <button *ngIf="jobAssigned.status == 'WORKING_ON' && jobAssigned.job.cancellation == null" routerLink="/my-jobs/{{jobAssigned.job.id}}/view" class="btn btn-secondary" type="button">
                                            Detail
                                        </button>
                                        <button *ngIf="jobAssigned.job.cancellation != null" class="btn btn-danger" type="button">
                                            Canceled
                                        </button>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<!--  END CONTENT AREA  -->

<!-- Modal -->
<!-- <div class="modal fade" id="modalSignature" #modalSignDoc data-backdrop="static" data-keyboard="false" tabindex="-1" -->
<div class="modal fade" id="modalSignature" #modalSignDoc tabindex="-1" role="dialog" aria-labelledby="addListsModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Job Acceptance
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-6 job-acceptance-pdf" *ngIf="urlJobAcceptanceTrusted">
                        <iframe class="preview-job-acceptance" [src]="urlJobAcceptanceTrusted" type="application/pdf"
                            allowfullscreen>
                        </iframe>
                    </div>
                    <div class="row col-12 col-md-6 mpr-0">
                        <div class="col-xl-12 col-md-12 col-sm-12 col-12 mb-3 mpr-0 sign-job-acceptance">   
                            <button class="btn btn-secondary d-wb-none" (click)="downloadDocument()">
                                <i class="fa-solid fa-file-lines"></i>
                                View Document 
                            </button>
                            <div class="col-xl-12 col-md-12 col-sm-12 col-12 mb-3 title-sign">
                                <h4 class="m-0"><strong>Special Conditions</strong></h4>
                                <span class="special-conditions">{{ specialConditions}}</span>
                            </div>
                            <span>By signing below,</span>    
                            <span>you agree this job acceptance</span>           
                        </div>
                        <div class="col-xl-12 col-md-12 col-sm-12 col-12 mb-3 mpr-0">
                            <app-sign (onSignature)="onSignature($event)" (onTypingSignature)="onTypingSignature($event)"></app-sign>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="formStatus != 'FILLING_OUT'" class="btn" data-dismiss="modal"
                    (click)="closeModal()">
                    <i class="flaticon-cancel-12"></i>
                    Discard
                </button>
                <button [disabled]="formStatus != 'FILLING_OUT' || typingSignature" type="button" class="btn btn-warning"
                    (click)="saveFormData()">
                    <div *ngIf="formStatus == 'SENDING'"
                        class="spinner-border text-white mr-2 align-self-center loader-sm ">Loading...</div>
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
