<ul class="navbar-item topbar-navigation">
    <div class="topbar-nav header navbar" role="banner">
        <nav id="topbar">
            
            <ul class="navbar-nav theme-brand flex-row  text-center">
                <li class="nav-item pr-1">
                    <a href="index.html">
                        <img src="../../../assets/img/logotipo_moverxpro360.png" width="40px" class="navbar-logo" alt="logo">                        
                    </a>
                </li>
                <li class="nav-item d-flex align-items-center">
                    <img src="../../../assets/img/logotipo_text_moverxpro360.png" height="30px" class="navbar-logo" alt="logo">
                </li>
            </ul>

            <ul class="list-unstyled menu-categories" id="topAccordion">
                <!-- <li class="menu single-menu">
                    <a routerLink="/dashboard" routerLinkActive="active" data-toggle="collapse" aria-expanded="true" class=""
                        (click)="selectOptNav = 'dashboard'"
                        [ngClass]="{'menu-top-selected': selectOptNav == 'dashboard'}">
                        <div class="d-column align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                            <span>Dashboard</span>
                        </div>
                    </a>
                </li> -->
                <li class="menu single-menu">
                    <a routerLink="/load-board" routerLinkActive="active" data-toggle="collapse" aria-expanded="true" class=""
                        (click)="changeNav('Loadboard')"
                        [ngClass]="{'menu-top-selected': selectOptNav == 'Loadboard'}">
                        <div class="d-column align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                            <span>Load Board</span>
                        </div>
                    </a>
                </li>
                <li class="menu single-menu">
                    <a routerLink="/jobs-calendar" routerLinkActive="active" data-toggle="collapse" aria-expanded="true" class=""
                        (click)="changeNav('jobscalendar')"
                        [ngClass]="{'menu-top-selected': selectOptNav == 'jobscalendar'}">
                        <div class="d-column align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                            <span>Jobs Calendar</span>
                        </div>
                    </a>
                </li>        
                <li class="menu single-menu">
                    <a routerLink="/my-jobs" routerLinkActive="active" data-toggle="collapse" aria-expanded="true" class=""
                        (click)="changeNav('myjobs')"
                        [ngClass]="{'menu-top-selected': selectOptNav == 'myjobs'}">
                        <div class="d-column align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                            <span>My Jobs</span>
                        </div>
                    </a>
                </li>                
                <li class="menu single-menu">
                    <a routerLink="/broker-documents" routerLinkActive="active" data-toggle="collapse" aria-expanded="true" class=""
                        (click)="changeNav('brokerdocuments')"
                        [ngClass]="{'menu-top-selected': selectOptNav == 'brokerdocuments'}">
                        <div class="d-column align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                            <span>Broker Documents</span>
                        </div>
                    </a>
                </li>        
                <li class="menu single-menu">
                    <a routerLink="/balances" routerLinkActive="active" data-toggle="collapse" aria-expanded="true" class=""
                        (click)="changeNav('balances')"
                        [ngClass]="{'menu-top-selected': selectOptNav == 'balances'}">
                        <div class="d-column align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                            <span>Balances</span>
                        </div>
                    </a>
                </li>
            </ul>

        </nav>
    </div>
</ul>
         
<!-- <ul class="navbar-item topbar-navigation">
    <div class="topbar-nav header navbar" role="banner">
        <nav id="topbar"> -->

<!-- <div class="wrapper topbar-nav header navbar" role="banner" style="width: auto;">
    <nav class="tabs" id="topbar" style="border-radius: 50px;">
        <div class="selector"></div>

        <ul class="list-unstyled menu-categories pt-1" id="topAccordion">
           
                <a routerLink="/dashboard" data-toggle="collapse" aria-expanded="true" class="">          
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                    <span>Dashboard</span>                    
                </a>

                <a routerLink="/load-board" data-toggle="collapse" aria-expanded="true" class="">           
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                    <span>Load Board</span>
                </a>

                <a routerLink="/jobs-calendar" data-toggle="collapse" aria-expanded="true" class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                    <span>Jobs Calendar</span>        
                </a>
         
                <a routerLink="/my-jobs" data-toggle="collapse" aria-expanded="true" class="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
                    <span>My Jobs</span>
                </a>
         
                <a routerLink="/broker-documents" data-toggle="collapse" aria-expanded="true" class="">       
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                    <span>Broker Documents</span>
                </a>
       
                <a routerLink="/balances" data-toggle="collapse" aria-expanded="true" class="">                      
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                    <span>Balances</span>        
                </a>
   
        </ul>
    </nav>
</div> -->

