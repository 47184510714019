<div class="form-container outer wrapper">
    <div class="form-form">
        <div class="form-form-wrap">
            <div class="form-container">

                <div class="row logo-container">
                    <img alt="logo" src="../../../assets/img/logotipo_moverxpro360.png" height="80px"
                        class="navbar-logo pr-2" alt="logo">
                    <img alt="logo" src="../../../assets/img/logotipo_text_moverxpro360-w.png" height="40px"
                        class="navbar-logo mt-3" alt="logo">
                </div>

                <div class="form-content">

                    <h1>Sign In</h1>
                    <p>Log in to your carrier account to continue</p>

                    <form class="text-left" (ngSubmit)="login()">
                        <div class="form">

                            <div id="workspace-field" class="field-wrapper input" *ngIf="showWorkspace">
                                <label for="workspace">WORKSPACE</label>
                                <svg _ngcontent-kgf-c49="" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" class="feather feather-cpu">
                                    <rect _ngcontent-kgf-c49="" x="4" y="4" width="16" height="16" rx="2" ry="2"></rect>
                                    <rect _ngcontent-kgf-c49="" x="9" y="9" width="6" height="6"></rect>
                                    <line _ngcontent-kgf-c49="" x1="9" y1="1" x2="9" y2="4"></line>
                                    <line _ngcontent-kgf-c49="" x1="15" y1="1" x2="15" y2="4"></line>
                                    <line _ngcontent-kgf-c49="" x1="9" y1="20" x2="9" y2="23"></line>
                                    <line _ngcontent-kgf-c49="" x1="15" y1="20" x2="15" y2="23"></line>
                                    <line _ngcontent-kgf-c49="" x1="20" y1="9" x2="23" y2="9"></line>
                                    <line _ngcontent-kgf-c49="" x1="20" y1="14" x2="23" y2="14"></line>
                                    <line _ngcontent-kgf-c49="" x1="1" y1="9" x2="4" y2="9"></line>
                                    <line _ngcontent-kgf-c49="" x1="1" y1="14" x2="4" y2="14"></line>
                                </svg>
                                <input id="workspace" name="dot" type="text" [(ngModel)]="formData.workspace_id"
                                    class="form-control" placeholder="e.g 6046a8ece9fb7d521b57b321" autocomplete="off">
                            </div>

                            <div id="username-field" class="field-wrapper input">
                                <label for="username">DOT</label>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-key">
                                    <path
                                        d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4">
                                    </path>
                                </svg>
                                <input id="username" name="dot" type="text" [(ngModel)]="formData.username"
                                    class="form-control" placeholder="e.g 285023540" autocomplete="off">
                            </div>

                            <div id="password-field" class="field-wrapper input mb-4">
                                <div class="d-flex justify-content-between">
                                    <label for="password">PIN</label>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-lock">
                                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                </svg>
                                <input id="password" name="token" type="password" [(ngModel)]="formData.password"
                                    class="form-control" placeholder="PIN" autocomplete="off">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" id="toggle-password" class="feather feather-eye">
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                            </div>

                            <div class="d-sm-flex justify-content-between">
                                <div class="field-wrapper">
                                    <button type="submit" [disabled]="status != 'FILLING_OUT'"
                                        class="btn btn-warning btn-lg mb-3 mr-3">
                                        <div *ngIf="status == 'SENDING'"
                                            class="spinner-border text-white mr-2 align-self-center loader-sm ">
                                            Sending...</div>
                                        Log In
                                    </button>
                                </div>
                            </div>

                            <!-- <p class="signup-link">Not registered ? <a href="auth_register_boxed.html">Create an account</a></p> -->

                        </div>
                    </form>

                </div>
            </div>
        </div>

        <ul class="bg-bubbles p-0">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>

    </div>