import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  nameCarrier: string;

  constructor(
    public authService: AuthService,
    private router: Router,

  ) { 
      this.nameCarrier = '';
  }

  ngOnInit(): void {
      this.nameCarrier = this.authService.session.carrier.name;
  }

  
  async logout() {
    const idWorkspace = this.authService.session.workspace.id;
    const validateLogout = await this.authService.logout();
    localStorage.removeItem('customer_estimate');
    if (validateLogout) {
        this.router.navigateByUrl('/login?workspace=' + idWorkspace);
    } else {
        console.error('Error al cerrar la sesion');
    }
}



}
