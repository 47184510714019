import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BoardJobView } from '../entities/board-job-view';

@Injectable({
    providedIn: 'root'
})
export class BoardJobsService {

    constructor(private http: HttpClient) {

    }

    /**
     * permite obtener todos los registros
     */
    getAll(): Promise<Array<BoardJobView>> {
        return this.http.get<Array<BoardJobView>>(environment.api.carrier + '/board-jobs').toPromise()
    }

    /**
     * permite obtener todos los registros vip
     */
    getVip(idWorkspace): Promise<Array<BoardJobView>> {
        return this.http.get<Array<BoardJobView>>(environment.api.base + '/board-jobs/' + idWorkspace +'/vip').toPromise()
    }

    /**
     * permite obtener todos los registros vip
     */
     getVipByState(idWorkspace, state): Promise<Array<BoardJobView>> {
        const params = { state }    
        return this.http.get<Array<BoardJobView>>(environment.api.base + '/board-jobs-by-state/' + idWorkspace +'/vip' , { params }).toPromise()
    }

    /**
     * permite obtener todos los registros vip
     */
     getVipByZipcodeAndRAdius(idWorkspace, zipcode, radius): Promise<Array<BoardJobView>> {
        const params = { zipcode, radius }  
        return this.http.get<Array<BoardJobView>>(environment.api.base + '/board-jobs-by-radius/' + idWorkspace +'/vip' , { params }).toPromise()
    }

    /**
     * permite obtener todos los registros public
     */
    getPublic(idWorkspace): Promise<Array<BoardJobView>> {
        return this.http.get<Array<BoardJobView>>(environment.api.base + '/board-jobs/' + idWorkspace).toPromise()
    }

    /**
     * permite obtener todos los registros public por estado
     */
     getPublicByState(idWorkspace, state): Promise<Array<BoardJobView>> {
        const params = { state }        
        return this.http.get<Array<BoardJobView>>(environment.api.base + '/board-jobs-by-state/' + idWorkspace, { params } ).toPromise();
    }  
    
    /**
     * permite obtener todos los registros public por estado
     */
     forZipcodeAndRAdius(idWorkspace, zipcode, radius): Promise<Array<BoardJobView>> {
        const params = { zipcode, radius }        
        return this.http.get<Array<BoardJobView>>(environment.api.base + '/board-jobs-radius/' + idWorkspace, { params }).toPromise();
   }  
   
    /**
     * permite obtener todos los registros public
     */
    loadWorkspace(workspaceId ) {
        return this.http.get<any>(environment.api.base + '/board-jobs/workspace/' + workspaceId).toPromise()
    }

}
