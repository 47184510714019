import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ScheduleCalendar } from 'src/app/entities/schedule-calendar';
import { BoardJobsService } from 'src/app/services/board-jobs.service';
import { HelperService } from 'src/app/services/helper.service';
import { JobOutsourcingsService } from 'src/app/services/job-outsourcings.service';
declare var jQuery;
declare var swal;
declare var moment;
@Component({
    selector: 'app-job-calendar',
    templateUrl: './job-calendar.component.html',
    styleUrls: ['./job-calendar.component.scss']
})
export class JobCalendarComponent implements OnInit {
    @ViewChild('divCalendar') divCalendar: ElementRef;
    startDate: string;
    endDate: string;
    arrayCalendar: Array<ScheduleCalendar>;
    selectedDay: number;
    startDay: string;
    endDay: string;

    jobs: Array<any>;
    calendario: ScheduleCalendar;

    constructor(
        private jobOutsourcingsService: JobOutsourcingsService,
        private helperService: HelperService,
    ) {
        this.calendario = new ScheduleCalendar();

     }

    ngOnInit(): void {
        this.loadCalendar();
    }

    
    loadCalendar() {
     
        this.helperService.showLoadingMxpro360();
        this.jobOutsourcingsService
            .getAll()
            .then((response) => {
                console.log('response', response);
                this.jobs = response;
                this.initializeCalendar();
            })
            .catch((error) => {
                this.helperService.showMessageError(error, {});
                this.helperService.hideLoadingMxpro360();

            })
            .finally(() => {
            });
    }

    initializeCalendar() {
        this.arrayCalendar = [];

        // await this.filterConfirmed();
        jQuery(this.divCalendar.nativeElement).fullCalendar('removeEventSources');
                for (const cal of this.jobs) {
                    let distanceToZipCode = '';
                        this.calendario.id = cal.id + 'job';
                        let carrierSelect = '';
                        this.calendario.description = '<i class="fas fa-briefcase"></i> ' + cal.job.document_code + '<br> '
                            + '<i class="fas fa-arrow-circle-up"></i><a class="show-map cursor-pointer" pickup-state="' + cal.job.from.address.state + '" pickup-zip="' + cal.job.from.address.zip_code + '" delivery-state="' + cal.job.to.address.state + '" delivery-zip="' + cal.job.to.address.zip_code + '">' + cal.job.from.address.state + ', ' + cal.job.from.address.zip_code + '</a><br> '
                            + '<i class="fas fa-arrow-circle-down"></i><a class="show-map cursor-pointer" pickup-state="' + cal.job.from.address.state + '" pickup-zip="' + cal.job.from.address.zip_code + '" delivery-state="' + cal.job.to.address.state + '" delivery-zip="' + cal.job.to.address.zip_code + '">' + cal.job.to.address.state + ', ' + cal.job.to.address.zip_code + '</a><br> '
                            + '<i class="fab fa-buffer"></i> ' + cal.job.volume.cf + ' cf ' + ' <i class="fas fa-location-arrow"></i> ' + parseInt(cal.job.volume.ml) + ' miles ' + ' <br> '
                            + distanceToZipCode
                            + carrierSelect;

                        this.calendario.title = cal.job.document_code;

                            this.calendario.start = moment(cal.job.from.range_start).format(moment.HTML5_FMT.DATE);
                            this.calendario.end = moment(cal.job.from.range_end).format(moment.HTML5_FMT.DATE);
                      


                        if(cal.status == 'WORKING_ON'){
                            this.calendario.className = 'calendar-color-green';
                        } else {
                            this.calendario.className = 'calendar-color-red';
                        }
                     
                        this.arrayCalendar.push(this.calendario);
                        this.calendario = new ScheduleCalendar();

                
              
            
        }
       
        jQuery(this.divCalendar.nativeElement).fullCalendar('addEventSource', this.arrayCalendar);
        this.helperService.hideLoadingMxpro360();
    }

    
    ngAfterViewInit(): void {

        const that = this;
        // setTimeout(() => {
        //     jQuery('.fc-next-button, .fc-prev-button, .fc-today-button').click(() => {
        //         jQuery(this.divCalendar.nativeElement).fullCalendar('removeEvents');
        //         that.startDate = '' + moment(jQuery(this.divCalendar.nativeElement)
        //             .fullCalendar('getView').start._d, moment.HTML5_FMT.DATE).unix() * 1000;
        //         that.endDate = '' + moment(jQuery(this.divCalendar.nativeElement)
        //             .fullCalendar('getView').end._d, moment.HTML5_FMT.DATE).unix() * 1000;

        //         // that.loadCalendar();
        //     });
        // }, 2000);
        // this.loadCalendar();

        jQuery(this.divCalendar.nativeElement).fullCalendar({
            header: {
                left: '',
                rigth: 'prev,next,today',
                center: 'title',
            },
            eventRender: (eventObj, $el) => {
                $el.find('span.fc-title').html(eventObj.description);
                $el.find('span.fc-time').remove();
            },
            events: this.arrayCalendar,
            editable: false,
            eventLimit: false,
            selectable: true,
            eventClick: (event) => {
                swal({
                    html: event.description
                });
                if (event.url) {
                    window.open(event.url, '_blank');
                    return false;
                }
            },
            eventMouseover: (event, jsEvent, view) => {

                jQuery(this).attr('id', event.id);

                jQuery('#' + event.id).popover({
                    template: '<div class="popover popover-primary" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
                    title: event.title,
                    content: event.description,
                    placement: 'top',
                });

                jQuery('#' + event.id).popover('show');
            },
            eventMouseout: (event, jsEvent, view) => {

                jQuery('#' + event.id).popover('hide');
            },
            dayClick: (date, allDay, jsEvent, view) => {
                this.selectedDay = moment(date._d, moment.HTML5_FMT.DATE).add(1, 'd').second(0).minute(0).hour(0).unix() * 1000;
                that.startDay = '' + moment(date._d, moment.HTML5_FMT.DATE).add(1, 'd').second(0).minute(0).hour(0).unix() * 1000;
                that.endDay = '' + moment(date._d, moment.HTML5_FMT.DATE).add(1, 'd').second(59).minute(59).hour(23).unix() * 1000;

                // that.loadDataDay();
            }
        });

        // this.load();
    }

}
