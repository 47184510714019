<div class="align-self-center">
    <a class="logo-moverxpro360" href="/">
        <img alt="logo" src="../../../assets/img/logotipo_moverxpro360.png" width="70px" class="navbar-logo pr-2"
            alt="logo">
        <img alt="logo" src="../../../assets/img/logotipo_text_moverxpro360-w.png" width="110px" class="navbar-logo text"
            alt="logo">
    </a>
</div>

<div class="layout-px-spacing">

    <div id="headerWrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-12 text-center pt-5">
                    <h2 class="main-heading">Sign Rate Sheet</h2>
                </div>
            </div>
        </div>
    </div>

    <!-- ngClass "rate-shieet-signed" cuando el rate shieet este firmado -->
    <div class="sign-rate-shieet-acceptance-container mt-3" >

        <div class="row rate-shieet-signed-container">
            <div class="col-md-12 col-lg-7 estimate-box" *ngIf="urlRateShieetAcceptanceTrusted">
                <iframe class="rate-shieet-acceptance-pdf" style="width: 100%; height: 100%;" [src]="urlRateShieetAcceptanceTrusted" type="application/pdf"
                    allowfullscreen>
                </iframe>
            </div>
            <div class="row col-md-12 col-lg-5 sign-box pl-4">
                <div class="col-xl-12 col-md-12 col-sm-12 col-12 mb-3 title-sign">
                    <h4 class="m-0"><strong>Special Conditions</strong></h4>
                    <span class="special-conditions">{{ jobOutsourcing.special_conditions}}</span>
                </div>
                <div class="col-xl-12 col-md-12 col-sm-12 col-12 mb-3 title-sign">
                    <h4 class="m-0"><strong>Signature</strong></h4>
                    <span>Signing this document you agree this rate sheet</span>
                </div>
                <div class="row container-sign" >
                    <app-sign (onSignature)="onSignature($event)"  (onTypingSignature)="onTypingSignature($event)"  style="width:100%"></app-sign>
                </div>

                <div class="col-12 text-right sign-action-button py-3 mb-3">
                    <button class="btn" data-dismiss="modal" (click)="closeModal()">
                        Discard
                    </button>
                    <button (click)="saveSignature()" type="button" class="btn btn-warning" [disabled]="typingSignature">
                        Save
                    </button>
                </div>
                
            </div>

            


        </div>

        
    </div>

</div>

<div class="footer-wrapper">
    <div class="footer-section f-section-1">
        <p class="">Copyright © 2021 All rights reserved by Uxomnitech</p>
    </div>
    <div class="footer-section f-section-2">
        <p class="">Coded with <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-heart">
                <path
                    d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z">
                </path>
            </svg></p>
    </div>
</div>