<div class="align-self-center">
    <a class="logo-moverxpro360" href="/">
        <img alt="logo" src="../../../assets/img/logotipo_moverxpro360.png" width="70px" class="navbar-logo pr-2"
            alt="logo">
        <img alt="logo" src="../../../assets/img/logotipo_text_moverxpro360-w.png" width="110px" class="navbar-logo text"
            alt="logo">
    </a>
</div>

<div class="layout-px-spacing">

    <div id="headerWrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-12 text-center pt-5">
                    <h2 class="main-heading">
                    <span [ngClass]="{'title-job-signed': isSignatureJobAcceptance}">Sign</span> Job Acceptance</h2>
                </div>
            </div>
        </div>
    </div>

    <div class="row" [ngClass]="{'opts-job-signed': isSignatureJobAcceptance}">
        <div class="col-md-12 col-sm-12 col-12 text-center pt-5">
            <div class="col-12 btn-view-jaf">
                <button class="btn btn-secondary" (click)="viewJAF(urlPdfName)">
                    View JAF
                </button>
            </div>    
        </div>
    </div>
    <!-- ngClass "job-signed" cuando el job acceptance este firmado -->
    <div class="sign-job-acceptance-container" [ngClass]="{'job-signed': isSignatureJobAcceptance}">

        <div class="row job-signed-container">
            <div class="col-md-12 col-lg-7 estimate-box container-image-credit-card" *ngIf="urlJobAcceptanceTrusted">
                <iframe class="job-acceptance-pdf" style="width: 100%; height: 1000px;" [src]="urlJobAcceptanceTrusted" type="application/pdf"
                    allowfullscreen>
                </iframe>
            </div>
            <div class="row col-md-12 col-lg-5 sign-box pl-4" style="height: 600px;">
                
                <div class="col-xl-12 col-md-12 col-sm-12 col-12 mb-3 title-sign">                    
                    <h4 class="m-0"><strong>Special Conditions</strong></h4>
                    <span class="special-conditions">{{ jobOutsourcing.special_conditions}}</span>
                </div>
                <div class="col-xl-12 col-md-12 col-sm-12 col-12 mb-3 title-sign">                    
                    <h4 class="m-0"><strong>Signature</strong></h4>
                    <span>By signing this document you agree to the Job Acceptance Form terms and special conditions!</span>
                </div>
                <div class="row container-sign" >
                    <app-sign (onSignature)="onSignature($event)"  (onTypingSignature)="onTypingSignature($event)"  style="width:100%"></app-sign>
                </div> 
        
                <div class="col-sm-12 col-md-12 col-lg-12 text-right sign-action-button py-3 mb-3">
                    <button class="btn" data-dismiss="modal" (click)="closeModal()">
                        Discard
                    </button>
                    <button (click)="saveSignature()" type="button" class="btn btn-warning" [disabled]="typingSignature">
                        Save
                    </button>
                </div>                
            </div>

            <div class="row col-md-12 col-lg-5 btn-job-signed pl-4">
                <div class="box-btn-job-signed">
                    <h4>
                        Job Acceptance
                    </h4>
                    <button class="btn btn-success btn-view-jaf-opts" (click)="changePdf(urlPdf['urlJobAcceptance'])">
                        <i class="fa-solid fa-eye"></i>
                        <span>View JAF</span> 
                    </button>
                    <button class="btn btn-secondary mt-2" (click)="downloadDocument(urlPdf['urlJobAcceptance'])">
                        <i class="fa-solid fa-file-export"></i>
                        <span>Download JAF</span>
                    </button>

                    <h4 class="mt-5">
                        Moving Estimate
                    </h4>
                    <button class="btn btn-success btn-view-estimate-opts mt-2" (click)="changePdf(urlPdf['urlEstimate'])">
                        <i class="fa-solid fa-eye"></i>
                        <span>View Estimate</span>
                    </button>
                    <button class="btn btn-secondary mt-2" (click)="downloadDocument(urlPdf['urlEstimate'])">
                        <i class="fa-solid fa-file-export"></i>
                        <span>Download Estimate</span>
                    </button>
                </div>
                <div class="box-btn-job-signed">
                    <span>Conditions: {{jobOutsourcing.special_conditions}}</span> 
                </div>
            </div>

        </div>
    </div>
</div>

<div class="footer-wrapper">
    <div class="footer-section f-section-1">
        <p class="">Copyright © 2021 All rights reserved by Uxomnitech</p>
    </div>
    <div class="footer-section f-section-2">
        <p class="">Coded with <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-heart">
                <path
                    d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z">
                </path>
            </svg></p>
    </div>
</div>