export class DateText {
    id: string;
    year: string;
    month: string;
    day: string;

    constructor(){
        this.id = null;
        this.year = null;
        this.month = null;
        this.day = null;
    }
}
