export class PublicVolume {

    libs: number;
    cf: number;
    miles: number;
    cubic_feets_selled: number

    constructor(){
        this.libs = 0;
        this.cf = 0;
        this.miles = 0;
        this.cubic_feets_selled = 0;
    }
}
