export class JobStatus {
    public id: number;
    public general: 'BROKER_CANCELED' | 'CARRIER_CANCELED' | 'WORK_CARRIED_OUT' | 'CUSTOMER_CANCELED' | 'CARRIER_PAPERWORK_ON' | 'WORKING_ON';
    public operation: 'NEW' | 'DELIVERED_BOXES' | 'PACKED_MOVE' | 'PICKED_UP' | 'IN_TRANSIT' | 'IN_STORAGE' | 'DELIVERED';
    public picked_docs: boolean;
    public delivered_docs: boolean;

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.general = 'WORKING_ON';
        this.operation = 'NEW';
        this.picked_docs = false;
        this.delivered_docs = false;
    }
}
