<!-- CONTENT BREADCRUMB -->
<div class="breadcrumb-four">
    <ul class="breadcrumb">
        <li><a routerLink="/dashboard"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg></a></li>
        <li><a routerLink="/balances"><span>Balances</span></a></li>
        <li class="active"><a href=""><span>Zelle</span></a></li>
    </ul>
</div>
<!-- CONTENT BREADCRUMB -->

<div id="content" class="main-content d-flex justify-content-center padd-t40">
    <div class="container justify-content-center">
        <div class="container">
            <div class="row layout-top-spacing marg-t0" id="cancel-row">                                       
                
                <div class="row col-lg-12 layout-spacing marg-0">

                    <div class="col-12 col-md-1">
                    </div>

                    <div class="col-12 col-md-5 text-right">
                        <h3><strong>Payment via Zelle</strong></h3>  

                        <div class="font-16 text-justify">
                            Make the transfer to the following Zelle account: billing@newcitymovingandstorage.com Attach proof-of-payment (screenshot), and submit it in the field provided below. Then, notify your agent that the payment has been made.
                        </div>

                        <div class="col-12 pr-0 mt-3 padd-l0">
                            <div class="infobox-3 infobox-amount mt-0">
                                <div class="info-icon">
                                    <i class="fas fa-dollar-sign"></i>
                                </div>
                                <h5 class="info-heading">Payment amount</h5>
                                <div class="amount">$15280.00</div>
                            </div>
                        </div>                                                                           

                    </div>

                    <div class="col-12 col-md-1">
                    </div>

                    <div class="col-12 col-sm-5 padd-t5">                  
                        <div class=" widget widget-content widget-content-area b-radius-10">  

                            <div class="text-center">
                                <img src="assets/img/upload-file.png" alt="" srcset="">
                            </div>
               
                            <div class="text-center mt-4">
                                <button class="btn btn-success width-200" (click)="uploadFiles()">Upload</button>
                            </div>

                        </div>                    
                    </div> 

                </div>                        

            </div>
        </div>
    </div>
</div>








