import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Balance } from '../entities/balance';
import { BalanceView } from '../entities/balance-view';
import { PaymentMethodView } from '../entities/payment-method-view';

@Injectable({
  providedIn: 'root'
})
export class BalancesService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Metodo que obtiene todos los balances en la web
   */
  getAll() {
    return this.http.get<any>(environment.api.carrier + '/balances').toPromise();
  }

  /**
   * Metodo que obtiene los balances por id de jobs
   *
   * @param id Id del job
   */
  getById(id) {
    return this.http.get<BalanceView>(environment.api.carrier + '/balances/' + id).toPromise();
  }

  /**
  * Metodo que obtiene los balances por id de jobs
  *
  * @param id Id del job
  */
  getPendingBalanceById(id) {
    return this.http.get<BalanceView>(environment.api.carrier + '/balances/' + id + '/pending').toPromise();
  }

  /**
   * Metodo que obtiene los metodos de pago poe balance
   *
   * @param id Id del job
   */
  getPaymentMethods(id) {
    return this.http.get<PaymentMethodView>(environment.api.carrier + '/payments-methods-by-balance/' + id).toPromise();
  }

}
