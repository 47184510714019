import { Carrier } from "./carrier";
import { Workspace } from "./workspace";

export class Session {

    token: string;
    workspace: Workspace;
    carrier: Carrier;
    
    constructor() {
        this.token = null;
        this.workspace = null;
        this.carrier = null;
    }

}
