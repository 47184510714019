<div id="content" class="main-content">

    <div class="layout-px-spacing-head pad-0">
        <div class="row pad-t50">
            <div class="col-3 form-group">
                <br>
                <form>
                    <div class="form-row align-items-center">
                        <div class="col-sm-10 my-1">
                            <select name="state" name="state" class="form-control" [(ngModel)]="state">
                                <option value="">Select a state</option>
                                <option *ngFor="let stateObject of states.states" [value]="stateObject.value">
                                    {{stateObject.label}}</option>
                            </select>
                        </div>
                        <div class="col-sm-2 my-1">
                            <button class="btn btn-xpro-search" (click)="forState()"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-search">
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                </svg>
                            </button>
                        </div>
                    </div>
                </form>
                <form>
                    <div class="form-row align-items-center">
                        <div class="col-sm-5 my-1">
                            <input type="text" class="form-control" [(ngModel)]="zipcode" name="zipcode"
                                placeholder="Zip Code">
                        </div>
                        <div class="col-sm-5 my-1">
                            <input type="number" class="form-control" min="0" [(ngModel)]="radius" name="radius"
                                placeholder="Radius">
                        </div>
                        <div class="col-auto col-sm-2 my-1">
                            <button class="btn btn-xpro-search" type="submit" (click)="forZipcodeAndRAdius()"><svg
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-search">
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                </svg>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-lg-5 ">
                <div class="text-center section-title">
                    <h1 class=" text-uppercase text-title">
                        {{workspace.name}}
                    </h1>
                    <h2 class=" text-uppercase text-title">
                        VIP LOAD BOARD
                    </h2>
                </div>
            </div>
            <div class="col-4 form-group">
                <br>
                <br>
                <ng-container *ngFor="let contact of dispatchContacts">

                    <div class="card">
                        <div class="card-body">
                            <div class="row  info-dispatch text-left font14">
                                <div class="col-md-4 col-sm-12 call-dispatch">
                                    <i class="" data-fa-i2svg="">
                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor"
                                            stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"
                                            class="css-i6dzq1">
                                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                            <circle cx="12" cy="7" r="4"></circle>
                                        </svg>
                                    </i>
                                    <span class="email-dispatch padd-l10">
                                        <a href="tel:{{contact.phone}}">Name:</a>
                                    </span>
                                </div>
                                <div class="col-md-8 col-sm-12 phone">
                                    <span><a href="tel:{{contact.phone}}">{{contact.name}}</a>
                                    </span>
                                </div>
                            </div>
                            <div class="row  info-dispatch text-left font14">
                                <div class="col-md-4 col-sm-12 call-dispatch">
                                    <i class="" data-fa-i2svg="">
                                        <svg width="14" class="svg-inline--fa fa-phone-alt fa-w-16" aria-hidden="true"
                                            focusable="false" data-prefix="fas" data-icon="phone-alt" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z">
                                            </path>
                                        </svg>
                                    </i>
                                    <span class="email-dispatch padd-l10">
                                        <a href="tel:{{contact.phone}}">Call:</a>
                                    </span>
                                </div>
                                <div class="col-md-8 col-sm-12 phone">
                                    <span><a href="tel:{{contact.phone}}">{{contact.phone}}</a>
                                    </span>
                                </div>
                            </div>
                            <div class="row info-dispatch text-left font14 marg-t12">
                                <div class="col-md-4 col-sm-12 call-dispatch">
                                    <i class="" data-fa-i2svg="">
                                        <svg width="14" class="svg-inline--fa fa-envelope fa-w-16" aria-hidden="true"
                                            focusable="false" data-prefix="fas" data-icon="envelope" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor"
                                                d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                                            </path>
                                        </svg>
                                    </i>
                                    <span class="email-dispatch padd-l10">
                                        <a href="mailto:{{contact.email}}">Email: </a>
                                    </span>
                                </div>
                                <div class="col-md-8 col-sm-12 phone">
                                    <span class="email">
                                        <a href="mailto:{{contact.email}}"> {{contact.email}} </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>


            </div>
        </div>
        <div class="row" id="cancel-row">

            <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing header-table">
                <div class="widget-content widget-content-area widget-content-head br-6 py-2">
                    <table id="invoice-list" class="table table-hover m-0" style="width:100%">
                        <thead>
                            <tr>                                
                                <th>Job</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Volume</th>
                                <th>Special Conditions</th>
                                <th>Offer Value</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>            
        
            <div id="resize"></div>

            <div class="col-xl-12 col-lg-12 col-sm-12 layout-top-spacing layout-spacing body-table">
                <div class="widget-content widget-content-area br-6">
                    <table id="invoice-list" class="table table-hover" style="width:100%">                        
                        <tbody>
                           
                            <tr *ngFor="let boardJob of boardJobs" [ngClass]="{'vip-job': boardJob.vip }" >
                                <td class="tdHeader headerCollapse">
                                    <button class="btn-arrow rotate">
                                        <i class="fas fa-chevron-down"></i>
                                    </button>                                    
                                    <div>
                                        <i class="fas fa-crown icon-vip-job"></i> 
                                        <span class="key">Job: </span>{{boardJob.job.document_code}}
                                        <span class="badge badge-success ml-3">{{boardJob.offer | currency}}</span>
                                    </div>
                                </td>                                
                                <td class="rowCollapse" data-label="Customer">
                                    <div class="arrow-down"></div>                 
                                    <i class="fas fa-crown web bs-tooltip" title="VIP Job"></i>                                                                                         
                                    <div><span class="key">Job: </span>{{boardJob.job.document_code}}</div>
                                    <div><span class="key">Service: </span>{{boardJob.job.service}}</div>
                                </td>
                                <td class="rowCollapse" data-label="From">                                    
                                    <div class="text-right">{{boardJob.job.from.address.state}} {{boardJob.job.from.address.zip_code}}</div>
                                    <div class="content-between">
                                        <span class="inv-date-start mr-2 success" title="Range Start">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                        </span>
                                        {{boardJob.job.from.range_start | date }}
                                    </div>
                                    <div class="content-between">
                                        <span class="inv-date-end mr-2 danger" title="Range End">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                        </span>
                                        {{boardJob.job.from.range_end | date }}
                                    </div>
                                </td>
                                <td class="rowCollapse" data-label="To">
                                    <div class="text-right">{{boardJob.job.to.address.state}} {{boardJob.job.to.address.zip_code}}</div>
                                    <div class="content-between">
                                        <span class="inv-date-start mr-2 success" title="Range Start">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                        </span>
                                        {{boardJob.job.to.range_start | date }}
                                    </div>
                                    <div class="content-between">
                                        <span class="inv-date-end mr-2 danger" title="Range End">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                        </span>
                                        {{boardJob.job.to.range_end | date }}
                                    </div>
                                </td> 
                                <td class="rowCollapse" data-label="Volume">
                                    <div><span class="key">lb:</span> {{boardJob.job.volume.lbs}} </div>
                                    <div><span class="key">cf:</span> {{boardJob.job.volume.cf}} </div>
                                    <div><span class="key">miles:</span> {{boardJob.job.volume.ml.toFixed(2)}} </div>
                                </td>                                
                                <td class="t-center rowCollapse special-conditions-container" data-label="Special Conditions">                                    

                                    <span *ngIf="boardJob.special_conditions == null" class="badge badge-danger">None</span>

                                    <div *ngIf="boardJob.special_conditions != null" class="special-conditions text-justify">
                                        <br class="break" >
                                        {{boardJob.special_conditions}}
                                    </div>

                                </td>
                                <td class="rowCollapse t-align" data-label="Offer Value">
                                    <span class="inv-amount"> {{boardJob.offer | currency}} </span>
                                </td>
                                <td class="rowCollapse t-align" data-label="Action">
                                    
                                    <button data-toggle="modal" data-target="#modalApplyJob" class="btn btn-success" >
                                        Apply
                                    </button>
                                </td>                                
                            </tr>           
                            
                        </tbody>
                    </table>                    

                </div>
            </div>

        </div>
    </div>

</div>
<!--  END CONTENT AREA  -->



<!-- Modal -->
<div class="modal fade" id="modalApplyJob" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered  modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    Apply to Job
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div  class="col-md-12 text-center marg-b30">
                        <i  class="icon-dispatch" data-fa-i2svg="">
                            <svg width="60"  class="svg-inline--fa fa-headset fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="headset" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z"></path></svg>
                        </i>
                        <h5  class="title-dispatch" id="">
                            <a  href="tel:7865181777">Dispatch</a>
                        </h5>
                    </div>
                </div>

                <ng-container *ngFor="let contact of dispatchContacts">

                    <div  class="row  info-dispatch text-left font14">
                        <div  class="col-md-4 col-sm-12 call-dispatch">
                            <i  class="" data-fa-i2svg="">
                                <svg width="14"  class="svg-inline--fa fa-phone-alt fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
                            </i>
                            <span  class="email-dispatch padd-l10">
                                <a  href="tel:{{contact.phone}}">Name Dispath:</a>
                            </span>
                        </div>
                        <div  class="col-md-8 col-sm-12 phone">
                            <span ><a  href="tel:{{contact.phone}}"> {{contact.name}} </a>
                            </span>
                        </div>
                    </div>                    

                    <div  class="row  info-dispatch text-left font14">
                        <div  class="col-md-4 col-sm-12 call-dispatch">
                            <i  class="" data-fa-i2svg="">
                                <svg width="14"  class="svg-inline--fa fa-phone-alt fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path></svg>
                            </i>
                            <span  class="email-dispatch padd-l10">
                                <a  href="tel:{{contact.phone}}">Call Dispath:</a>
                            </span>
                        </div>
                        <div  class="col-md-8 col-sm-12 phone">
                            <span ><a  href="tel:{{contact.phone}}">{{contact.phone}}</a>
                            </span>
                        </div>
                    </div>

                    <div  class="row info-dispatch text-left font14 marg-t12">
                        <div  class="col-md-4 col-sm-12 call-dispatch">
                            <i  class="" data-fa-i2svg="">
                                <svg width="14"  class="svg-inline--fa fa-envelope fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                            </i>
                            <span  class="email-dispatch padd-l10">
                                <a  href="mailto:{{contact.email}}">Email Dispath: </a>
                            </span>
                        </div>
                        <div  class="col-md-8 col-sm-12 phone">
                            <span  class="email">
                                <a  href="mailto:{{contact.email}}"> {{contact.email}} </a>
                            </span>
                        </div>
                    </div>

                    <br>
                </ng-container>

                
            </div>
            <div class="modal-footer">
                <button class="btn" data-dismiss="modal"><i class="flaticon-cancel-12"></i> OK</button>
                <!--
                    <button (click)="getIt()" type="button" class="btn btn-warning">Get in</button>
                -->
            </div>
        </div>
    </div>
</div>



