import { ActivatedRoute } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ConstantsMessages } from 'src/app/constants-messages';
import { DomSanitizer } from '@angular/platform-browser';
import { ElectronicSignature } from 'src/app/entities/electronic-signature';
import { environment } from 'src/environments/environment';
import { HelperService } from 'src/app/services/helper.service';
import { JobOutsourcing } from 'src/app/entities/job-outsourcing';
import { JobOutsourcingsService } from 'src/app/services/job-outsourcings.service';

declare const jQuery;
declare const swal;

@Component({
  selector: 'app-rate-shieet',
  templateUrl: './rate-shieet.component.html',
  styleUrls: ['./rate-shieet.component.scss']
})
export class RateShieetComponent implements OnInit {
  // creamos un objeto para en el establecer la informacion del archivo que contiene la firma
  signature: File;

  // cargamos el elemento que trabaja la modal
  @ViewChild('modalSignDoc') modalFormElem: ElementRef;

  public isSignatureRateShieet: boolean;
  public jobOutsourcing: JobOutsourcing;
  public jobOutsourcingId: string;
  public typingSignature: boolean;
  public urlRateShieetAcceptanceTrusted;
  public workspaceId: string;

  constructor(
    private currentRoute: ActivatedRoute,
    private helperService: HelperService,
    private jobOutsourcingsService: JobOutsourcingsService,
    private sanitizer: DomSanitizer
  ) {
    this.isSignatureRateShieet = false;
    this.jobOutsourcingId = null;
    this.jobOutsourcing = new JobOutsourcing();
    this.signature = null;
    this.typingSignature = false;
    this.workspaceId = null;
  }

  ngOnInit(): void {
    this.currentRoute.params.subscribe(params => {
      if (typeof params.workspace_id !== 'undefined' && typeof params.outsourcing_id !== 'undefined') {
        this.workspaceId = params.workspace_id
        this.jobOutsourcingId = params.outsourcing_id;
        this.loadRateShieet(params.workspace_id, params.outsourcing_id);
        this.loadJobOutsourcing(params.workspace_id, params.outsourcing_id);
      }
    });
  }

  public loadRateShieet(idWorkspace, idJobOutsourcing): void {
    this.helperService.showLoadingMxpro360();
    this.jobOutsourcingsService
      .getPublicRateShieet(idWorkspace, idJobOutsourcing)
      .then((response) => {
        
        this.isSignatureRateShieet = response['is_signature'];
        console.log('this.isSignatureRateShieet ==>', this.isSignatureRateShieet);
        let urlRateShieetUntrusted = `${environment.api.base}/tmp/${response['filename']}`;
        if (this.isSignatureRateShieet) {
          urlRateShieetUntrusted = `${environment.az_api.cdn}/${this.workspaceId}/documents/${response['filename']}`;
        }
        console.log('urlRateShieetUntrusted', urlRateShieetUntrusted);
        
        this.urlRateShieetAcceptanceTrusted = this.sanitizer.bypassSecurityTrustResourceUrl(urlRateShieetUntrusted);
        console.log('this.urlRateShieetAcceptanceTrusted =>', this.urlRateShieetAcceptanceTrusted);
        
      })
      .catch((error) => {
        this.helperService.showMessageError(error, { idWorkspace: idWorkspace, idJobOutsourcing: idJobOutsourcing });
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  private loadJobOutsourcing(idWorkspace, idJobOutsourcing): void {
    this.helperService.showLoadingMxpro360();
    this.jobOutsourcingsService
      .getJobOutsourcing(idWorkspace, idJobOutsourcing)
      .then((response) => {
        this.jobOutsourcing = response;
      })
      .catch((error) => {
        this.helperService.showMessageError(error, {idWorkspace: idWorkspace, idJobOutsourcing: idJobOutsourcing});
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  /**
   * Permite escuchar la firma
   */
  onSignature(signature: File) {
    this.signature = signature;
  }

  /**
   * Permite cerrar la modal
   */
  closeModal(): void {
    // nuleamos el formulario para poder ahorrar memoria
    this.signature = null;
  }

  saveSignature(): void {
    // revisamos si no esta se habia enviando previamente 
    if (!this.jobOutsourcingId) {
      return;
    }

    this.helperService.showLoadingMxpro360();

    const formData = new FormData();
    formData.append('files[]', this.signature);
    jQuery.ajax({
      url: environment.api.base + '/files',
      type: 'post',
      dataType: 'json',
      data: formData,
      cache: false,
      contentType: false,
      processData: false
    }).done((response) => {
      const electronicSignature = new ElectronicSignature();
      electronicSignature.esignature_file.display_name = response.files[0];
      electronicSignature.esignature_file.name = response.files[0];
      electronicSignature.esignature_file.mime_type = this.helperService.getMimeType(response.files[0]);
      electronicSignature.internet_browser = this.helperService.detectBrowser();
      // enviamos la firma y e indicamos a que job se asignara
      this.helperService.showLoadingMxpro360();
      this.jobOutsourcingsService
        .savePublicRateShieet(this.workspaceId, this.jobOutsourcingId, electronicSignature)
        .then((filename) => {
          //const urlRateShieetUntrusted = environment.api.base + '/tmp/' + filename;
          const urlRateShieetUntrusted = `${environment.az_api.cdn}/${this.workspaceId}/documents/${filename}`;
          this.urlRateShieetAcceptanceTrusted = this.sanitizer.bypassSecurityTrustResourceUrl(urlRateShieetUntrusted);

          this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
          this.loadRateShieet(this.workspaceId, this.jobOutsourcingId);
        })
        .catch((error) => {
          // verificamos que se este recibiendo un error del servidor
          if ('message' in error) {
            // avisamos lo que esta pasando
            swal({
              title: 'Ops',
              text: error.message,
              type: 'error',
            });
          }
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        })
    });
  }

  /**
   * Permite escuchar la firma
   */
  onTypingSignature(typing: boolean) {
    this.typingSignature = typing;
  }

}
