<!--  BEGIN NAVBAR  -->
 <app-header class="header-container"></app-header>
<!--  END NAVBAR  -->

<!--  BEGIN MAIN CONTAINER  -->
<div class="main-container" id="container">

    <div class="overlay"></div>
    <div class="search-overlay"></div>

    <!--  BEGIN TOPBAR  -->
    <app-topbar-nav class="header navbar navbar-expand-sm d-flex justify-content-center marg-t105"></app-topbar-nav>
    <!--  END TOPBAR  -->
    
    <!--  BEGIN CONTENT AREA  -->
    <div id="content" class="main-content">

        <router-outlet></router-outlet>
            
        <app-footer class="footer-wrapper" ></app-footer>
        
    </div>
    <!--  END CONTENT AREA  -->

</div>
<!-- END MAIN CONTAINER -->
