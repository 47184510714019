<!-- CONTENT BREADCRUMB -->
    <div class="breadcrumb-four">
        <ul class="breadcrumb">
            <li class="active"><a routerLink="/dashboard"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg></a></li>
        </ul>
    </div>
<!-- CONTENT BREADCRUMB -->

            
<!-- CONTENT AREA -->
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12 layout-top-spacing layout-spacing">
            <div class="widget widget-content-area br-4">
                <div class="widget-one">

                    <h6>Blank Page - Kick Start you new project with ease!</h6>

                    <p class="mb-0 mt-3">With CORK starter kit, you can begin your work without any hassle. The starter page is highly optimized which gives you freedom to start with minimal code and add only the desired components and plugins required for your project.</p>

                </div>
            </div>
        </div>
    </div>
<!-- CONTENT AREA -->