import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileUploadModalComponent } from '../modals/file-upload-modal/file-upload-modal.component';

declare var jQuery;

@Injectable({
    providedIn: 'root'
})
export class UploadFilesService {

    // creamos un referenciador para la modal
    private modalComponentRef: ComponentRef<FileUploadModalComponent>;

    /**
    * variable a tratar las suscripciones con la modales para cuando se cierre,
    * se crea esta variable ya que cuando se cierra la modal, ya no va a existir mas,
    * por lo que se debe desuscribir (asi permite liberar recursos)
    */
    private onCloseSuscribe: Subscription;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        private appRef: ApplicationRef,
    ) {
        this.onCloseSuscribe = null;
    }

    /**
     * Permite abrir la modal, 
     * @returns 
     */
    openModal(): Promise<Array<string>> {
        return new Promise((resolve, reject) => {

            // le damos vida en "angular" al componente recibido
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FileUploadModalComponent);
            this.modalComponentRef = componentFactory.create(this.injector);

            // indicamos que anexe el view
            this.appRef.attachView(this.modalComponentRef.hostView);

            // agregamos la vista al documento
            const domElem = (this.modalComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
            document.body.appendChild(domElem);

            // creamos un archivo
            this.onCloseSuscribe = this.modalComponentRef.instance.onClose
                .subscribe((files: Array<string>) => {
                    resolve(files);
                    this.onCloseSuscribe.unsubscribe();
                    this.destroyModal();
                });

            this.modalComponentRef.instance.onReady
                .subscribe(() => {
                    // abrimos la modal
                    this.modalComponentRef.instance.open();
                });
        });
    }

    /**
     * permite destruir la modal
     */
    private destroyModal() {
        this.appRef.detachView(this.modalComponentRef.hostView);
        this.modalComponentRef.destroy();
    }

    /**
     * permite subir archivos al servidor, deu
     * 
     * @param files 
     */
    send(files: File[]) {

        // creamos objeto formulario
        const formData = new FormData();

        // iteramos los archivos
        for (const file of files) {

            // agregamos los archivos al objeto formulario
            formData.append('files[]', file);
        }

        // enviamos al servidor y envolvemos en una promesa
        return new Promise((resolve, reject) => {
            jQuery.ajax({
                url: environment.api.base + '/files',
                type: 'post',
                data: formData,
                contentType: false,
                processData: false,
                success: function (response) {
                    resolve(response);
                },
                error: function (response) {
                    reject(response);
                }
            });
        });
    }

}
