import { ConstantsMessages } from './../../constants-messages';
import { ElectronicSignature } from 'src/app/entities/electronic-signature';
import { HelperService } from 'src/app/services/helper.service';

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { environment } from '../../../environments/environment';
import { JobOutsourcingsService } from '../../services/job-outsourcings.service';
import { JobOutsourcing } from 'src/app/entities/job-outsourcing';

declare const jQuery;
declare const swal;
@Component({
  selector: 'app-sign-job-acceptance',
  templateUrl: './sign-job-acceptance.component.html',
  styleUrls: ['./sign-job-acceptance.component.scss']
})
export class SignJobAcceptanceComponent implements OnInit {

  // creamos un objeto para en el establecer la informacion del archivo que contiene la firma
  signature: File;

  // cargamos el elemento que trabaja la modal
  @ViewChild('modalSignDoc')
  modalFormElem: ElementRef;

  public urlJobAcceptanceTrusted;

  public workspaceId: string;
  public urlPdfName: string;

  public jobOutsourcingId: string;
  public jobOutsourcing: JobOutsourcing;

  public isSignatureJobAcceptance: boolean;
  public typingSignature: boolean;
  public urlPdf: any;

  constructor(
    private currentRoute: ActivatedRoute,
    private jobOutsourcingsService: JobOutsourcingsService,
    private sanitizer: DomSanitizer,
    private helperService: HelperService
  ) {
    this.signature = null;
    this.workspaceId = null;
    this.jobOutsourcingId = null;
    this.isSignatureJobAcceptance = false;
    this.typingSignature = false;
    this.urlPdfName = '';
}

  ngOnInit(): void {
    this.currentRoute.params.subscribe(params => {
      if (typeof params.workspace_id !== 'undefined' && typeof params.outsourcing_id !== 'undefined') {
        this.workspaceId = params.workspace_id
        this.jobOutsourcingId = params.outsourcing_id;
        this.loadJobAcceptance(params.workspace_id, params.outsourcing_id);
        this.loadJobOutsourcing(params.workspace_id, params.outsourcing_id);
      }
    });
  }

  public loadJobAcceptance(idWorkspace, idJobOutsourcing): void {
    this.helperService.showLoadingMxpro360();
    this.jobOutsourcingsService
      .getPublicJobAcceptance(idWorkspace, idJobOutsourcing)
      .then((response) => {
        
        this.isSignatureJobAcceptance = response['is_signature'];
        this.urlPdfName = `${environment.api.base}/tmp/${response['filename']}`;
        if (this.isSignatureJobAcceptance) {
          this.urlPdfName = `${environment.az_api.cdn}/${this.workspaceId}/documents/${response['filename']}`;
          this.urlPdf = response;
        } 
        if(this.urlPdf){
          this.changePdf(this.urlPdf['urlEstimate']);
        } else {
          this.urlJobAcceptanceTrusted = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlPdfName);
        }
      })
      .catch((error) => {
        this.helperService.showMessageError(error, {idWorkspace: idWorkspace, idJobOutsourcing: idJobOutsourcing});
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }

  private loadJobOutsourcing(idWorkspace, idJobOutsourcing): void {
    this.helperService.showLoadingMxpro360();
    this.jobOutsourcingsService
      .getJobOutsourcing(idWorkspace, idJobOutsourcing)
      .then((response) => {
        this.jobOutsourcing = response;
      })
      .catch((error) => {
        this.helperService.showMessageError(error, {idWorkspace: idWorkspace, idJobOutsourcing: idJobOutsourcing});
      })
      .finally(() => {
        this.helperService.hideLoadingMxpro360();
      });
  }
  /**
     * Permite escuchar la firma
     */
  onSignature(signature: File) {
    this.signature = signature;
  }

  /**
     * Permite cerrar la modal
     */
  closeModal(): void {
    // nuleamos el formulario para poder ahorrar memoria
    this.signature = null;
  }

  saveSignature(): void {
    // revisamos si no esta se habia enviando previamente 
    if (!this.jobOutsourcingId) {
      return;
    }

    this.helperService.showLoadingMxpro360();

    const formData = new FormData();
    formData.append('files[]', this.signature);
    jQuery.ajax({
      url: environment.api.base + '/files',
      type: 'post',
      dataType: 'json',
      data: formData,
      cache: false,
      contentType: false,
      processData: false
    }).done((response) => {
      const electronicSignature = new ElectronicSignature();
      electronicSignature.esignature_file.display_name = response.files[0];
      electronicSignature.esignature_file.name = response.files[0];
      electronicSignature.esignature_file.mime_type = this.helperService.getMimeType(response.files[0]);
      electronicSignature.internet_browser = this.helperService.detectBrowser();
      // enviamos la firma y e indicamos a que job se asignara
      this.helperService.showLoadingMxpro360();
      this.jobOutsourcingsService
        .savePublicJobAcceptance(this.workspaceId, this.jobOutsourcingId, electronicSignature)
        .then((filename) => {
          this.urlPdf = filename;
          //const urlJobAcceptanceUntrusted = environment.api.base + '/tmp/' + filename;
          this.changePdf(filename['urlEstimate']);
          this.urlPdfName = `${environment.az_api.cdn}/${this.workspaceId}/documents/${filename}`;
          this.urlJobAcceptanceTrusted = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlPdfName);
          this.helperService.showMessageSnackbar(ConstantsMessages.SAVED);
          this.loadJobAcceptance(this.workspaceId, this.jobOutsourcingId);
        })
        .catch((error) => {
          // verificamos que se este recibiendo un error del servidor
          if ('message' in error) {
            // avisamos lo que esta pasando
            const errorMessage = error.error ? error.error.message : error.message;
            swal({
              title: 'Ops',
              text: errorMessage,
              type: 'error',
            });
          }
        })
        .finally(() => {
          this.helperService.hideLoadingMxpro360();
        })
    });
  }

  /**
   * Permite escuchar la firma
   */
  onTypingSignature(typing: boolean) {
      
      this.typingSignature = typing;
      
  }

    changePdf(filename){
      const urlJobAcceptanceUntrusted = `${environment.az_api.cdn}/${this.workspaceId}/documents/${filename}`;
      this.urlJobAcceptanceTrusted = this.sanitizer.bypassSecurityTrustResourceUrl(urlJobAcceptanceUntrusted);

    }


    downloadDocument(urlPdf) {
      window.open(`${environment.az_api.cdn}/${this.workspaceId}/documents/${urlPdf}`);
    }

  viewJAF(urlPdfName){
    window.open(urlPdfName);
  }
}
