import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DispatchContacts } from '../entities/dispatch-contact';

@Injectable({
  providedIn: 'root'
})
export class DispatchContactsService {
  constructor(private http: HttpClient) { }

  getAll(workspaceId): Promise<Array<DispatchContacts>> {
    return this.http.get<Array<DispatchContacts>>(environment.api.base + '/dispatch/contacts/workspace?workspace_id=' + workspaceId).toPromise();
}

}
