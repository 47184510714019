export class Geospatial {
    id: string;
    type: string;
    coordinates: any;

    constructor(){
        this.id = null;
        this.type = null;
        this.coordinates = [];
    }
}
