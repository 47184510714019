
<nav class="menu-jobs-view">
    <a href="#job-details" data-ancla="job-details" class="btn-call-action">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
    </a> 
    
    <!-- <a href="#inventory" data-ancla="inventory" class="btn-call-action">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3" width="22" height="5"></rect><line x1="10" y1="12" x2="14" y2="12"></line></svg>
    </a>  -->

    <a href="#operations" data-ancla="operations" class="btn-call-action">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>
    </a>
</nav>

<!-- Job Details -->
<div class="row">
    <div id="job-details" class="ancla col-xl-12 col-lg-6 col-md-5 col-sm-12 title-section">
        <h3>Job Details <span class="job-details-color">{{job.document.code}}</span> </h3>
        <h4  [hidden]="!authService.session.carrier.has_moverxpro360">If you want to make changes to this job you have to log in to your <a class="job-details-color" target="_blank" href="{{environment.app.base}}">MoverXpro account.</a></h4>
    </div>
</div> 

<div class="row layout-top-spacing">

    <div class="col-xl-3">

        <!-- Contacts -->
        <div class="user-profile layout-spacing">
            <div class="widget-content widget-content-area">
                <div class="d-flex justify-content-between">
                    <h3 >Contacts</h3>
                </div>
                <div class="user-info-list">

                    
                        <ul class="contacts-block list-unstyled">
                            <li class="contacts-block__item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-user">
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg>
                               {{job.customer.name}}
                            </li>
                            <li class="contacts-block__item">
                                <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                                        height="24" viewBox="0 0 24 24" fill="none"
                                        stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-mail">
                                        <path
                                            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                        </path>
                                        <polyline points="22,6 12,13 2,6"></polyline>
                                    </svg>
                                    {{job.customer.email}}

                                </a>
                            </li>
                            <li class="contacts-block__item">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                    class="feather feather-phone">
                                    <path
                                        d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                    </path>
                                </svg>
                                {{job.customer.phone}}

                            </li>

                        </ul>

                        <hr>

                        
                       
                        <ng-container *ngFor="let contact of job.contacts; let i = index">

                            <hr>

                            <ul class="contacts-block list-unstyled">

                                <li class="contacts-block__item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-users">
                                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="9" cy="7" r="4"></circle>
                                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                    </svg>
                                    {{contact.name | titlecase}} {{contact.last_name | titlecase}}
                                </li>

                                <li class="contacts-block__item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-mail">
                                        <path
                                            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z">
                                        </path>
                                        <polyline points="22,6 12,13 2,6"></polyline>
                                    </svg>
                                    {{contact.email | titlecase}}
                                </li>

                                <li class="contacts-block__item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-phone">
                                        <path
                                            d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                                        </path>
                                    </svg>
                                    {{contact.phone}}
                                </li>

                            </ul>
                        </ng-container>

                        
                    
                </div>
            </div>
        </div>

        <!-- Special Conditions -->
        <div class="user-profile special-conditions layout-spacing">
            <div class="widget-content widget-content-area">
                <div class="d-flex justify-content-between">
                    <h3 >Special Conditions / Agreements</h3>
                </div>

                <p class="text-justify">
                   {{job.additional_agreement}}
                </p>

            </div>
        </div>

        <!-- Operation Details -->
        <div class="user-profile special-conditions layout-spacing">
            <div class="widget-content widget-content-area">
                <div class="d-flex justify-content-between">
                    <h3 >Direction / Operation Details</h3>
                </div>
                
                <p class="text-justify">
                    {{job.operation_details}}
                </p>

            </div>
        </div>

    </div>

    <div class="col-xl-4">

        <!-- FEES Information -->
        <div class="user-profile layout-spacing">
            <div class="widget-content widget-content-area">
                <div class="d-flex justify-content-between">
                    <h3 >Fees Information</h3>
                </div>
                <div class="user-info-list">

                    <ul class="contacts-block list-unstyled">
                        <li class="contacts-block__item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-layers">
                                <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                <polyline points="2 17 12 22 22 17"></polyline>
                                <polyline points="2 12 12 17 22 12"></polyline>
                            </svg>
                            <strong>Pounds (Lbs):</strong>
                            ............... {{job.volume.libs}}
                        </li>
                        <li class="contacts-block__item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-package">
                                <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
                                <path
                                    d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z">
                                </path>
                                <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                <line x1="12" y1="22.08" x2="12" y2="12"></line>
                            </svg>
                            <strong>Cubic Feet (Cf):</strong>
                            ........... {{job.volume.cubic_feets_selled}}
                        </li>

                        <li class="contacts-block__item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-compass">
                                <circle cx="12" cy="12" r="10"></circle>
                                <polygon
                                    points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76">
                                </polygon>
                            </svg>
                            <strong>Miles (Mi):</strong>
                            ...................... {{job.volume.miles | number: '1.2-2'}}
                        </li>

                        <li class="contacts-block__item">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-truck">
                                <rect x="1" y="3" width="15" height="13"></rect>
                                <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                                <circle cx="5.5" cy="18.5" r="2.5"></circle>
                                <circle cx="18.5" cy="18.5" r="2.5"></circle>
                            </svg>
                            <strong>Service Type:</strong>
                            ............... Long
                        </li>


                    </ul>
                    
                </div>
            </div>
        </div>

        <!-- Charges -->
        <div class="skills layout-spacing ">
            <div class="widget-content widget-content-area">

                <h3 >Charges</h3>
                <div class="row">

                    <div class="col-md-7 col-sm-7 col-6">Basic Estimate Price:</div>
                    <div class="col-md-5 col-sm-5 col-6 text-right">{{job.charges.cubic_feet_total_price | currency}}</div>
                    <div class="col-md-7 col-sm-7 col-6">Fuel Surcharge: {{job.charges.fuel_surcharge_percentage }}% </div>
                    <div class="col-md-5 col-sm-5 col-6 text-right">{{job.charges.fuel_surcharge | currency}}</div>
                    <ng-container  *ngIf="job.insurance.type != 'RELEASE_VALUE'">
                        <div class="col-12 margin-t10"><strong>Full Value Protection</strong></div>
                        <div class="col-md-7 col-sm-7 col-6">Amount of Liability: {{job.insurance.liability_amount | currency}}</div>
                        <div class="col-md-5 col-sm-5 col-6 text-right margin-b8">{{job.insurance.deductible_amount | currency}}</div>
                        <div class="col-12">Deductible:{{job.insurance.valuation_charge | currency}}</div>
                    </ng-container>
                    <ng-container  *ngIf="job.insurance.type == 'RELEASE_VALUE'">
                        <div class="col-12 margin-t10" ><strong>Basic Value Protectión</strong></div>
                        <div class="col-md-7 col-sm-7 col-6">$0.60 per lbs. per article</div>
                        <div class="col-md-5 col-sm-5 col-6 text-right margin-b8">$0.00</div>
                    </ng-container>
                    <div class="col-12 margin-t10"  *ngIf="bulkyFee?.length > 0"><strong>Inventory Bulky Fee</strong></div>
                    <ng-container *ngFor="let item of bulkyFee ">
                        <div class="col-md-7 col-sm-7 col-6">{{item.inventory_item.bulky_fee.name}}:</div>
                        <div class="col-md-5 col-sm-5 col-6 text-right margin-b8"> {{item.inventory_item.bulky_fee.cost | currency}}</div>
                    </ng-container>

                    <div class="col-md-12 margin-t10"  *ngIf="additionals?.length > 0"><strong>Estimate Additional</strong></div>
                    <ng-container *ngFor="let addi of additionals ">
                        <div class="col-md-7 col-sm-7 col-6">{{addi.name}}  </div>
                        <div class="col-md-5 col-sm-5 col-6 text-right">{{addi.amount | currency}}</div>
                    </ng-container>

                    <div class="col-md-12 margin-t10"  *ngIf="discounts?.length > 0"><strong>Estimate Discount</strong></div>
                    <ng-container *ngFor="let disc of discounts ">
                        <div class="col-md-7 col-sm-7 col-6"> {{disc.name}} </div>
                        <div class="col-md-5 col-sm-5 col-6 text-right"> {{disc.amount | currency}}</div>
                    </ng-container>

                    <div class="col-md-7 col-sm-7 col-6 margin-t10"><strong>Total Customer
                            Payment:</strong></div>
                    <div class="col-md-5 col-sm-5 col-6 margin-t10 text-right">
                        <strong>{{customerPayment | currency}}</strong></div>
                    <div class="col-md-7 col-sm-7 col-6 black-color"><strong>Total Moving
                            Estimate:</strong></div>
                    <div class="col-md-5 col-sm-5 col-6 black-color text-right">
                        <strong>{{job.total | currency}}</strong></div>

                </div>

            </div>
        </div>

    </div>

    <div class="col-xl-5">
                        
        <!-- Moving From/To -->
        <div class="skills layout-spacing ">
            <div class="widget-content widget-content-area">
                <div class="row">

                    <div class="col-xl-6 col-lg-6 col-md-5 col-sm-12">
                        <h3 >Moving From</h3>

                        <div *ngIf="job.pickup.address.street">
                            <strong>Street:</strong>
                            <p> {{job.pickup.address.street }}</p>
                        </div>
                        <div *ngIf="job.pickup.address.city">
                            <strong>City:</strong>
                            <p>{{job.pickup.address.city | titlecase}}</p>
                        </div>
                        <div *ngIf="job.pickup.address.state">
                            <strong>State:</strong>
                            <p> {{job.pickup.address.state}}</p>
                        </div>
                        <div *ngIf="job.pickup.address.zip_code">
                            <strong>Zip Code:</strong>
                            <p> {{job.pickup.address.zip_code}}</p>
                        </div>
                        <div *ngIf="job.pickup.address.country">
                            <strong>Country:</strong>
                            <p> 
                                <ng-container *ngIf="job.pickup.address.country == 'Estados Unidos'">United States</ng-container>
                                <ng-container *ngIf="job.pickup.address.country != 'Estados Unidos'">
                                {{job.pickup.address.country | titlecase}}</ng-container>
                            </p>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-5 col-sm-12">
                        <h3 >Moving To</h3>

                        <div *ngIf="job.pickup.address.street">
                            <strong>Street:</strong>
                            <p> {{job.delivery.address.street }}</p>
                        </div>
                        <div *ngIf="job.pickup.address.city">
                            <strong>City:</strong>
                            <p>{{job.delivery.address.city | titlecase}}</p>
                        </div>
                        <div *ngIf="job.pickup.address.state">
                            <strong>State:</strong>
                            <p> {{job.delivery.address.state}}</p>
                        </div>
                        <div *ngIf="job.pickup.address.zip_code">
                            <strong>Zip Code:</strong>
                            <p> {{job.delivery.address.zip_code}}</p>
                        </div>
                        <div *ngIf="job.pickup.address.country">
                            <strong>Country:</strong>
                            <p> 
                                <ng-container *ngIf="job.delivery.address.country == 'Estados Unidos'">United States</ng-container>
                                <ng-container *ngIf="job.delivery.address.country != 'Estados Unidos'">
                                {{job.delivery.address.country | titlecase}}</ng-container>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        
        <!-- Stop Points -->
        <div class="skills layout-spacing widget-stop-points" *ngIf="job.stop_points.length > 0">
            <div class="widget-content widget-content-area">

                <h3 > Additional Stops</h3>

                <!-- Ng Class "Pickup" - "Delivery" -->
                <div class="row pickup"  *ngIf="job.stop_points.length > 0">
                    <div class="col-xl-6 col-lg-6 col-md-5 col-sm-12"  *ngFor="let stopPoint of job.stop_points">
                        <h4 >Pickup</h4>
                        <div>
                            <strong>Street:</strong>
                            <p>{{stopPoint.address.street}}</p>
                        </div>
                        <div>
                            <strong>City:</strong>
                            <p>{{stopPoint.address.city}}</p>
                        </div>
                        <div>
                            <strong>State:</strong>
                            <p> {{stopPoint.address.state}}</p>
                        </div>
                        <div>
                            <strong>Zip Code:</strong>
                            <p> {{stopPoint.address.zip_code}}</p>
                        </div>
                        <div>
                            <strong>Country:</strong>
                            <p> {{stopPoint.address.country}}</p>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-5 col-sm-12">
                        <h4 >Delivery</h4>
                        <div class="w-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-map-pin">
                                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                <circle cx="12" cy="10" r="3"></circle>
                            </svg>
                        </div>

                        <div>
                            <strong>Street:</strong>
                            <p>23061 Canzonet Street</p>
                        </div>
                        <div>
                            <strong>City:</strong>
                            <p>Los Angeles</p>
                        </div>
                        <div>
                            <strong>State:</strong>
                            <p> CA - California</p>
                        </div>
                        <div>
                            <strong>Zip Code:</strong>
                            <p> 630003</p>
                        </div>
                        <div>
                            <strong>Country:</strong>
                            <p>United States</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

</div>


<!-- Inventory Items -->
<div class="user-profile layout-spacing" *ngIf="job.estimate_inventory_container.length > 0">
    <div class="widget-content widget-content-area">

        <h3> Inventory Items</h3>

        <div class="user-info-list">
            <ul class="contacts-block list-unstyled">
                <li class="contacts-block__item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-archive">
                        <polyline points="21 8 21 21 3 21 3 8"></polyline>
                        <rect x="1" y="3" width="22" height="5"></rect>
                        <line x1="10" y1="12" x2="14" y2="12"></line>
                    </svg>
                    Total Items: {{totalItems}}
                </li>
                <li class="contacts-block__item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-package">
                        <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
                        <path
                            d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z">
                        </path>
                        <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                        <line x1="12" y1="22.08" x2="12" y2="12"></line>
                    </svg>
                    Total Cubic Feet: {{totalCf}}
                </li>
            </ul>
        </div>

        <!-- Items Inventory -->
        <div id="ct" class="note-container note-grid">

            <div class="note-item all-notes note-personal"  [ngClass]="notesColors[i]" *ngFor="let container of job.estimate_inventory_container; let i = index">
                <div class="note-inner-content">
                    <div class="note-content">
                        <h3 class="note-title">
                            {{container.name}}
                        </h3>
                        <!-- <p class="meta-time">11/01/2019</p> -->
                        <div class="note-description-content" *ngFor="let item of container.items ">
                            <p class="note-description">
                                {{item.inventory_item.name}}
                            </p>
                            <div class="note-action">
                                Qty: {{item.quantity}} | Cubic Feet:
                                {{item.quantity * item.inventory_item.cubic_feet}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            

        </div>
        <!-- Items Inventory -->

    </div>
</div>

<hr>

<!-- Operations -->
<div id="operations" class="col-xl-12 col-lg-6 col-md-5 col-sm-12 layout-top-spacing title-section">
    <h3>Operations</h3>
</div>


<div class="row layout-top-spacing">
    <div class="col-xl-5">
                        
            <!-- History Status -->
            <div class="education layout-spacing ">
                <div class="widget-content widget-content-area">


                    <div class="widget-heading">
                        <h3 class="title-content-area">
                            History Status
                        </h3>

                        <a title="Add Carrier Notes" class="tooltipmxp btn-call-action" data-toggle="modal"
                            (click)="openModalStatusHistory()" [hidden]="authService.session.carrier.has_moverxpro360">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-plus-circle">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                        </a>

                    </div>
                    <div class="timeline-alter">
                        <div class="item-timeline" *ngFor="let SH of statusesHistory">
                            <div class="t-meta-date">
                                <p >{{SH.created | date}}</p>
                            </div>
                            <div class="t-dot">
                            </div>
                            <div class="t-text">
                                <strong>
                                    {{SH.status}}
                                </strong>
                                <p>
                                    {{SH.comment}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </div>
    <div class="col-xl-7">

            <!-- Notes -->
            <div class="skills layout-spacing ">
                <div class="widget-content widget-content-area">

                    <!-- inicio seccion de carrier notes -->

                    <div class="widget-heading">
                        <h3 class="title-content-area">
                            Notes
                        </h3>

                        <a title="Add Carrier Notes" class="tooltipmxp btn-call-action" data-toggle="modal"
                            (click)="openModalCarrierNotes()"  [hidden]="authService.session.carrier.has_moverxpro360">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-plus-circle">
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                        </a>

                    </div>

                    <br>

                    <div class="timeline-alter">
                        <div class="table-responsive overflow-unset">
                            <table class="table table-bordered mb-4">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Note</th>
                                        <th>Visibility</th>
                                        <!-- <th>Action</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="text-center" *ngFor="let note of jobNotes; index as i;">
                                        <td>{{i+1}}</td>
                                        <td>{{note.created | date}}</td>
                                        <td style="white-space: break-spaces;">
                                            <div>{{note.note}}</div>
                                        </td>
                                        <td class="d-flex justify-content-between align-items-center">
                                            {{note.visibility}}
                                            <button class="btn btn-secondary change-visibility tooltipmxp"
                                                title="Change Note Visibility">
                                                <i class="fas fa-redo d-flex justify-content-center"></i>
                                            </button>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- fin seccion de carrier notes -->

                </div>

            </div>

    </div>
</div>


<div class="row layout-top-spacing">

    <!-- Box Delivery -->
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12  box-delivery"  [hidden]="!jobAppoinments['request_box_delivery_day']">
        <div class="user-profile layout-spacing operations">
            <div class="widget-content widget-content-area">

                <div class="d-flex justify-content-between">
                    <h3 >Box Delivery</h3>
                    <div class="w-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                    </div>
                </div>

                <div class="assign-work-team "  [ngClass]="{'assign-work-team': !jobAppoinments['box_delivery_day']}">

                    <div class="overlay" *ngIf="!jobAppoinments['box_delivery_day']">
                        <h5 class="text-white">
                            Not assigned
                        </h5>

                        <button class="btn btn-dark">
                            Assign?
                        </button>
                    </div>


                    <div class="px-4 layer-blur">
                        <div class="row">
                            <div class="col-md-3">
                                Date
                            </div>
                            <div class="col-md-9">
                                <p class="meta-time-date">
                                    <app-date-picker class="width-date-picker" [enableTime]="false"  [disabled]="authService.session.carrier.has_moverxpro360"
                                        [value]="jobAppoinments['box_delivery_day']['date']" [placeholder]="'Not Assigned'"
                                        (onChange)="changeDayDate($event, 'box_delivery_day')">
                                    </app-date-picker>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3">
                                <div class="input-group-prepend">
                                    <span>Confirm</span>
                                </div>
                            </div>
                            <div class="col-3">
                                <input name="confirm" type="checkbox" aria-label="Small" [disabled]="authService.session.carrier.has_moverxpro360"
                                    aria-describedby="inputGroup-sizing-sm" aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm" [(ngModel)]="jobAppoinments['box_delivery_day']['confirm']">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3">
                                <div class="input-group-prepend">
                                    <span>Dispached</span>
                                </div>
                            </div>
                            <div class="col-3">
                                <input name="dispatched" type="checkbox" aria-label="Small"  [disabled]="authService.session.carrier.has_moverxpro360"
                                    aria-describedby="inputGroup-sizing-sm" [(ngModel)]="jobAppoinments['box_delivery_day']['dispatched']">
                            </div>
                        </div>

                        <div class="margin-t50">
                            Office Remarks
                            <textarea name="remarks" class="form-control"  [disabled]="authService.session.carrier.has_moverxpro360"
                                aria-label="With textarea" [(ngModel)]="jobAppoinments['box_delivery_day']['remarks']"></textarea>
                        </div>

                        <div class="text-right margin-t20">
                            <div class="field-wrapper">
                                <button (click)="saveJobAppoinment()"   [hidden]="authService.session.carrier.has_moverxpro360" type="button" class="btn btn-info button-save"> Save</button>
                            </div>
                        </div>
                    </div>
                     
                </div>
            </div>
        </div>
    </div>

    <!-- Pack Day -->
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 pack-day" [hidden]="!jobAppoinments['request_pack_day']">
        <div class="user-profile layout-spacing operations">
            <div class="widget-content widget-content-area">
                <div class="d-flex justify-content-between">
                    <h3 >Pack Day</h3>
                    <div class="w-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                    </div>
                </div>

                <div class=" timeline-post-content " [ngClass]="{'assign-work-team': !jobAppoinments['pack_day']}" >

                    <div class="overlay" *ngIf="!jobAppoinments['pack_day']">
                        <h5 class="text-white">
                            Not assigned
                        </h5>

                        <button class="btn btn-dark">
                            Assign?
                        </button>
                    </div>


                    <div class="px-4 layer-blur">
                        <div class="row">
                            <div class="col-md-3">
                                Date
                            </div>
                            <div class="col-md-9">
                                <p class="meta-time-date">
                                   <app-date-picker class="width-date-picker" [enableTime]="false"  [disabled]="authService.session.carrier.has_moverxpro360"
                                        [value]="jobAppoinments['pack_day']['date']" [placeholder]="'Not Assigned'"
                                        (onChange)="changeDayDate($event, 'pack_day')">
                                    </app-date-picker>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3">
                                <div class="input-group-prepend">
                                    <span>Confirm</span>
                                </div>
                            </div>
                            <div class="col-3">
                                <input name="confirm" type="checkbox" aria-label="Small" [disabled]="authService.session.carrier.has_moverxpro360"
                                    aria-describedby="inputGroup-sizing-sm" aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm" [(ngModel)]="jobAppoinments['pack_day']['confirm']">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3">
                                <div class="input-group-prepend">
                                    <span>Dispached</span>
                                </div>
                            </div>
                            <div class="col-3">
                                <input name="dispatched" type="checkbox" aria-label="Small"  [disabled]="authService.session.carrier.has_moverxpro360"
                                    aria-describedby="inputGroup-sizing-sm" [(ngModel)]="jobAppoinments['pack_day']['dispatched']">
                            </div>
                        </div>

                        <div class="margin-t50">
                            Office Remarks
                            <textarea name="remarks" class="form-control"  [disabled]="authService.session.carrier.has_moverxpro360"
                                aria-label="With textarea" [(ngModel)]="jobAppoinments['pack_day']['remarks']"></textarea>
                        </div>

                        <div class="text-right margin-t20">
                            <div class="field-wrapper">
                                <button (click)="saveJobAppoinment()"   [hidden]="authService.session.carrier.has_moverxpro360" type="button" class="btn btn-info button-save"> Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Pickup Day -->
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 pickup-day">
        <div class="user-profile layout-spacing operations">
            <div class="widget-content widget-content-area">
                <div class="d-flex justify-content-between">
                    <h3 >Pickup Day</h3>
                    <div class="w-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                    </div>
                </div>

                <div [ngClass]="{'assign-work-team': !jobAppoinments['pickup_day']}" class=" timeline-post-content">

                   <div class="px-4 layer-blur">
                        <div class="row">
                            <div class="col-md-3">
                                Date
                            </div>
                            <div class="col-md-9">
                                <p class="meta-time-date">
                                   <app-date-picker class="width-date-picker" [enableTime]="false"  [disabled]="authService.session.carrier.has_moverxpro360"
                                        [value]="jobAppoinments['pickup_day']['date']" [placeholder]="'Not Assigned'"
                                        (onChange)="changeDayDate($event, 'pickup_day')">
                                    </app-date-picker>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3">
                                <div class="input-group-prepend">
                                    <span>Confirm</span>
                                </div>
                            </div>
                            <div class="col-3">
                                <input name="confirm" type="checkbox" aria-label="Small" [disabled]="authService.session.carrier.has_moverxpro360"
                                    aria-describedby="inputGroup-sizing-sm" aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm" [(ngModel)]="jobAppoinments['pickup_day']['confirm']">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3">
                                <div class="input-group-prepend">
                                    <span>Dispached</span>
                                </div>
                            </div>
                            <div class="col-3">
                                <input name="dispatched" type="checkbox" aria-label="Small"  [disabled]="authService.session.carrier.has_moverxpro360"
                                    aria-describedby="inputGroup-sizing-sm" [(ngModel)]="jobAppoinments['pickup_day']['dispatched']">
                            </div>
                        </div>

                        <div class="margin-t50">
                            Office Remarks
                            <textarea name="remarks" class="form-control"  [disabled]="authService.session.carrier.has_moverxpro360"
                                aria-label="With textarea" [(ngModel)]="jobAppoinments['pickup_day']['remarks']"></textarea>
                        </div>

                        <div class="text-right margin-t20">
                            <div class="field-wrapper">
                                <button (click)="saveJobAppoinment()"   [hidden]="authService.session.carrier.has_moverxpro360" type="button" class="btn btn-info button-save"> Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Delivery Day -->
    <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 delivery-day">
        <div class="user-profile layout-spacing operations">
            <div class="widget-content widget-content-area">
                <div class="d-flex justify-content-between">
                    <h3 >Delivery Day</h3>
                    <div class="w-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                    </div>
                </div>

                <div [ngClass]="{'assign-work-team': !jobAppoinments['delivery_day']}" class=" timeline-post-content">
                    

                    <div class="overlay"  *ngIf="!jobAppoinments['delivery_day']">
                        <h5 class="text-white">
                            Not assigned
                        </h5>

                        <button class="btn btn-dark">
                            Assign?
                        </button>
                    </div>


                    <div class="px-4 layer-blur">
                        <div class="row">
                            <div class="col-md-3">
                                Date
                            </div>
                            <div class="col-md-9">
                                <p class="meta-time-date">
                                   <app-date-picker class="width-date-picker" [enableTime]="false"  [disabled]="authService.session.carrier.has_moverxpro360"
                                        [value]="jobAppoinments['delivery_day']['date']" [placeholder]="'Not Assigned'"
                                        (onChange)="changeDayDate($event, 'delivery_day')">
                                    </app-date-picker>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3">
                                <div class="input-group-prepend">
                                    <span>Confirm</span>
                                </div>
                            </div>
                            <div class="col-3">
                                <input name="confirm" type="checkbox" aria-label="Small" [disabled]="authService.session.carrier.has_moverxpro360"
                                    aria-describedby="inputGroup-sizing-sm" aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm" [(ngModel)]="jobAppoinments['delivery_day']['confirm']">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3">
                                <div class="input-group-prepend">
                                    <span>Dispached</span>
                                </div>
                            </div>
                            <div class="col-3">
                                <input name="dispatched" type="checkbox" aria-label="Small"  [disabled]="authService.session.carrier.has_moverxpro360"
                                    aria-describedby="inputGroup-sizing-sm" [(ngModel)]="jobAppoinments['delivery_day']['dispatched']">
                            </div>
                        </div>

                        <div class="margin-t50">
                            Office Remarks
                            <textarea name="remarks" class="form-control"  [disabled]="authService.session.carrier.has_moverxpro360"
                                aria-label="With textarea" [(ngModel)]="jobAppoinments['delivery_day']['remarks']"></textarea>
                        </div>

                        <div class="text-right margin-t20">
                            <div class="field-wrapper">
                                <button (click)="saveJobAppoinment()"   [hidden]="authService.session.carrier.has_moverxpro360" type="button" class="btn btn-info button-save"> Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


<!-- Modal OperatingStatusHistory -->
<div class="modal fade" #modalOperatingStatusHistory tabindex="-1" role="dialog" aria-labelledby="modalCarrierNotes"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Operating Status History
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><svg aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg></button>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                  
                    <label class="control-label">Operation:</label>
                    <select [(ngModel)]="jobStatus.operation" name="jobStatusoperation" class="form-control" style="width: 100%;">
                        <option value="DELIVERED_BOXES">DELIVERED BOXES</option>
                        <option value="PACKED_MOVE">PACKED MOVE</option>
                        <option value="PICKED_UP">PICKED UP</option>
                        <option value="IN_TRANSIT">IN TRANSIT</option>
                        <option value="IN_STORAGE">IN STORAGE</option>
                        <option value="DELIVERED">DELIVERED</option>
                    </select>
                </div>
                <div class="col-md-12">
                    <label for="inputState">
                        Comment
                    </label>
                    <div class="list-title">
                        <input type="text" name="comment" class="form-control"
                            [(ngModel)]="jobOperatingStatus.comment">
                    </div>
                </div>

            </div>
            <div class="modal-footer pt-3">
                <button class="btn" data-dismiss="modal">
                    <i class="flaticon-cancel-12"></i>
                    Cancel
                </button>
                <button (click)="saveOperatingStatus(true)" data-dismiss="modal" type="button" class="btn btn-primary">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
<!-- fin Modal OperatingStatusHistory -->




<!-- Modal carrier note -->
<div class="modal fade" #modalCarrierNotes tabindex="-1" role="dialog" aria-labelledby="modalCarrierNotes"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg max-width-modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="newConceptTitle">
                    Add New Carrier Notes
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true"><svg aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-x">
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg></button>
            </div>
            <div class="modal-body">
                <div class="col-md-12">
                    <label for="inputState">
                        Note
                    </label>
                    <textarea class="form-control" [(ngModel)]="jobNote.note">

                    </textarea>
                    <!-- <div class="list-title">
                        <div class="document-editor" style="    max-height: 300px;">
                            <div class="document-editor__toolbar" id="toolbar-container"></div>
                            <div class="document-editor__editable-container">
                                <div class="document-editor__editable" name="company_settings_policy_ensurance">
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="col-md-12 mt-4 carrier--type-notes">
                    <label for="autotransport_type">Type</label>
                    <div class="list-title">
                        <select id="carrier-note-visualization" placeholder="Type" class="form-control" [(ngModel)]="jobNote.visibility"
                            name="autotransport_type">
                            <option value="PUBLIC">
                                Public

                            </option>
                            <option value="PRIVATE">
                                Private</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-3">
                <button class="btn" data-dismiss="modal">
                    <i class="flaticon-cancel-12"></i>
                    Cancel
                </button>
                <button (click)="saveJobNotes()" data-dismiss="modal" type="button" class="btn btn-warning">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
<!-- fin Modal carrier note -->