<div id="content" class="main-content d-flex justify-content-center padd-t40">
    <div class="container justify-content-center">
        <div class="container">
            <div class="row layout-top-spacing marg-t0" id="cancel-row">

                <div class="row col-lg-12 layout-spacing marg-0">

                    <div class="col-12 col-md-1">
                    </div>

                    <ul>
                        <li class="{{tabBank}}">
                            <!-- Circulo e icono -->
                            <span class="marker-number"><i class="fas fa-university  fa-2x icon"></i></span>
                            <!-- Linea -->
                            <span class="marker-line"></span>
                            <!-- Texto debajo del circulo -->
                            <!-- <span class="marker-text"></span> -->
                        </li>
                        <li class="{{tabResult}}">
                            <!-- Circulo e icono -->
                            <span class="marker-number"><i class="far fa-receipt fa-2x icon"></i></span>
                            <!-- <span class="marker-text"></span> -->
                        </li>
                    </ul>
                    <ng-container *ngIf="tabBank == 'active'">
                        <div class="col-12 col-md-5 text-right">
                            <h3><strong>Payment via {{paymentMethod.name}}</strong></h3>

                            <div *ngIf="paymentMethod.description; else noneDescription">
                                <!-- Payment Successful -->
                                <div class="font-16 text-right">
                                    {{paymentMethod.description}}
                                </div>
                            </div>
                            <ng-template #noneDescription>
                                <div class="font-16 text-right">
                                    Please enter the info to make the payment
                                </div>
                            </ng-template>

                            <div class="col-12 pr-0 mt-3 padd-l0">
                                <div class="infobox-3 infobox-amount mt-0">
                                    <div class="info-icon">
                                        <i class="fas fa-dollar-sign"></i>
                                    </div>
                                    <h5 class="info-heading">Payment amount</h5>
                                    <div class="amount">${{balance.amount}}</div>
                                </div>
                            </div>

                        </div>

                        <div class="col-12 col-md-1">
                        </div>

                        <div class="col-12 col-md-5 padd-t5">

                            <div class=" widget widget-content widget-content-area b-radius-10">

                                <div class="row form-ach">
                                    <div class="col-md-12">
                                        <label for="">Titular Account Name</label>
                                        <div class="form-group">
                                            <i class="fas fa-user-alt"></i>
                                            <input type="text" class="form-control" placeholder="Name"
                                                [(ngModel)]="paymentProfile.bank_information.account_name">
                                        </div>
                                    </div>
                                </div>

                                <div class="row form-ach">
                                    <div class="col-md-12">
                                        <label for="">Routing Number</label>
                                        <div class="form-group">
                                            <i class="far fa-credit-card"></i>
                                            <input type="text" class="form-control" placeholder="Number"
                                                [(ngModel)]="paymentProfile.bank_information.routing_number">
                                        </div>
                                    </div>
                                </div>

                                <div class="row form-ach">
                                    <div class="col-md-12">
                                        <label for="">Account Number</label>
                                        <div class="form-group">
                                            <i class="fas fa-credit-card"></i>
                                            <input type="text" class="form-control" placeholder="Number"
                                                [(ngModel)]="paymentProfile.bank_information.account_number">
                                        </div>
                                    </div>
                                </div>

                                <div class="text-right mt-4">
                                    <button class="btn btn-success width-100" (click)="sendPayment()">Pay</button>
                                </div>
                            </div>

                        </div>
                    </ng-container>

                    <ng-container *ngIf="tabResult == 'active'">
                        <h3>Payment Information</h3>
                        <section class="row d-flex justify-content-center invoice">

                            <div class="row invoice-container mb-3" style="width: 750px;">
                                <div class="invoice-inbox col-12 pt-2 pb-2">
                                    <div id="ct" class="">
                                        <div class="invoice-00001">
                                            <div
                                                *ngIf="responseTransaction.transaction.status == 'PAID'; else noneTransaction">
                                                <!-- Payment Successful -->
                                                <div class="content-section">
                                                    <div class="row">
                                                        <div class="col-2 pr-0 text-center">
                                                            <i class="far fa-check-circle icon-payment-successful"></i>
                                                        </div>
                                                        <div class="col-10 pl-0">
                                                            <h4 class="m-0 text-payment-successful">Payment
                                                                Successful</h4>
                                                            <span class="desc-payment-successful">Your payment
                                                                has
                                                                been processed! Details of transactions are
                                                                incluided below</span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-template #noneTransaction>
                                                <!-- Payment Failed -->
                                                <div class="content-section">
                                                    <div class="row">
                                                        <div class="col-2 pr-0 text-center">
                                                            <i class="far fa-times-circle icon-payment-failed"></i>
                                                        </div>
                                                        <div class="col-10 pl-0">
                                                            <h4 class="m-0 text-payment-failed">Payment Failed
                                                            </h4>
                                                            <span class="desc-payment-successful">Your payment
                                                                coundn´t be processed, please contact our
                                                                agent</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>

                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-2 download-invoice">
                                    <button class="btn btn-secondary">Download</button>
                                </div> -->
                            </div>

                            <div class="invoice-container" style="width: 750px;">
                                <div class="invoice-inbox">

                                    <div id="ct" class="">

                                        <div class="invoice-00001">
                                            <div class="content-section">

                                                <div class="inv--head-section inv--detail-section border-0 mb-2">

                                                    <div class="row">

                                                        <div class="col-sm-6 col-12 mr-auto">
                                                            <img class="company-logo"
                                                                src="https://www.newcitymovingandstorage.com/wp-content/uploads/2020/06/logo.png"
                                                                alt="company">

                                                        </div>

                                                        <div class="col-sm-6 text-sm-right">
                                                            <p class="inv-email-address">
                                                                {{responseTransaction.company.name}}</p>
                                                            <p class="inv-email-address"
                                                                *ngIf="responseTransaction.company.address.full_address">
                                                                {{responseTransaction.company.address.full_address}}
                                                            </p>
                                                            <p class="inv-email-address"
                                                                *ngIf="responseTransaction.company.license">IC
                                                                MC: {{responseTransaction.company.license}} </p>
                                                            <p class="inv-email-address"
                                                                *ngIf="responseTransaction.company.dot">US
                                                                D.O.T: {{responseTransaction.company.dot}}</p>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div class="inv--detail-section inv--customer-detail-section">

                                                    <div class="row">

                                                        <div class="col-sm-6 text-left inv--transaction">
                                                            <p class="inv-created-date mb-0"
                                                                *ngIf="responseTransaction.transaction.code"><span
                                                                    class="inv-title">Transaction ID : </span>
                                                                <span
                                                                    class="inv-date">{{responseTransaction.transaction.code}}</span>
                                                            </p>
                                                            <p class="inv-created-date mb-0"
                                                                *ngIf="responseTransaction.transaction.created"><span
                                                                    class="inv-title">Invoice Date : </span>
                                                                <span
                                                                    class="inv-date">{{responseTransaction.transaction.created}}</span>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div class="row mt-4">
                                                        <div class="col-sm-6">
                                                            <div class="inv--payment-info">
                                                                <p class="title-details mb-1">Payment Info</p>
                                                                <p class=""><span class=" inv-subtitle">Concept:</span>
                                                                    <span>{{balance.concept}}</span>
                                                                </p>
                                                                <p><span class=" inv-subtitle">Product:</span>
                                                                    <span>{{responseTransaction.transaction.card_number}}</span>
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div
                                                            *ngIf="responseTransaction.transaction.status == 'PAID'; else nonePAID">
                                                            <!-- Payment Successful -->
                                                            <div
                                                                class="col-sm-6 align-self-end text-right inv--payment-info">
                                                                <p><span class=" inv-subtitle">Payment
                                                                        Status:</span> <span>Approved</span></p>
                                                                <div class="total-section-successful mt-2 pr-3">
                                                                    Amount Paid ${{balance.amount}}</div>
                                                            </div>
                                                        </div>
                                                        <ng-template #nonePAID>
                                                            <!-- Payment Failed -->
                                                            <div
                                                                class="col-sm-6 align-self-end text-right inv--payment-info">
                                                                <p><span class=" inv-subtitle">Payment
                                                                        Status:</span> <span>Denied</span></p>
                                                                <div class="total-section-failed mt-2 pr-3">
                                                                    Amount Paid ${{balance.amount}}</div>
                                                            </div>
                                                        </ng-template>

                                                        <div
                                                            class="col-xl-6 col-lg-5 col-md-6 col-sm-8 col-12 order-sm-0 order-1">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row invoice-container mt-3" style="width: 750px;">
                                <div class="invoice-inbox col-12">
                                    <div id="ct" class="">
                                        <div class="invoice-00001">
                                            <div class="content-section">
                                                <div class="response-payment-status">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>
</div>