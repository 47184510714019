<div id="content" class="main-content">

    <div class="container text-center section-title pad-t70">
        <p class="h6 text-uppercase text-primary">Jobs Calendar</p>
       
    </div>

    <div class="layout-px-spacing-head pad-0">
        <div class="row" id="cancel-row">

            <div class="col-xl-12">
                <div #divCalendar id="calendar">
                    
                 </div>
            </div>

        </div>
    </div>

</div>
<!--  END CONTENT AREA  -->
