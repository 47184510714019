
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PanelLayoutComponent } from './components/panel-layout/panel-layout.component';
import { BalancesComponent } from './pages/balances/balances.component';
import { BrokerDocumentsComponent } from './pages/broker-documents/broker-documents.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { JobCalendarComponent } from './pages/job-calendar/job-calendar.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { LoadBoardComponent } from './pages/load-board/load-board.component';
import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PaymentsAchComponent } from './pages/payments-ach/payments-ach.component';
import { PaymentsCheckComponent } from './pages/payments-check/payments-check.component';
import { PaymentsCreditCardComponent } from './pages/payments-credit-card/payments-credit-card.component';
import { PaymentsOfflineComponent } from './pages/payments-offline/payments-offline.component';
import { PaymentsZelleComponent } from './pages/payments-zelle/payments-zelle.component';
import { RegisterComponent } from './pages/register/register.component';
import { PublicLoadBoardComponent } from './pages/public-load-board/public-load-board.component';
import { VipLoadBoardComponent } from './pages/vip-load-board/vip-load-board.component';
import { JobsViewComponent } from './pages/jobs-view/jobs-view.component';
import { SignJobAcceptanceComponent } from './pages/sign-job-acceptance/sign-job-acceptance.component';
import { RateShieetComponent } from './pages/rate-shieet/rate-shieet.component';

const panelLayout: Routes = [
    { path: 'dashboard', component: DashboardComponent },
    { path: 'load-board', component: LoadBoardComponent },
    { path: 'jobs-calendar', component: JobCalendarComponent },
    { path: 'my-jobs', component: JobsComponent },
    { path: 'my-jobs/:id/view', component: JobsViewComponent },
    { path: 'broker-documents', component: BrokerDocumentsComponent },
    { path: 'balances', component: BalancesComponent },
    { path: 'jobs-view', component: JobsViewComponent },

    //Payment Methods
    { path: 'payment/:id_balance/credit-card/:id_method', component: PaymentsCreditCardComponent },
    { path: 'payment/:id_balance/transfer-online/:id_method', component: PaymentsAchComponent },
    { path: 'payment/:id_balance/transfer-offline/:id_method', component: PaymentsOfflineComponent },

    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent },

];

const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'vip-load-board/:id', component: VipLoadBoardComponent },
    { path: 'public-load-board/:id', component: PublicLoadBoardComponent },
    { path: 'job-acceptance/:workspace_id/sign/:outsourcing_id', component: SignJobAcceptanceComponent },
    { path: 'rate-shieet/:workspace_id/sign/:outsourcing_id', component: RateShieetComponent },
    { path: '', component: PanelLayoutComponent, children: panelLayout },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
