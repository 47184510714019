export class JobOperationStatus {
    public id: string;
    public status: string;
    public comment: string;
    public employee_id: string;
    public job_id: string;
    public created: number;

    constructor() {
        this.id = null;
        this.status = null;
        this.comment = null;
        this.employee_id = null;
        this.job_id = null;
        this.created = null;
    }
}
