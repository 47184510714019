import { ThisReceiver } from "@angular/compiler";
import { Customer } from "./customer";
import { Document } from "./document";
import { EstimateInventoryContainer } from './estimate-inventory-container';
import { JobOutsourcing } from "./job-outsourcing";
import { JobStatus } from './job-status';
import { PublicEstimateCharge } from "./public-estimate-charge";
import { PublicEstimateDelivery } from "./public-estimate-delivery";
import { PublicEstimateInsurance } from "./public-estimate-insurance";
import { PublicEstimatePickup } from "./public-estimate-pickup";
import { PublicEstimateStopPoint } from "./public-estimate-stop-point";
import { PublicJob } from "./public-job";
import { PublicVolume } from "./public-volume";

export class JobOutsourcingView extends JobOutsourcing {
    
    public owed_from_job: number;
    public owed_to_broker: number;
    public job: PublicJob;
    public customer: Customer;
    public volume: PublicVolume;
    public charges: PublicEstimateCharge;
    public insurance: PublicEstimateInsurance;
    public pickup: PublicEstimatePickup;
    public delivery: PublicEstimateDelivery;
    public stop_points: Array<PublicEstimateStopPoint>;
    public estimate_inventory_container : Array<EstimateInventoryContainer>;
    public additional_agreement : string;
    public operation_details : string;
    public total : number;
    public contacts : Array<any>;
    public estimate_additional_charges : Array<any>;
    public balances : Array<any>;
    public statuses: JobStatus;
    public document: Document;

    constructor() {
        super();
        this.owed_from_job = 0;
        this.owed_to_broker = 0;
        this.customer = new Customer();
        this.job = new PublicJob();
        this.volume = new PublicVolume();
        this.charges = new PublicEstimateCharge();
        this.insurance = new PublicEstimateInsurance();
        this.pickup = new PublicEstimatePickup();
        this.delivery = new PublicEstimateDelivery();
        this.stop_points = [];
        this.estimate_inventory_container = [];
        this.additional_agreement = '';
        this.operation_details = '';
        this.total = 0;
        this.contacts = [];
        this.estimate_additional_charges = [];
        this.balances = [];
        this.statuses = new JobStatus();
        this.document = new Document();
    }

}
