import { File } from './file';
import { ElectronicSignature } from './electronic-signature';
import { PaymentProfile } from './payment-profile';
export class CarrierPayment {
    public id: string;
    public amount: number;
    public esignature: ElectronicSignature;
    public payment_profile: PaymentProfile;
    public reference: string;
    public vouchers: Array<File>;

    constructor() {
        this.id = null;
        this.amount = null;
        this.esignature = new ElectronicSignature();
        this.payment_profile = new PaymentProfile();
        this.reference = null;
        this.vouchers = [];
    }
}
