export class CarrierCandidate {
    
    public id: string;
    public carrier_id: string;
    public comments: string;
    public bid: number;
    public status: 'PENDING' | 'APPROVED' | 'REJECT' | 'CANCELED';
    public job_board_id: string;
    public created: number;
    public updated: number;

    constructor() {
        this.id = null;
        this.carrier_id = null;
        this.comments = null;
        this.bid = null;
        this.status = null;
        this.job_board_id = null;
        this.status = null;
        this.created = null;
        this.updated = null;
    }
}
