import { Document } from './document';

export class JobOutsourcing {
    // Cunado asigna directamente
    public id: string;
    public type: 'PICKUP' | 'DELIVERY' | 'ALL';
    public status: string;
    public carrier_id: string;
    public carrier_pay: number;
    public special_conditions: string;
    public job_acceptance_doc: Document;
    public employee_id: string;
    public reason: string;
    public job_id: string;
    public belongs_broker: boolean;
    public carrier_job_id: string;
    public created: number;
    public updated: number;

    /**
     * permite instanciar la clase
     */
    constructor() {
        this.id = null;
        this.type = null;
        this.status = null;
        this.carrier_pay = null;
        this.carrier_id = null;
        this.reason = null;
        this.special_conditions = null;
        this.job_id = null;
        this.job_acceptance_doc = null;
        this.employee_id = null;
        this.belongs_broker = null;
        this.carrier_job_id = null;
        this.created = null;
        this.updated = null;
    }
}
