import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JobCarrierNote } from '../entities/job-carrier-note';

@Injectable({
  providedIn: 'root'
})
export class JobNotesService {

    constructor(private http: HttpClient) { }

    getAll(idJob: string): Promise<Array<JobCarrierNote>> {
      return this.http.get<Array<JobCarrierNote>>(environment.api.carrier + '/jobs/' + idJob + '/notes').toPromise();
    }
  
    saveNote(idJob: string, note: JobCarrierNote): Promise<any> {
      return this.http.post<Array<JobCarrierNote>>(environment.api.carrier + '/jobs/' + idJob + '/notes', note).toPromise();
    }
}
