import { Balance } from 'src/app/entities/balance';
import { BalanceView } from 'src/app/entities/balance-view';
import { AuthService } from 'src/app/services/auth.service';
import { BalancesService } from 'src/app/services/balances.service';
import { HelperService } from 'src/app/services/helper.service';
import { environment } from 'src/environments/environment';

import { Component, OnInit } from '@angular/core';

import { PaymentMethod } from '../../entities/payment-method';
import { PaymentMethodView } from '../../entities/payment-method-view';

declare var jQuery
@Component({
    selector: 'app-balances',
    templateUrl: './balances.component.html',
    styleUrls: ['./balances.component.scss']
})
export class BalancesComponent implements OnInit {

    balances: Array<Balance>;
    viewBalance: BalanceView;
    readonly environment = environment;
    balancetSelect: string;

    public paymentMethods: PaymentMethodView;

    constructor(
        public authService: AuthService,
        private balancesService: BalancesService,
        private helperService: HelperService
    ) {
        this.balances = [];
        this.viewBalance = new BalanceView;
        this.balancetSelect = '';
        this.paymentMethods = new PaymentMethodView();
    }

    ngOnInit(): void {
        this.load();
    }

    ngAfterViewInit(): void {
        this.toggleCollapse()
    }

    load() {
        this.helperService.showLoadingMxpro360();
        this.balancesService
            .getAll()
            .then((response) => {
                this.balances = response;
                this.rowCollapse();     
            })
            .catch((error) => {
                this.helperService.showMessageError(error, {});
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadPayment(id) {
        this.helperService.showLoadingMxpro360();
        this.balancesService
            .getById(id)
            .then((response) => {
                this.viewBalance = response;
            })
            .catch((error) => {
                this.helperService.showMessageError(error, id);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    loadPaymentMethods(idBalance: string) {
        this.helperService.showLoadingMxpro360();
        this.balancesService
            .getPaymentMethods(idBalance)
            .then(async (response) => {
                this.paymentMethods = response;

                for (const paymentMethod of this.paymentMethods.payment_methods) {
                    switch (paymentMethod.method) {
                        case 'CREDIT_CARD':
                            paymentMethod['logo'] = 'assets/img/credit-card-method.png';
                            break;

                        case 'BANK_TRANSFER_ONLINE':
                            if (paymentMethod.name.toLowerCase() === 'ach') {
                                paymentMethod['logo'] = 'assets/img/ach-method.png';
                            } else {
                                paymentMethod['logo'] = 'assets/img/bank-transfer-method.png';
                            }
                            break;

                        case 'BANK_TRANSFER_OFFLINE':
                            if (paymentMethod.name.toLowerCase() === 'zelle') {
                                paymentMethod['logo'] = 'assets/img/zelle-method.png';
                            } else {
                                paymentMethod['logo'] = 'assets/img/bank-transfer-method.png';
                            }
                            break;

                        case 'CHECK':
                            paymentMethod['logo'] = 'assets/img/check-method.png';
                            break;
                    }
                }

            })
            .catch((error) => {
                this.helperService.showMessageError(error, idBalance);
            })
            .finally(() => {
                this.helperService.hideLoadingMxpro360();
            });
    }

    public redirectToPayment(paymentMethod: PaymentMethod, idBalance: string) {
        this.helperService.redirectToPayment(paymentMethod, idBalance);
    }


    /**
     * Se alterna entre agregar y eliminar el Collapse a los row
     */
     toggleCollapse(){
        jQuery('#invoice-list tbody').on('click', '.headerCollapse', function () {
            jQuery(this).nextUntil('tr.header').slideToggle(500);
            jQuery(this).children('.rotate').toggleClass('down')
        });

        jQuery(window).resize(function () {
            if (jQuery(window).width() > 1024) {                
                jQuery("table tbody tr td").removeAttr("style");
            }
            else {
                jQuery(".tdHeader").addClass("headerCollapse");
                jQuery(".rowCollapse").css("display", "none");  
            }
        }); 
    }

    /**
     * Se define como estado inicial el Collapse para los row
     */
    rowCollapse(){
        setTimeout(() => {
            if (jQuery(window).width() < 1024) {                
                    jQuery(".tdHeader").addClass("headerCollapse");
                    jQuery(".rowCollapse").css("display", "none");                
            }
            else {
                jQuery(".tdHeader").removeClass("headerCollapse");
                jQuery("table tbody tr td").removeAttr("style");
            }
        }, 50);
    }
}
