import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
declare const moment;
declare var flatpickr;
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {

    @Input()
    theme: string;

    @Input()
    placeholder: string;

    @Input()
    disabled: boolean;

    @Input()
    enableTime: boolean;

    @Input()
    value: number;

    @Output() onChange: EventEmitter<Number>;

    @ViewChild('fieldDatePicker', { static: true })
    inputField: ElementRef<HTMLInputElement>;

    render: string;

    state: 'RENDER' | 'INPUT';

    private flatPicker;

    constructor() {
        this.onChange = new EventEmitter();
        this.disabled = false;
        this.enableTime = false;
        this.state = 'RENDER';
        this.placeholder = null;
        this.value = null;
        this.flatPicker = null;
        this.theme = 'FORM_FIELD'; // WIDGET este es para cuando no se use en un formulario si no en un panel
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {

        this.flatPicker = flatpickr(this.inputField.nativeElement, {
            enableTime: this.enableTime,
            allowInput: false,
            onChange: (selectedDates: Array<Date>, dateStr, instance) => {
                this.value = moment(selectedDates[0], moment.HTML5_FMT.DATE).unix() * 1000;
                console.log('this.value', this.value);

                if (this.enableTime) {
                    this.render = moment(selectedDates[0]).format('MMM Do YYYY, h:mm A');
                } else {
                    this.render = moment(selectedDates[0]).format('MMM Do YYYY');
                }

                // notificamos el nuevo valor
                this.onChange.emit(this.value);
            },
            onClose: () => {
                this.state = 'RENDER';
            }
        });
        console.log('this.value 2', this.value);

    }

    ngOnChanges(changes: SimpleChanges) {

        // si el cambio es sobre el placeholder lo hacemos primero
        if (changes.placeholder) {
            this.render = changes.placeholder.currentValue;
        }

        // si el cambio es sobre el value lo ejecutamos de ultimo
        if (changes.value) {
            if (changes.value.currentValue !== null) {
                if (this.enableTime) {
                    this.render = moment(changes.value.currentValue).format('MMM Do YYYY, h:mm A');
                } else {
                    this.render = moment(changes.value.currentValue).format('MMM Do YYYY');
                }
            }
        }
    }

    /**
     * Se ejecuta cuando el usuario hace clik para cambiar una fecha
     */
    onClickRender() {

        if (this.state === 'INPUT' || this.disabled) {
            return;
        }

        console.log('this.value 3', this.value);

        this.flatPicker.setDate(moment(this.value).toDate());
        this.flatPicker.redraw();

        this.state = 'INPUT';

        setTimeout(() => {
            this.inputField.nativeElement.focus();
        }, 100);
    }

}
