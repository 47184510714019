import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JobCarrierAppointmentView } from '../entities/job-carrier-appointment-view';
import { JobOutsourcingView } from '../entities/job-outsourcing-view';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

    constructor(private http: HttpClient) {

    }

    /**
     * permite obtener todos los registros
     */
    getById(id): Promise<JobOutsourcingView> {
        return this.http.get<any>(environment.api.carrier + '/jobs/'+ id +'/detail').toPromise()
    }

    getJobAppoinment(id): Promise<JobCarrierAppointmentView> {
        return this.http.get<JobCarrierAppointmentView>(environment.api.carrier + '/jobs/'+ id +'/appointments').toPromise()
    }

    
    saveJobAppoinment(id: string, dataForm: JobCarrierAppointmentView) {
        return this.http.put(environment.api.carrier + '/jobs/' + id + '/appointments', dataForm).toPromise();
    }


}
