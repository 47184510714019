import { PublicAddress } from "./public-address";

export class PublicAppointment {

    range_start: number;
    range_end: number;
    address: PublicAddress;

    constructor() {
        this.range_start = null;
        this.range_end = null;
        this.address = null;
    }
}
