import { PublicAppointment } from "./public-appointment";
import { PublicVolume } from "./public-volume";

export class PublicJob {

    id: string;
    document_code: string;
    service: "LONG" | "LOCAL" | "AUTOTRANSPORT";
    from: PublicAppointment;
    to: PublicAppointment;
    volume: PublicVolume;

    constructor(){
        this.id = null;
        this.document_code = null;
        this.service = null;
        this.from = null;
        this.to = null;
        this.volume  = null;
    }
}
